import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { Container, BoxLogin, Img } from './styles';
import { translate } from '@/i18n/src/locales';

import * as ROUTES from '@/routes/routes';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Login as LogInUser } from '@/store/actions/user';
import Alert from '@/components/Alert';
import { showAlert, setSideBarOpen } from '@/store/actions/theme';
import BounceLoader from 'react-spinners/BounceLoader';
import AuthLogo from '@/assets/images/auth-logo.svg';

import { isAuthenticated } from '@/services/localStorageService';

function Login() {
    const [user, setUser] = useState();
    const [password, setPassword] = useState();

    const history = useHistory();
    const dispatch = useDispatch();

    const isError = useSelector((state) => state.user.isUserError);
    const isLoading = useSelector((state) => state.user.isUserLoading);

    useEffect(() => {
        dispatch(setSideBarOpen(false));
    }, []);

    useEffect(() => {
        if (isError === true) {
            dispatch(
                showAlert({
                    type: 'warning',
                    message: translate('invalidUserOrPassword'),
                    time: 3000,
                }),
            );
        }
    }, [isError]);

    useEffect(() => {
        if (isAuthenticated()) {
            history.push(ROUTES.START);
        }
    }, [isLoading]);

    function handleClick() {
        dispatch(LogInUser(user, password));
    }

    return (
        <>
            <Helmet>
                <title>Administração Franqueadora - Login</title>
            </Helmet>

            <Container>
                <Alert />
                <BoxLogin>
                    <Img src={AuthLogo} />
                    <form method="POST">
                        <input
                            type="text"
                            className="mb"
                            placeholder={translate('user')}
                            onChange={(e) => setUser(e.target.value)}
                        />
                        <input
                            type="password"
                            className="mb"
                            placeholder={translate('password')}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {!isLoading ? (
                            <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={() => handleClick()}>
                                {translate('signIn')}
                            </button>
                        ) : (
                            <div className="btn btn-primary loader">
                                <BounceLoader size={30} color={'#fff'} />
                            </div>
                        )}
                    </form>
                </BoxLogin>
            </Container>
        </>
    );
}

export default Login;
