import React, { useEffect } from 'react';

import {
  RiHome2Line,
  RiMoneyDollarBoxLine,
  RiArrowRightSLine,
  RiShoppingCart2Line,
  RiGovernmentLine,
  RiShoppingCartLine,
} from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import * as ROUTES from '@/routes/routes';
import { translate } from '@/i18n/src/locales';
import { setSideBarOpen } from '@/store/actions/theme';
import Item from './Item';
import { Container, SidebarLink, Logo } from './styles';

function Sidebar() {
  const sideBarOpen = useSelector((state) => state.theme.sideBarOpen);
  const roles = useSelector((state) => state.user.roles);
  const user = useSelector((state) => state.user.user);

  const isSmallScreen = useMediaQuery({ query: '(min-width: 1024px)' });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSideBarOpen(isSmallScreen));
  }, [isSmallScreen]);
  return (
    <Container sideBarOpen={sideBarOpen}>
      <Logo src={user.LogoDirPadraoBranco} />

      {roles.includes('Start') ? (
        <Item title={translate('financial')}>
          <SidebarLink to={ROUTES.START}>
            <RiHome2Line size={20} />
            {translate('start')}
          </SidebarLink>
        </Item>
      ) : null}

      {roles.includes('Revenues') ? (
        <Item title={translate('financial')} collapse icon={<RiMoneyDollarBoxLine size={20} />}>
          <SidebarLink to={ROUTES.REVENUES}>
            <RiArrowRightSLine />
            {translate('revenues')}
          </SidebarLink>
        </Item>
      ) : null}

      {roles.includes('Vouchers', 'VoucherCreate', 'Sales') ? (
        <Item title={translate('store')} collapse icon={<RiShoppingCart2Line size={20} />}>
          {roles.includes('Vouchers') ? (
            <SidebarLink to={ROUTES.VOUCHERS}>
              <RiArrowRightSLine />
              {translate('vouchers')}
            </SidebarLink>
          ) : null}
          {roles.includes('VoucherCreate') ? (
            <SidebarLink to={ROUTES.VOUCHER_CREATE}>
              <RiArrowRightSLine />
              {translate('voucherCreate')}
            </SidebarLink>
          ) : null}
          {roles.includes('Sales') ? (
            <SidebarLink to={ROUTES.SALES}>
              <RiArrowRightSLine />
              {translate('sales')}
            </SidebarLink>
          ) : null}
        </Item>
      ) : null}

      {roles.includes('Poles', 'PoleCreate') ? (
        <Item title={translate('poles')} collapse icon={<RiGovernmentLine size={20} />}>
          {roles.includes('Poles') ? (
            <SidebarLink to={ROUTES.POLES}>
              <RiArrowRightSLine />
              {translate('poles')}
            </SidebarLink>
          ) : null}
          {roles.includes('PoleCreate') ? (
            <SidebarLink to={ROUTES.POLE_CREATE}>
              <RiArrowRightSLine />
              {translate('poleCreate')}
            </SidebarLink>
          ) : null}
        </Item>
      ) : null}

      {roles.includes('Products', 'ProductCreate') ? (
        <Item title={translate('products')} collapse icon={<RiShoppingCartLine size={20} />}>
          {roles.includes('Products') ? (
            <SidebarLink to={ROUTES.PRODUCTS}>
              <RiArrowRightSLine />
              {translate('products')}
            </SidebarLink>
          ) : null}
          {roles.includes('ProductCreate') ? (
            <SidebarLink to={ROUTES.PRODUCT_CREATE}>
              <RiArrowRightSLine />
              {translate('productCreate')}
            </SidebarLink>
          ) : null}
        </Item>
      ) : null}

      {roles.includes('Franchisors', 'FranchisorCreate') ? (
        <Item title={translate('franchisors')} collapse icon={<RiGovernmentLine size={20} />}>
          {roles.includes('Franchisors') ? (
            <SidebarLink to={ROUTES.FRANCHISORS}>
              <RiArrowRightSLine />
              {translate('franchisors')}
            </SidebarLink>
          ) : null}
          {roles.includes('FranchisorCreate') ? (
            <SidebarLink to={ROUTES.FRANCHISOR_CREATE}>
              <RiArrowRightSLine />
              {translate('franchisorCreate')}
            </SidebarLink>
          ) : null}
        </Item>
      ) : null}

      {roles.includes('Coupons', 'CouponCreate') ? (
        <Item title={translate('coupons')} collapse icon={<RiGovernmentLine size={20} />}>
          {roles.includes('Coupons') ? (
            <SidebarLink to={ROUTES.COUPONS}>
              <RiArrowRightSLine />
              {translate('coupons')}
            </SidebarLink>
          ) : null}
          {roles.includes('CouponCreate') ? (
            <SidebarLink to={ROUTES.COUPON_CREATE}>
              <RiArrowRightSLine />
              {translate('couponCreate')}
            </SidebarLink>
          ) : null}
        </Item>
      ) : null}

      {roles.includes('Sections', 'SectionCreate') ? (
        <Item title={translate('sections')} collapse icon={<RiShoppingCartLine size={20} />}>
          {roles.includes('Sections') ? (
            <SidebarLink to={ROUTES.SECTIONS}>
              <RiArrowRightSLine />
              {translate('sections')}
            </SidebarLink>
          ) : null}
          {roles.includes('SectionCreate') ? (
            <SidebarLink to={ROUTES.SECTION_CREATE}>
              <RiArrowRightSLine />
              {translate('sectionCreate')}
            </SidebarLink>
          ) : null}
        </Item>
      ) : null}

      {roles.includes('PaymentPlans', 'PaymentPlanCreate', 'ConfigurationPlans', 'ConfigurationPlanCreate') ? (
        <Item title={translate('paymentPlans')} collapse icon={<RiShoppingCartLine size={20} />}>
          {roles.includes('PaymentPlans') ? (
            <SidebarLink to={ROUTES.PAYMENTPLANS}>
              <RiArrowRightSLine />
              {translate('paymentPlans')}
            </SidebarLink>
          ) : null}
          {roles.includes('PaymentPlanCreate') ? (
            <SidebarLink to={ROUTES.PAYMENTPLAN_CREATE}>
              <RiArrowRightSLine />
              {translate('paymentPlanCreate')}
            </SidebarLink>
          ) : null}

          {roles.includes('ConfigurationPlans') ? (
            <SidebarLink to={ROUTES.CONFIGURATIONPLANS}>
              <RiArrowRightSLine />
              {translate('configurationPlans')}
            </SidebarLink>
          ) : null}
          {roles.includes('ConfigurationPlanCreate') ? (
            <SidebarLink to={ROUTES.CONFIGURATIONPLAN_CREATE}>
              <RiArrowRightSLine />
              {translate('configurationPlanCreate')}
            </SidebarLink>
          ) : null}
        </Item>
      ) : null}
    </Container>
  );
}

export default Sidebar;
