import styled from 'styled-components';
import DataTable from 'react-data-table-component';

export const Container = styled.div`
  header {
    padding: 0 !important;
  }
  .limpa-buscar {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
`;

export const Table = styled(DataTable)`
  .rdt_TableCol {
    text-transform: uppercase;
    font-weight: 700;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  }

  .rdt_TableHeader {
    display: none !important;
  }

  img {
    width: 4rem;
    border-radius: 0.3rem;
  }

  .icon-edit {
    width: 2rem;
    cursor: pointer;
  }

  .icon-edit:hover {
    opacity: 0.7;
    transition: all 0.5s;
  }
`;
