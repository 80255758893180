/* eslint-disable no-console */
import { push } from 'connected-react-router';
import { apiLoja } from '@/services/api';
import * as ROUTES from '@/routes/routes';
import { showAlert } from '@/store/actions/theme';
import { translate } from '@/i18n/src/locales';
import * as _ from 'lodash';
import {
    PAYMENTPLANS_SET,
    PAYMENTPLAN_EDIT_SET,
    PAYMENTPLAN_LOADING,
    PAYMENTPLAN_LOADED,
    PAYMENTPLAN_CLEAR,
} from '../actionTypes';

export const setPaymentPlans = (paymentPlans) => ({
    type: PAYMENTPLANS_SET,
    payload: paymentPlans,
});

export const setPaymentPlanEdit = (paymentPlans) => ({
    type: PAYMENTPLAN_EDIT_SET,
    payload: paymentPlans,
});

export const loadingPaymentPlan = () => ({
    type: PAYMENTPLAN_LOADING,
});

export const loadedPaymentPlan = () => ({
    type: PAYMENTPLAN_LOADED,
});

export const clearPaymentPlan = () => ({
    type: PAYMENTPLAN_CLEAR,
});

export const addPaymentPlan = (paymentPlan) => {
    return (dispatch) => {
        dispatch(loadingPaymentPlan());
        apiLoja
            .post(`/PlanoPagamento/Add`, {
                ...paymentPlan,
            })
            .then((data) => {
                dispatch(setPaymentPlanEdit(data.data));
                dispatch(loadedPaymentPlan());
                dispatch(push(`${ROUTES.PAYMENTPLAN_CREATE}/${data.data.Result.IdPlanoPagamento}`));
                dispatch(
                    showAlert({
                        type: 'success',
                        message: translate('paymentPlanAdd'),
                        time: 3000,
                    })
                );
            })
            .catch((error) => {
                if (error.response.status === 300) {
                    dispatch(clearPaymentPlan());
                    dispatch(
                        showAlert({
                            type: 'warning',
                            message: translate('paymentPlanExists'),
                            time: 3000,
                        })
                    );
                } else if (error.response.status === 412) {
                    dispatch(clearPaymentPlan());
                    dispatch(
                        showAlert({
                            type: 'warning',
                            message: translate(error.response.data),
                            time: 3000,
                        })
                    );
                } else console.log('error comming', error);
            });
    };
};

export const editPaymentPlan = (paymentPlan) => {
    return (dispatch) => {
        dispatch(loadingPaymentPlan());
        apiLoja
            .put(`/PlanoPagamento`, {
                ...paymentPlan,
            })
            .then((data) => {
                dispatch(setPaymentPlanEdit(data.data));
                dispatch(push(`${ROUTES.PAYMENTPLAN_CREATE}/${paymentPlan.IdPlanoPagamento}`));
                dispatch(loadedPaymentPlan());
                dispatch(
                    showAlert({
                        type: 'success',
                        message: translate('paymentPlanEdited'),
                        time: 3000,
                    })
                );
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const fetchAllPaymentPlan = () => {
    return (dispatch) => {
        dispatch(loadingPaymentPlan());
        apiLoja
            .get(`/PlanoPagamento`)
            .then((data) => {
                dispatch(setPaymentPlans(data.data));
                dispatch(loadedPaymentPlan());
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const fetchPaymentPlanById = (IdPlanoPagamento) => {
    return (dispatch) => {
        dispatch(loadingPaymentPlan());
        apiLoja
            .get(`/PlanoPagamento/${IdPlanoPagamento}`)
            .then((data) => {
                dispatch(setPaymentPlanEdit(data.data));
                dispatch(loadedPaymentPlan());
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

