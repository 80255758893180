import React from 'react';

import Card from '@/components/Card';

import { Helmet } from 'react-helmet';
import NaoAutorizado from '@/assets/images/acesso-nao-autorizado.svg';

import { Img, Row } from './styles';

function NoPermission() {
    return (
        <>
            <Helmet>
                <title>Administração Franqueadora - Sem Permissão</title>
            </Helmet>
            <Card>
                <div className="img-start">
                    <Img src={NaoAutorizado} />
                </div>
            </Card>
        </>
    );
}

export default NoPermission;
