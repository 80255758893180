import React, { useEffect } from 'react';

import { translate } from '@/i18n/src/locales';
import { Helmet } from 'react-helmet';

import Card from '@/components/Card';
import TitlePage from '@/components/TitlePage';
import DataTable from '@/components/DataTable';

import { fetchPoles } from '@/store/actions/pole';
import { useSelector, useDispatch } from 'react-redux';
import * as ROUTES from '@/routes/routes';

function Poles() {
  const dispatch = useDispatch();

  const poles = useSelector((state) => state.pole.poles);

  useEffect(() => {
    dispatch(fetchPoles());
  }, []);

  return (
    <>
      <Helmet>
        <title>Administração Franqueadora -{translate('poles')}</title>
      </Helmet>
      <TitlePage title={translate('poles')} link={ROUTES.POLE_CREATE} txtButton={translate('poleCreate')} />
      <Card>
        <DataTable
          data={poles}
          searchPlaceHolder={translate('searchPoleName')}
          columns={[
            { name: translate('poleName'), selector: 'nomePolo', sortable: true },
            {
              name: translate('externalCode'),
              selector: 'codigoExternoPolo',
              sortable: true,
            },
          ]}
          columnFilter="nomePolo"
        />
      </Card>
    </>
  );
}

export default Poles;
