/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'react-flexbox-grid';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';

import {
  RiArrowDropDownLine,
  RiCheckboxBlankLine,
  RiCheckboxFill,
  RiDeleteBin2Line,
} from 'react-icons/ri';
import DataTable from '@/components/DataTable';
import { useSelector, useDispatch } from 'react-redux';
import ContentLoader from 'react-content-loader';
import { translate } from '@/i18n/src/locales';
import TitlePage from '@/components/TitlePage';
import Switcher from '@/components/Forms/Switcher';
import Input from '@/components/Forms/Input';
import Card from '@/components/Card';
import { toBase64 } from '@/utils/ConvertBase64';
import { colors } from '@/template/styles';
import Badge from '@/components/Badge';
// import Modal from "@/Component/Modal";
import {
  fetchSectionById,
  addSection,
  editSection,
  updateImageSection,
  fetchAllSectionPaymentPlans,
  addSectionProduct,
  updateSectionProduct,
  fetchSectionPlan,
  removeSectionPlan
} from '@/store/actions/section';

import ImageGalery from '@/assets/images/image-gallery.svg';

import * as S from './styles';

function SectionCreate() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const sectionEdit = useSelector((state) => state.section.sectionEdit);
  const sectionIsLoading = useSelector((state) => state.section.sectionIsLoading);
  const [isEdit, setIsEdit] = useState(false);
  const [imageSection, setImageSection] = useState(null);
  const [image64Section, setImage64Section] = useState(null);
  const [sectionName, setSectionName] = useState('');
  const [sectionActive, setSectionActive] = useState(true);
  const [openCollapseSectionProduct, setOpenCollapseSectionProduct] = useState(false);
  const [openCollapsePaymentPlan, setOpenCollapsePaymentPlan] = useState(false);
  const isProdutcOnSection = useSelector((state) => state.section.isProdutcOnSection);
  const isPaymentPlanOnSection = useSelector((state) => state.section.isSectionOnPaymentPlan);
  const sectionProducts = useSelector((state) => state.section.sectionProducts);
  const sectionPaymentPlans = useSelector((state) => state.section.sectionPaymentPlans);
  const sectionProductsIsLoading = useSelector((state) => state.section.sectionProductsIsLoading);
  const sectionPaymentPlanIsLoading = useSelector((state) => state.section.sectionPaymentPlanIsLoading);
  const [sectionNameStatus, setSectionNameStatus] = useState({
    status: '',
    feedback: '',
  });


  useEffect(() => {
    if (id) {
      setIsEdit(true);
      dispatch(fetchSectionById(id));
    } else {
      setIsEdit(false);
    }
  }, [id]);

  useEffect(() => {
    if (isEdit && sectionEdit) {
      setSectionName(sectionEdit.NomeSecao);
      setImageSection(sectionEdit.Imagem);
      setSectionActive(sectionEdit.Ativo);

      dispatch(fetchAllSectionPaymentPlans(id));
    }
  }, [isEdit, sectionEdit]);


  useEffect(() => {
    if (isPaymentPlanOnSection) {
      setOpenCollapsePaymentPlan(true);
    } else {
      setOpenCollapsePaymentPlan(false);
    }
  }, [isPaymentPlanOnSection]);

  useEffect(() => {
    if (isProdutcOnSection) {
      setOpenCollapseSectionProduct(true);
    } else {
      setOpenCollapseSectionProduct(false);
    }
  }, [isProdutcOnSection]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const img64 = await toBase64(acceptedFiles[0]);
      setImage64Section(img64.split('base64,')[1]);
      setImageSection(URL.createObjectURL(acceptedFiles[0]));

      if (isEdit) {
        dispatch(updateImageSection(id, img64.split('base64,')[1]));
      }
    },
    [dispatch, setImageSection, setImage64Section, isEdit]
  );

  const removeImageSection = useCallback(() => {
    setImageSection(null);
  }, []);

  const handleAddSection = useCallback(() => {
    let formErro = false;

    if (!sectionName) {
      setSectionNameStatus({ status: 'danger', feedback: 'Campo obrigatório' });
      formErro = true;
    }

    if (!formErro) {
      dispatch(
        addSection({
          NomeSecao: sectionName,
          Ativo: sectionActive,
          ImagemBase64: image64Section,
        })
      );
    }
  }, [
    dispatch,
    sectionName,
    sectionActive,
    image64Section,
  ]);

  const handleEditSection = useCallback(() => {
    let formErro = false;

    if (!sectionName) {
      setSectionNameStatus({ status: 'danger', feedback: 'Campo obrigatório' });
      formErro = true;
    }

    if (!formErro) {
      dispatch(
        editSection({
          IdSecao: id,
          NomeSecao: sectionName,
          Ativo: sectionActive,
          Imagem: imageSection
        })
      );
    }
    // window.location.reload(true);
  }, [
    id,
    dispatch,
    sectionName,
    sectionActive,
    imageSection,
  ]);


  const toogleSectionProduct = useCallback(
    (idProduct, enable) => {
      if (enable) {
        dispatch(
          addSectionProduct({
            IdSecao: id,
            IdProduto: idProduct,
            Ativo: 1
          })
        );
      } else {
        dispatch(
          updateSectionProduct({
            IdSecao: id,
            IdProduto: idProduct,
            Ativo: 0            
          })
        );
      }
    },
    [dispatch, sectionEdit]
  );

  const toogleSectionPlan = useCallback(
    (idPlan, enable) => {
      if (enable) {
        dispatch(
          fetchSectionPlan({
            IdSecao: id,
            IdPlanoPagamento: idPlan
          })
        );
      } else {
        dispatch(
          removeSectionPlan({
            IdSecao: id,
            IdPlanoPagamento: idPlan,
          })
        );
      }
    },
    [dispatch, sectionEdit]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <Helmet>
        <title>
          Administração Franqueadora -{' '}
          {isEdit ? translate('sectionEdit') : translate('sectionCreate')}
        </title>
      </Helmet>
      <TitlePage title={isEdit ? translate('sectionEdit') : translate('sectionCreate')} />
      <Card>
        {sectionIsLoading ? (
          <ContentLoader
            width="100%"
            speed={1}
            height="380px"
            backgroundColor="#c6c6c6"
            foregroundColor="#F0F0F5"
          >
            <rect x="0" y="0" rx="5px" ry="5px" width="320px" height="300px" />
            <rect x="340" y="0" rx="5px" ry="5px" width="60%" height="40px" />
            <rect x="340" y="50" rx="5px" ry="5px" width="45%" height="40px" />
            <rect x="340" y="100" rx="5px" ry="5px" width="45%" height="40px" />
            <rect x="0" y="320" rx="5px" ry="5px" width="100%" height="50px" />
          </ContentLoader>
        ) : (
          <>
            <Row>
              <Col xs={12} md={4}>
                {imageSection === null ? (
                  <S.ContainerUpload {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>{translate('dropFile')}</p>
                    ) : (
                      <div>
                        <S.Img src={ImageGalery} />
                        <p>{translate('chooseImageSection')}</p>
                      </div>
                    )}
                  </S.ContainerUpload>
                ) : (
                  <S.ContentImgPreview>
                    <div className="remove-image" onClick={removeImageSection}>
                      <RiDeleteBin2Line size={25} />
                    </div>
                    <S.ImgPreview src={imageSection} />
                  </S.ContentImgPreview>
                )}
              </Col>
              <Col xs={12} md={8}>
                <Row>
                  <Col xs={12}>
                    <Input
                      name="name-adm"
                      type="text"
                      label="Nome da seção"
                      value={sectionName}
                      change={(value) => setSectionName(value)}
                      status={sectionNameStatus}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={2}>
                    <Switcher set={setSectionActive} value={sectionActive} label="Seção Ativa" />
                  </Col>
                </Row>
                <Row>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {isEdit ? (
                  <button type="submit" className="btn btn-primary mt" onClick={handleEditSection}>
                    Salvar Mudanças
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary mt" onClick={handleAddSection}>
                    Salvar
                  </button>
                )}
              </Col>
            </Row>
          </>
        )}
      </Card>
      {sectionIsLoading || sectionProductsIsLoading || sectionPaymentPlanIsLoading ? (
        <ContentLoader
          width="100%"
          speed={1}
          height="380px"
          backgroundColor="#c6c6c6"
          foregroundColor="#F0F0F5"
        >
          <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="80px" />
          <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="80px" />
        </ContentLoader>
      ) : (
        isEdit && (
          <>
            <S.CollapseItem>
              <div className="header" onClick={() => setOpenCollapsePaymentPlan(!openCollapsePaymentPlan)}>
                <div className="title">
                  {isPaymentPlanOnSection ? (
                    <RiCheckboxFill size={25} color={colors.success} />
                  ) : (
                    <RiCheckboxBlankLine size={25} />
                  )}
                  <div>{translate('havePaymentPlan')}</div>
                </div>
                <div className="item-action">
                  <S.ArrowCollapse open={openCollapsePaymentPlan}>
                    <RiArrowDropDownLine size={30} />
                  </S.ArrowCollapse>
                </div>
              </div>
              <S.CollapseBody
                isOpen={openCollapsePaymentPlan}
                transition="height 0.4s cubic-bezier(.4, 0, .2, 1"
              >
                {sectionPaymentPlans && (
                  < DataTable
                    data={sectionPaymentPlans}
                    searchPlaceHolder={translate('searchPaymentPlan')}
                    columns={[
                      {
                        name: 'Nome',
                        selector: 'Descricao',
                        sortable: true,
                      },
                      {
                        width: '200px',
                        right: true,
                        cell: (row) =>
                          <div
                            className="check-plan-product"
                            onClick={() => toogleSectionPlan(row.IdPlanoPagamento, !row.Vinculado)}
                          >
                            {row.Vinculado ? (
                              <RiCheckboxFill size={25} color={colors.success} />
                            ) : (
                              <RiCheckboxBlankLine size={25} />
                            )}
                          </div>
                      },
                    ]}
                    columnFilter="Descricao"
                  />
                )}
              </S.CollapseBody>
            </S.CollapseItem>

            <S.CollapseItem>
              <div className="header" onClick={() => setOpenCollapseSectionProduct(!openCollapseSectionProduct)}>
                <div className="title">
                  {isProdutcOnSection ? (
                    <RiCheckboxFill size={25} color={colors.success} />
                  ) : (
                    <RiCheckboxBlankLine size={25} />
                  )}
                  <div>{translate('haveProduct')}</div>
                </div>
                <div className="item-action">
                  <S.ArrowCollapse open={openCollapseSectionProduct}>
                    <RiArrowDropDownLine size={30} />
                  </S.ArrowCollapse>
                </div>
              </div>
              <S.CollapseBody
                isOpen={openCollapseSectionProduct}
                transition="height 0.4s cubic-bezier(.4, 0, .2, 1"
              >
                {sectionProducts && (
                  < DataTable
                    data={sectionProducts}
                    searchPlaceHolder={translate('searchProduct')}
                    columns={[
                      {
                        compact: true,
                        center: true,
                        width: '60px',
                        cell: (row) => <img src={row.Imagem} alt={row.Nome} />,
                      },
                      {
                        name: 'Nome',
                        selector: 'Nome',
                        sortable: true,
                      },
                      {
                        name: translate('active'),
                        selector: 'Ativo',
                        compact: true,
                        width: '120px',
                        cell: (row) => {
                          return row.Ativo ? <Badge type="success">{translate('active')}</Badge> : <Badge type="danger">{translate('inactive')}</Badge>;
                        },
                      },
                      {
                        width: '200px',
                        right: true,
                        cell: (row) =>
                          <div
                            className="check-plan-product"
                            onClick={() => toogleSectionProduct(row.IdProduto, !row.Vinculado)}
                          >
                            {row.Vinculado ? (
                              <RiCheckboxFill size={25} color={colors.success} />
                            ) : (
                              <RiCheckboxBlankLine size={25} />
                            )}
                          </div>
                      },
                    ]}
                    columnFilter="Nome"
                  />
                )}
              </S.CollapseBody>
            </S.CollapseItem>
          </>
        )
      )}
    </>
  );
}

export default SectionCreate;
