import styled from 'styled-components';
import { colors, metrics } from '@/template/styles';

export const Container = styled.div`
    background: #1e273b;
    margin-left: 10px;
    margin-bottom: 10px;
    width: 140px;
    border-radius: 5px;
    color: ${colors.white};
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    align-items: center;

    .entalhe-card {
        width: 50%;
        height: 5px;
        background: #fff;
        margin-bottom: 1rem;
        border-radius: 50px;
    }

    img {
        width: 60%;
        border-radius: 5px;
    }
`;

export const Body = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0.5rem 1rem;
    font-size: 1rem;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-top: 1px dashed #4f4f4f;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
`;
