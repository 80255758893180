/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'react-flexbox-grid';
import { useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { RiArrowDropDownLine, RiCheckboxBlankLine, RiCheckboxFill, RiDeleteBin2Line } from 'react-icons/ri';

import DataTable from '@/components/DataTable';
import Badge from '@/components/Badge';
import SelectOne from '@/components/Forms/SelectOne';
import ContentLoader from 'react-content-loader';
import { translate } from '@/i18n/src/locales';
import TitlePage from '@/components/TitlePage';
import Switcher from '@/components/Forms/Switcher';
import InputMoney from '@/components/Forms/InputMoney';
import InputPercentage from '@/components/Forms/InputPercentage';
import Input from '@/components/Forms/Input';
import FormatDate from '@/components/Forms/FormatDate';

import Card from '@/components/Card';
import Check from '@/components/Forms/Check';
import { colors } from '@/template/styles';
import { toBase64 } from '@/utils/ConvertBase64';
import { fetchCouponById, addCoupon, editCoupon, fetchAllProductsCouponLoja, fetchCouponProductsLoja, updateCouponProduct } from '@/store/actions/coupon';

import { fetchCouponTypes } from '@/store/actions/couponTypes';
import { fetchAllPolesCouponLoja, fetchCouponPolesLoja, updateCouponPole } from '@/store/actions/couponPoles';
import { fetchCouponTypeApplication } from '@/store/actions/couponTypeApplication';
import el from 'date-fns/esm/locale/el/index.js';
import { Container, Label } from './styles';
import * as S from './styles';

function CouponCreate() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const couponEdit = useSelector((state) => state.coupon.couponEdit);
  const couponIsLoading = useSelector((state) => state.coupon.couponIsLoading);
  const couponProductsIsLoading = useSelector((state) => state.coupon.couponProductsIsLoading);
  const couponpolesIsLoading = useSelector((state) => state.coupon.couponPolesIsLoading);

  const isProductsOnCoupon = useSelector((state) => state.coupon.isProductsOnCoupon);
  const isPolesOnCoupon = useSelector((state) => state.couponPoles.isPolesOnCoupon);
  const couponProducts = useSelector((state) => state.coupon.couponProducts);

  const couponPoles = useSelector((state) => state.couponPoles.couponsPole);

  const typeCoupon = useSelector((state) => state.couponTypes.couponTypes);
  const typeCouponApplication = useSelector((state) => state.couponTypeApplication.couponTypeApplication);
  const [isEdit, setIsEdit] = useState(false);

  const [openCollapseProduct, setOpenCollapseProduct] = useState(false);
  const [openCollapsePole, setOpenCollapsePole] = useState(false);

  const [couponCode, setCouponCode] = useState('');
  const [couponActive, setCouponActive] = useState(true);
  const [firstPurchase, setFirstPurchase] = useState(false);
  const [couponCodeStatus, setCouponCodeStatus] = useState({
    status: '',
    feedback: '',
  });

  const [couponPrice, setCouponPrice] = useState('');
  const [couponPriceStatus, setCouponPriceStatus] = useState({
    status: '',
    feedback: '',
  });

  const [inputCouponTypes, setInputCouponTypes] = useState(null);
  const [inputCouponTypeApplication, setInputCouponTypeApplication] = useState(null);
  const [selectedCouponTypes, setSelectedCouponTypes] = useState(0);
  const [selectedCouponTypeApplication, setSelectedCouponTypeApplication] = useState(0);
  const [couponStartDate, setCouponStartDate] = useState('');
  const [couponStartDateStatus, setCouponStartDateStatus] = useState({
    status: '',
    feedback: '',
  });
  const [couponDueDate, setCouponDueDate] = useState('');
  const [couponDueDateStatus, setCouponDueDateStatus] = useState({
    status: '',
    feedback: '',
  });

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      dispatch(fetchCouponById(id));
    } else {
      setIsEdit(false);
    }

    dispatch(fetchCouponTypes());
    dispatch(fetchCouponTypeApplication());
  }, [id]);

  useEffect(() => {
    if (typeCoupon.length > 0) {
      setInputCouponTypes(
        typeCoupon.map((item) => ({
          label: item.Tipo,
          value: String(item.IdCupomTipo),
        }))
      );
    }
  }, [typeCoupon]);

  useEffect(() => {
    if (typeCouponApplication.length > 0) {
      setInputCouponTypeApplication(
        typeCouponApplication.map((item) => ({
          label: item.Descricao,
          value: String(item.IdCupomTipoAplicacao),
        }))
      );
    }
  }, [typeCouponApplication]);

  useEffect(() => {
    if (isEdit && couponEdit) {
      setCouponCode(couponEdit.Codigo);
      setSelectedCouponTypes(couponEdit.IdCupomTipo);
      setCouponPrice(couponEdit.ValorDesconto.toFixed(2).replace('.', ','));
      setCouponStartDate(FormatDate(couponEdit.DataInicio));
      setCouponDueDate(FormatDate(couponEdit.DataFim));
      setSelectedCouponTypeApplication(couponEdit.IdCupomTipoAplicacao);
      setCouponActive(couponEdit.Ativo);
      setFirstPurchase(couponEdit.PrimeiraCompra);

      dispatch(fetchAllProductsCouponLoja(couponEdit.IdCupom));
      dispatch(fetchAllPolesCouponLoja(couponEdit.IdCupom));
    }
  }, [isEdit, couponEdit]);

  useEffect(() => {
    if (isProductsOnCoupon) {
      setOpenCollapseProduct(true);
    } else {
      setOpenCollapseProduct(false);
    }
  }, [isProductsOnCoupon]);

  useEffect(() => {
    if (isPolesOnCoupon) {
      setOpenCollapsePole(true);
    } else {
      setOpenCollapsePole(false);
    }
  }, [isPolesOnCoupon]);

  const changeCouponTypes = useCallback((item) => {
    setSelectedCouponTypes(item.value);
  }, []);

  const changeCouponTypeApplication = useCallback((item) => {
    setSelectedCouponTypeApplication(item.value);
  }, []);

  const handleAddCoupon = useCallback(() => {
    let formErro = false;

    if (!couponCode) {
      setCouponCodeStatus({ status: 'danger', feedback: 'Campo obrigatório' });
      formErro = true;
    }

    if (!couponPrice) {
      setCouponPriceStatus({ status: 'danger', feedback: 'Campo obrigatório' });
      formErro = true;
    }

    if (!couponStartDate) {
      setCouponStartDateStatus({ status: 'danger', feedback: 'Campo obrigatório' });
      formErro = true;
    }

    if (!couponDueDate) {
      setCouponDueDateStatus({ status: 'danger', feedback: 'Campo obrigatório' });
      formErro = true;
    }

    if (!formErro) {
      dispatch(
        addCoupon({
          Codigo: couponCode,
          IdCupomTipo: selectedCouponTypes,
          ValorDesconto: couponPrice.replace(',', '.'),
          Ativo: couponActive,
          DataInicio: couponStartDate,
          DataFim: couponDueDate,
          IdCupomTipoAplicacao: selectedCouponTypeApplication,
          PrimeiraCompra: firstPurchase,
        })
      );
    }
  }, [dispatch, couponCode, typeCoupon, couponPrice, couponActive, couponStartDate, couponDueDate, firstPurchase]);

  const handleEditCoupon = useCallback(() => {
    let formErro = false;

    if (!couponCode) {
      setCouponCodeStatus({ status: 'danger', feedback: 'Campo obrigatório' });
      formErro = true;
    }

    if (!couponPrice) {
      setCouponPriceStatus({ status: 'danger', feedback: 'Campo obrigatório' });
      formErro = true;
    }

    if (!formErro) {
      dispatch(
        editCoupon({
          idCupom: id,
          Codigo: couponCode,
          IdCupomTipo: selectedCouponTypes,
          ValorDesconto: couponPrice.replace(',', '.'),
          Ativo: couponActive,
          DataInicio: couponStartDate,
          DataFim: couponDueDate,
          IdCupomTipoAplicacao: selectedCouponTypeApplication,
          PrimeiraCompra: firstPurchase,
        })
      );
    }
  }, [id, dispatch, couponCode, selectedCouponTypes, couponPrice, couponActive, couponStartDate, couponDueDate, selectedCouponTypeApplication, firstPurchase]);

  const toogleCouponProduct = useCallback(
    (idProduct, enable) => {
      if (enable) {
        dispatch(
          fetchCouponProductsLoja({
            IdProduto: idProduct,
            IdCupom: id,
            Ativo: true,
          })
        );
      } else {
        dispatch(
          updateCouponProduct({
            IdProduto: idProduct,
            IdCupom: id,
            Ativo: false,
          })
        );
      }
    },
    [dispatch, couponEdit]
  );

  const toogleCouponPole = useCallback(
    (idPole, idCoupon, enable) => {
      if (enable) {
        dispatch(
          fetchCouponPolesLoja({
            IdPolo: idPole,
            IdCupom: idCoupon,
            Ativo: true,
          })
        );
      } else {
        dispatch(
          updateCouponPole({
            IdPolo: idPole,
            IdCupom: idCoupon,
            Ativo: false,
          })
        );
      }
    },
    [dispatch, couponEdit]
  );

  return (
    <>
      <Helmet>
        <title>Administração Franqueadora - {isEdit ? translate('couponEdit') : translate('couponCreate')}</title>
      </Helmet>
      <TitlePage title={isEdit ? translate('couponEdit') : translate('couponCreate')} />
      <Card>
        {couponIsLoading ? (
          <ContentLoader width="100%" speed={1} height="380px" backgroundColor="#c6c6c6" foregroundColor="#F0F0F5">
            <rect x="0" y="0" rx="5px" ry="5px" width="60%" height="40px" />
            <rect x="0" y="60" rx="5px" ry="5px" width="20%" height="40px" />
            <rect x="0" y="120" rx="5px" ry="5px" width="20%" height="40px" />
            <rect x="220" y="120" rx="5px" ry="5px" width="20%" height="40px" />
            <rect x="440" y="120" rx="5px" ry="5px" width="20%" height="40px" />
            <rect x="0" y="230" rx="5px" ry="5px" width="100%" height="50px" />
          </ContentLoader>
        ) : (
          <>
            <Row>
              <Col xs={12} md={8}>
                <Row>
                  <Col xs={12}>
                    <Input
                      name="name-adm"
                      type="text"
                      label={translate('couponName')}
                      value={couponCode}
                      change={(value) => setCouponCode(value)}
                      status={couponCodeStatus}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    {inputCouponTypes && (
                      <SelectOne
                        options={inputCouponTypes}
                        value={inputCouponTypes.find((item) => item.value === String(selectedCouponTypes))}
                        change={(item) => changeCouponTypes(item)}
                        searchable
                        placeholder="Selecione"
                      />
                    )}
                  </Col>
                  <Col xs={12} md={4}>
                    {inputCouponTypeApplication && (
                      <SelectOne
                        options={inputCouponTypeApplication}
                        value={inputCouponTypeApplication.find((item) => item.value === String(selectedCouponTypeApplication))}
                        change={(item) => changeCouponTypeApplication(item)}
                        searchable
                        placeholder="Selecione"
                      />
                    )}
                  </Col>
                  <Col xs={6} md={2}>
                    <Switcher set={setFirstPurchase} value={firstPurchase} label={translate('firstPurchase')} />
                  </Col>
                  <Col xs={6} md={2}>
                    <Switcher set={setCouponActive} value={couponActive} label={translate('activeCoupon')} />
                  </Col>
                </Row>
                <Row className="mt">
                  <Col xs={12} md={4}>
                    {selectedCouponTypes != 2 && selectedCouponTypes != 1 && (
                      <Container>
                        <Label>{translate('discount')}</Label>
                        <input disabled />
                      </Container>
                    )}
                    {selectedCouponTypes == 2 && (
                      <InputMoney
                        value={couponPrice}
                        label={translate('discount')}
                        status={couponPriceStatus}
                        change={(value) => setCouponPrice(value)}
                      />
                    )}
                    {selectedCouponTypes == 1 && (
                      <InputPercentage
                        value={couponPrice}
                        label={translate('discount')}
                        status={couponPriceStatus}
                        change={(value) => setCouponPrice(value)}
                        onChange={(value) => this.setState({ number: value })}
                      />
                    )}
                  </Col>
                  <Col xs={12} md={4}>
                    <Input
                      value={couponStartDate}
                      type="date"
                      label={translate('startDate')}
                      status={couponStartDateStatus}
                      change={(value) => setCouponStartDate(value)}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Input
                      value={couponDueDate}
                      type="date"
                      label={translate('dueDate')}
                      status={couponDueDateStatus}
                      change={(value) => setCouponDueDate(value)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {isEdit ? (
                  <button type="submit" className="btn btn-primary mt" onClick={handleEditCoupon}>
                    Salvar Mudanças
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary mt" onClick={handleAddCoupon}>
                    Salvar
                  </button>
                )}
              </Col>
            </Row>
          </>
        )}
      </Card>

      {couponIsLoading || couponProductsIsLoading ? (
        <ContentLoader width="100%" speed={1} height="380px" backgroundColor="#c6c6c6" foregroundColor="#F0F0F5">
          <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="80px" />
          <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="80px" />
        </ContentLoader>
      ) : (
        isEdit && (selectedCouponTypeApplication != 1 && selectedCouponTypeApplication != 4 && selectedCouponTypeApplication != 0) && (
          <>
            <S.CollapseItem>
              <div className="header" onClick={() => setOpenCollapseProduct(!openCollapseProduct)}>
                <div className="title">
                  {isProductsOnCoupon ? (
                    <RiCheckboxFill size={25} color={colors.success} />
                  ) : (
                    <RiCheckboxBlankLine size={25} />
                  )}
                  <div>Cupom possui produtos?</div>
                </div>
                <div className="item-action">
                  <S.ArrowCollapse open={openCollapseProduct}>
                    <RiArrowDropDownLine size={30} />
                  </S.ArrowCollapse>
                </div>
              </div>
              <S.CollapseBody isOpen={openCollapseProduct} transition="height 0.4s cubic-bezier(.4, 0, .2, 1">
                {couponProducts && (
                  <DataTable
                    data={couponProducts}
                    searchPlaceHolder="Buscar Produtos"
                    columns={[
                      {
                        compact: true,
                        center: true,
                        width: '60px',
                        cell: (row) => <img src={row.Imagem} alt={row.Nome} />,
                      },
                      {
                        name: 'Nome',
                        selector: 'Nome',
                        sortable: true,
                      },
                      {
                        name: 'Ativo',
                        selector: 'Ativo',
                        compact: true,
                        width: '120px',
                        cell: (row) => {
                          return row.Ativo ? <Badge type="success">Ativo</Badge> : <Badge type="danger">Inativo</Badge>;
                        },
                      },
                      {
                        width: '60px',
                        cell: (row) => (
                          <div
                            className="check-trail-module"
                            onClick={() => toogleCouponProduct(row.IdProduto, !row.Vinculado)}
                          >
                            {row.Vinculado ? (
                              <RiCheckboxFill size={25} color={colors.success} />
                            ) : (
                              <RiCheckboxBlankLine size={25} />
                            )}
                          </div>
                        ),
                      },
                    ]}
                    columnFilter="Nome"
                  />
                )}
              </S.CollapseBody>
            </S.CollapseItem>
          </>
        )
      )}

      {(couponIsLoading || couponpolesIsLoading) ? (
        <ContentLoader width="100%" speed={1} height="380px" backgroundColor="#c6c6c6" foregroundColor="#F0F0F5">
          <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="80px" />
          <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="80px" />
        </ContentLoader>
      ) : (
        isEdit && (
          <>
            <S.CollapseItem>
              <div className="header" onClick={() => setOpenCollapsePole(!openCollapsePole)}>
                <div className="title">
                  {isPolesOnCoupon ? (
                    <RiCheckboxFill size={25} color={colors.success} />
                  ) : (
                    <RiCheckboxBlankLine size={25} />
                  )}
                  <div>Vincular Polo?</div>
                </div>
                <div className="item-action">
                  <S.ArrowCollapse open={openCollapsePole}>
                    <RiArrowDropDownLine size={30} />
                  </S.ArrowCollapse>
                </div>
              </div>
              <S.CollapseBody isOpen={openCollapsePole} transition="height 0.4s cubic-bezier(.4, 0, .2, 1">
                {couponPoles && (
                  <DataTable
                    data={couponPoles}
                    searchPlaceHolder={translate('searchPoleName')}
                    columns={[
                      { name: translate('poleName'), selector: 'NomePolo', sortable: true },
                      {
                        name: translate('externalCode'),
                        selector: 'CodigoExternoPolo',
                        sortable: true,
                      },
                      {
                        width: '60px',
                        cell: (row) => (
                          <div
                            className="check-trail-module"
                            onClick={() => toogleCouponPole(row.IdPolo, row.IdCupom, !row.Ativo)}
                          >
                            {row.Ativo ? (
                              <RiCheckboxFill size={25} color={colors.success} />
                            ) : (
                              <RiCheckboxBlankLine size={25} />
                            )}
                          </div>
                        ),
                      },
                    ]}
                    columnFilter="NomePolo"
                  />
                )}
              </S.CollapseBody>
            </S.CollapseItem>
          </>
        )
      )}
    </>
  );
}

export default CouponCreate;
