/* eslint-disable no-console */
import { push } from 'connected-react-router';
import { apiLoja } from '@/services/api';
import * as ROUTES from '@/routes/routes';
import { translate } from '@/i18n/src/locales';
import { showAlert } from './theme';
import * as _ from 'lodash';
import {
    SECTIONS_SET,
    SECTION_EDIT_SET,
    SECTION_LOADING,
    SECTION_LOADED,
    SECTION_CLEAR,
    SECTION_PRODUCTS_SET,
    SECTION_PRODUCTS_LOADING,
    SECTION_PRODUCTS_LOADED,
    SECTION_PAYMENTPLANS_SET,
    SECTION_PAYMENTPLANS_LOADING,
    SECTION_PAYMENTPLANS_LOADED
} from '../actionTypes';
import { da } from 'date-fns/locale';

export const setSections = (sections) => ({
    type: SECTIONS_SET,
    payload: sections,
});

export const setSectionEdit = (section) => ({
    type: SECTION_EDIT_SET,
    payload: section,
});

export const loadingSection = () => ({
    type: SECTION_LOADING,
});

export const loadedSection = () => ({
    type: SECTION_LOADED,
});

export const clearSection = () => ({
    type: SECTION_CLEAR,
});

export const setSectionProducts = (products, isProdutcOnSection) => ({
    type: SECTION_PRODUCTS_SET,
    payload: { products, isProdutcOnSection },
});

export const loadingSectionProducts = () => ({
    type: SECTION_PRODUCTS_LOADING,
});

export const loadedSectionProducts = () => ({
    type: SECTION_PRODUCTS_LOADED,
});


export const setSectionPaymentPlans = (paymentPlans, isSectionOnPaymentPlan) => ({
    type: SECTION_PAYMENTPLANS_SET,
    payload: { paymentPlans, isSectionOnPaymentPlan },
});

export const loadingSectionPaymentPlans = () => ({
    type: SECTION_PAYMENTPLANS_LOADING,
});

export const loadedSectionPaymentPlans = () => ({
    type: SECTION_PAYMENTPLANS_LOADED,
});


export const fetchAllSectionProducts = (idSecao) => {
    return (dispatch) => {
        dispatch(loadingSectionProducts());
        apiLoja
            .get(`/SecaoProduto/${idSecao}`)
            .then((data) => {
                const ordered = _.orderBy(data.data, 'Vinculado', 'desc');
                const isProdutcOnSection = _.find(ordered, { Vinculado: true }) || false
                if (isProdutcOnSection) {
                    dispatch(setSectionProducts(ordered, true));
                } else {
                    dispatch(setSectionProducts(ordered, false));
                }
                dispatch(loadedSectionProducts());
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const fetchAllSectionPaymentPlans = (idSecao) => {
    return (dispatch) => {
        dispatch(loadingSectionPaymentPlans());
        apiLoja
            .get(`/SecaoPlano/${idSecao}`)
            .then((data) => {
                const ordered = _.orderBy(data.data, 'Vinculado', 'desc');
                const isSectionOnPaymentPlan = _.find(ordered, { Vinculado: true }) || false;

                if (isSectionOnPaymentPlan) {
                    dispatch(setSectionPaymentPlans(ordered, true));
                } else {
                    dispatch(setSectionPaymentPlans(ordered, false));
                }
                dispatch(loadedSectionPaymentPlans());
                dispatch(fetchAllSectionProducts(idSecao));
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const fetchSectionById = (idSecao) => {
    return (dispatch) => {
        dispatch(loadingSection());
        apiLoja
            .get(`/Secao/${idSecao}`)
            .then((data) => {
                dispatch(setSectionEdit(data.data));
                dispatch(loadedSection());
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const addSection = (section) => {
    return (dispatch) => {
        dispatch(loadingSection());
        apiLoja
            .post(`/Secao`, {
                ...section,
            })
            .then((data) => {
                dispatch(loadedSection());
                dispatch(push(`${ROUTES.SECTION_CREATE}/${data.data.IdSecao}`));
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const addSectionPlan = (sectionPlan) => {
    return (dispatch) => {
        dispatch(loadingSectionPaymentPlans());
        apiLoja
            .post(`/SecaoPlano`, {
                ...sectionPlan,
            })
            .then(() => {
                dispatch(fetchAllSectionPaymentPlans(sectionPlan.IdSecao));
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const addSectionProduct = (sectionProduct) => {
    return (dispatch) => {
        dispatch(loadingSectionProducts());
        apiLoja
            .get(`/SecaoProduto/${sectionProduct.IdSecao}/${sectionProduct.IdProduto}`)
            .then((data) => {
                if (data.data == null) {
                    apiLoja
                        .post(`/SecaoProduto`, {
                            ...sectionProduct,
                        })
                        .then(() => {
                            dispatch(fetchAllSectionProducts(sectionProduct.IdSecao));
                        })
                } else {
                    apiLoja
                        .put(`/SecaoProduto`, {
                            ...sectionProduct,
                        })
                        .then(() => {
                            dispatch(fetchAllSectionProducts(sectionProduct.IdSecao));
                        })
                }
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    dispatch(
                        showAlert({
                            type: 'warning',
                            message: 'SecaoPlano já existe',
                            time: 3000,
                        })
                    );
                } else {
                    console.log('error comming', error);
                }
            });
    };
};

export const editSection = (section) => {
    return (dispatch) => {
        dispatch(loadingSection());
        apiLoja
            .put(`/Secao`, {
                ...section,
            })
            .then((data) => {
                dispatch(push(`${ROUTES.SECTION_CREATE}/${section.IdSecao}`));
                dispatch(loadedSection());
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const updateImageSection = (IdSecao, ImagemBase64) => {
    return (dispatch) => {
        dispatch(loadingSection());
        apiLoja
            .patch(`/Secao`, {
                IdSecao,
                ImagemBase64,
            })
            .then(() => {
                dispatch(loadedSection());
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const fetchAllSection = () => {
    return (dispatch) => {
        dispatch(loadingSection());
        apiLoja
            .get(`/Secao/`)
            .then((data) => {
                dispatch(setSections(data.data));
                dispatch(loadedSection());
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const fetchSectionPlan = (sectionPlan) => {
    return (dispatch) => {
        dispatch(loadingSectionProducts());
        apiLoja
            .get(`/SecaoPlano/${sectionPlan.IdSecao}/${sectionPlan.IdPlanoPagamento}`)
            .then((data) => {
                if (data.data != null) {

                    let promiseChangeSectionPlan = new Promise(function (resolve, reject) {
                        dispatch(removeSectionPlan(data.data));
                        let removedSectionPlan = true;
                        if (removedSectionPlan)
                            resolve();
                        else
                            reject();
                    });

                    promiseChangeSectionPlan.then(function (resolveReturn) {
                        dispatch(addSectionPlan(sectionPlan));
                        dispatch(
                            showAlert({
                                type: 'success',
                                message: translate('changePlanSectionMessageSucess'),
                                time: 3000,
                            })
                        );
                    }).catch(function (rejectReturn) {
                        dispatch(
                            showAlert({
                                type: 'warning',
                                message: translate('changePlanSectionMessageError'),
                                time: 3000,
                            })
                        );
                    });

                } else {
                    dispatch(addSectionPlan(sectionPlan));
                }
            }).catch((error) => {
                dispatch(fetchAllSectionProducts(sectionPlan.IdSecao));
                console.log('error comming', error);
            });
    };
};

export const updateSectionProduct = (sectionProduct) => {
    return (dispatch) => {
        dispatch(loadingSectionProducts());
        apiLoja
            .put(`/SecaoProduto`, {
                ...sectionProduct,
            })
            .then((data) => {
                dispatch(fetchAllSectionProducts(sectionProduct.IdSecao));
            })
            .catch((error) => {
                dispatch(fetchAllSectionProducts(sectionProduct.IdSecao));
                console.log('error comming', error);
            });
    };
};

export const removeSectionPlan = (sectionPlan) => {
    return (dispatch) => {
        dispatch(loadingSectionPaymentPlans());
        apiLoja
            .delete(`/SecaoPlano`, {
                data: {
                    ...sectionPlan,
                },
            })
            .then((data) => {
                dispatch(fetchAllSectionPaymentPlans(sectionPlan.IdSecao));
            })
            .catch((error) => {
                dispatch(fetchAllSectionPaymentPlans(sectionPlan.IdSecao));
                console.log('error comming', error);
            });
    };
};

