import { COUPON_POLES_SET, COUPON_POLES_LOADING, COUPON_POLES_LOADED } from '../actionTypes';

const initialState = {
    couponsPole: [],
    isPolesOnCoupon: false,
    couponPolesIsLoading: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case COUPON_POLES_SET:
            return {
                ...state,
                couponsPole: action.payload.couponsPole,
                isPolesOnCoupon: action.payload.isPolesOnCoupon,
            };
        case COUPON_POLES_LOADING:
            return {
                ...state,
                couponPolesIsLoading: true,
            };
        case COUPON_POLES_LOADED:
            return {
                ...state,
                couponPolesIsLoading: false,
            };
        default:
            return state;
    }
};

export default reducer;
