import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import history from '@/routes/history';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import userReducer from './reducers/user';
import themeReducer from './reducers/theme';
import invoiceReducer from './reducers/invoice';
import polesReducer from './reducers/pole';
import voucherReducer from './reducers/voucher';
import couponReducer from './reducers/coupon';
import productReducer from './reducers/product';
import salesReducer from './reducers/sales';
import plansReducer from './reducers/plans';
import signatureTypeReducer from './reducers/signatureType';
import couponTypesReducer from './reducers/couponTypes';
import couponPolesReducer from './reducers/couponPoles';
import couponTypeApplicationReducer from './reducers/couponTypeApplication';
import sectionReducer from './reducers/section';
import paymentPlanReducer from './reducers/paymentPlan';
import configurationPlanReducer from './reducers/configurationPlan';
import configurationPlanTypesReducer from './reducers/configurationPlanTypes';
import configurationPlanExhibitionReducer from './reducers/configurationPlanExhibition';

// WHITELIST
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'], // only user will be persisted
};

const reducers = combineReducers({
  user: userReducer,
  theme: themeReducer,
  invoice: invoiceReducer,
  pole: polesReducer,
  voucher: voucherReducer,
  product: productReducer,
  coupon: couponReducer,
  sales: salesReducer,
  plans: plansReducer,
  signatureType: signatureTypeReducer,
  couponTypes: couponTypesReducer,
  couponPoles: couponPolesReducer,
  couponTypeApplication: couponTypeApplicationReducer,
  section: sectionReducer,
  paymentPlan: paymentPlanReducer,
  configurationPlan: configurationPlanReducer,
  configurationPlanTypes: configurationPlanTypesReducer,
  configurationPlanExhibition: configurationPlanExhibitionReducer,
  router: connectRouter(history),
});

const persistedReducer = persistReducer(persistConfig, reducers);
const compose = composeWithDevTools({ realtime: true });

const middleware = [thunk, routerMiddleware(history)];

const store = createStore(persistedReducer, compose(applyMiddleware(...middleware)));
const persistor = persistStore(store);

export { store, persistor };
