import styled from 'styled-components';
import { colors } from '@/template/styles';

export const Container = styled.div`
  position: relative;
  margin-bottom: 1.5rem;

  input {
    transition: all 0.2;
    border-color: ${(props) => {
    if (props.status === 'success') {
      return `${colors.success} !important`;
    }
    if (props.status === 'danger') {
      return `${colors.danger} !important`;
    }
    return '#dcdce6';
  }};
    color: ${(props) => {
    if (props.status === 'success') {
      return `${colors.success} !important`;
    }
    if (props.status === 'danger') {
      return `${colors.danger} !important`;
    }
    return '#000';
  }};
  }

  input:focus {
    transition: all 0.2;
    border-color: ${colors.blue};
    border-width: 0.2rem;
  }

  .feedback {
    margin-top: 2px;
    font-size: 1.2rem;
    color: ${(props) => {
    if (props.status === 'success') {
      return `${colors.success} !important`;
    }
    if (props.status === 'danger') {
      return `${colors.danger} !important`;
    }
    return '#000';
  }};
  }
`;

export const Label = styled.div`
  position: absolute;
  left: 10px;
  background: #fff;
  top: -9px;
  padding: 0px 10px;
  color: ${(props) => {
    if (props.status === 'success') {
      return `${colors.success} !important`;
    }
    if (props.status === 'danger') {
      return `${colors.danger} !important`;
    }
    return '#000';
  }};
`;
