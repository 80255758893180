import styled from 'styled-components';
import { colors } from '@/template/styles';

export const AlertContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.sideBarOpen ? `220px` : `0px`)};
  right: 0;
  z-index: 999;
  display: flex;
  height: ${(props) => props.height};
  background: ${(props) => {
    if (props.type === 'success') {
      return colors.success;
    }
    if (props.type === 'danger') {
      return colors.danger;
    }
    if (props.type === 'warning') {
      return colors.warning;
    }
    if (props.type === 'info') {
      return colors.info;
    }
  }};
  display: flex;
  align-items: center;
  padding: 0px 2rem;
  overflow: hidden;
  transition: height 0.2s;
  color: ${colors.white};
  svg {
    margin-right: 15px;
  }
`;
