export const HOME = '/';

export const LOGIN = '/login';

export const START = '/app/start';
export const REVENUES = '/app/revenues';
export const NO_PERMISSION = '/app/nopermission';

export const VOUCHERS = '/app/vouchers';
export const VOUCHER_CREATE = '/app/voucher/create';

export const SALES = '/app/sales';

export const POLES = '/app/poles';
export const POLE_CREATE = '/app/pole/create';

export const PRODUCTS = '/app/products';
export const PRODUCT_CREATE = '/app/product/create';

export const FRANCHISORS = '/app/franchisors';
export const FRANCHISOR_CREATE = '/app/franchisor/create';

export const COUPONS = '/app/coupons';
export const COUPON_CREATE = '/app/coupon/create';

export const SECTIONS = '/app/sections';
export const SECTION_CREATE = '/app/section/create';

export const PAYMENTPLANS = '/app/paymentPlans';
export const PAYMENTPLAN_CREATE = '/app/paymentPlan/create';

export const CONFIGURATIONPLANS = '/app/paymentPlan/configurationPlans';
export const CONFIGURATIONPLAN_CREATE = '/app/paymentPlan/configurationPlan/create';
