export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const USER_ERROR = 'USER_ERROR';
export const USER_SET = 'USER_SET';

export const THEME_TOOGLE_SIDEBAR = 'THEME_TOOGLE_SIDEBAR';
export const THEME_SHOW_ALERT = 'THEME_SHOW_ALERT';
export const THEME_CLOSE_ALERT = 'THEME_CLOSE_ALERT';

export const INVOICES_SET = 'INVOICES_SET';
export const INVOICES_LOADING = 'INVOICES_LOADING';
export const INVOICES_LOADED = 'INVOICES_LOADED';

export const INVOICE_ITENS_SET = 'INVOICE_ITENS_SET';
export const INVOICE_ITENS_LOADING = 'INVOICE_ITENS_LOADING';
export const INVOICE_ITENS_LOADED = 'INVOICE_ITENS_LOADED';

export const POLES_SET = 'POLES_SET';
export const POLES_LOADING = 'POLES_LOADING';
export const POLES_LOADED = 'POLES_LOADED';
export const POLE_CREATED = 'POLE_CREATED';

export const VOUCHER_SET = 'VOUCHER_SET';
export const VOUCHER_LOADING = 'VOUCHER_LOADING';
export const VOUCHER_LOADED = 'VOUCHER_LOADED';
export const VOUCHER_CLEAR = 'VOUCHER_CLEAR';
export const VOUCHER_FILTER_SET = 'VOUCHER_FILTER_SET';

export const PRODUCTS_SET = 'PRODUCTS_SET';
export const PRODUCT_EDIT_SET = 'PRODUCT_EDIT_SET';
export const PRODUCT_ID_EDIT_SET = 'PRODUCT_ID_EDIT_SET';
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_LOADED = 'PRODUCT_LOADED';
export const PRODUCT_CLEAR = 'PRODUCT_CLEAR';

export const PRODUCT_TRAILS_SET = 'PRODUCT_TRAILS_SET';
export const PRODUCT_TRAILS_LOADING = 'PRODUCT_TRAILS_LOADING';
export const PRODUCT_TRAILS_LOADED = 'PRODUCT_TRAILS_LOADED';

export const PRODUCT_MODULES_SET = 'PRODUCT_MODULES_SET';
export const PRODUCT_MODULES_LOADING = 'PRODUCT_MODULES_LOADING';
export const PRODUCT_MODULES_LOADED = 'PRODUCT_MODULES_LOADED';

export const PRODUCT_ADD_INTEGRATION = 'PRODUCT_ADD_INTEGRATION';
export const PRODUCT_REMOVE_INTEGRATION = 'PRODUCT_REMOVE_INTEGRATION';

export const SALES_SET = 'SALES_SET';
export const SALES_LOADING = 'SALES_LOADING';
export const SALES_LOADED = 'SALES_LOADED';
export const SALES_CLEAR = 'SALES_CLEAR';

export const SALES_FILTER_SET = 'SALES_FILTER_SET';

export const PLANS_SET = 'PLANS_SET';
export const PLANS_LOADING = 'PLANS_LOADING';
export const PLANS_LOADED = 'PLANS_LOADED';

export const SIGNATURE_TYPE_SET = 'SIGNATURE_TYPE_SET';
export const SIGNATURE_TYPE_LOADING = 'SIGNATURE_TYPE_LOADING';
export const SIGNATURE_TYPE_LOADED = 'SIGNATURE_TYPE_LOADED';

export const COUPONS_SET = 'COUPONS_SET';
export const COUPON_EDIT_SET = 'COUPON_EDIT_SET';
export const COUPON_ID_EDIT_SET = 'COUPON_ID_EDIT_SET';
export const COUPON_LOADING = 'COUPON_LOADING';
export const COUPON_LOADED = 'COUPON_LOADED';
export const COUPON_CLEAR = 'COUPON_CLEAR';

export const COUPON_TYPES_SET = 'COUPON_TYPES_SET';
export const COUPON_TYPES_LOADING = 'COUPON_TYPES_LOADING';
export const COUPON_TYPES_LOADED = 'COUPON_TYPES_LOADED';

export const COUPON_POLES_SET = 'COUPON_POLES_SET';
export const COUPON_POLES_LOADING = 'COUPON_POLES_LOADING';
export const COUPON_POLES_LOADED = 'COUPON_POLES_LOADED';

export const COUPON_TYPE_APPLICATION_SET = 'COUPON_TYPE_APPLICATION_SET';
export const COUPON_TYPE_APPLICATION_LOADING = 'COUPON_TYPE_APPLICATION_LOADING';
export const COUPON_TYPE_APPLICATION_LOADED = 'COUPON_TYPE_APPLICATION_LOADED';

export const COUPON_PRODUCTS_SET = 'COUPON_PRODUCTS_SET';
export const COUPON_PRODUCTS_LOADING = 'COUPON_PRODUCTS_LOADING';
export const COUPON_PRODUCTS_LOADED = 'COUPON_PRODUCTS_LOADED';

export const SECTIONS_SET = 'SECTIONS_SET';
export const SECTION_EDIT_SET = 'SECTION_EDIT_SET';
export const SECTION_ID_EDIT_SET = 'SECTION_ID_EDIT_SET';
export const SECTION_LOADING = 'SECTION_LOADING';
export const SECTION_LOADED = 'SECTION_LOADED';
export const SECTION_CLEAR = 'SECTION_CLEAR';

export const SECTION_PRODUCTS_SET = 'SECTION_PRODUCTS_SET';
export const SECTION_PRODUCTS_LOADING = 'SECTION_PRODUCTS_LOADING';
export const SECTION_PRODUCTS_LOADED = 'SECTION_PRODUCTS_LOADED';

export const SECTION_PAYMENTPLANS_SET = 'SECTION_PAYMENTPLANS_SET';
export const SECTION_PAYMENTPLANS_LOADING = 'SECTION_PAYMENTPLANS_LOADING';
export const SECTION_PAYMENTPLANS_LOADED = 'SECTION_PAYMENTPLANS_LOADED';

export const PAYMENTPLANS_SET = 'PAYMENTPLANS_SET';
export const PAYMENTPLAN_EDIT_SET = 'PAYMENTPLAN_EDIT_SET';
export const PAYMENTPLAN_ID_EDIT_SET = 'PAYMENTPLAN_ID_EDIT_SET';
export const PAYMENTPLAN_LOADING = 'PAYMENTPLAN_LOADING';
export const PAYMENTPLAN_LOADED = 'PAYMENTPLAN_LOADED';
export const PAYMENTPLAN_CLEAR = 'PAYMENTPLAN_CLEAR';

export const CONFIGURATIONPLANS_SET = 'CONFIGURATIONPLAN_SET';
export const CONFIGURATIONPLAN_EDIT_SET = 'CONFIGURATIONPLAN_EDIT_SET';
export const CONFIGURATIONPLAN_ID_EDIT_SET = 'CONFIGURATIONPLAN_ID_EDIT_SET';
export const CONFIGURATIONPLAN_LOADING = 'CONFIGURATIONPLAN_LOADING';
export const CONFIGURATIONPLAN_LOADED = 'CONFIGURATIONPLAN_LOADED';
export const CONFIGURATIONPLAN_CLEAR = 'CONFIGURATIONPLAN_CLEAR';

export const CONFIGURATIONPLAN_TYPES_SET = 'CONFIGURATIONPLAN_TYPES_SET';
export const CONFIGURATIONPLAN_TYPES_LOADING = 'CONFIGURATIONPLAN_TYPES_LOADING';
export const CONFIGURATIONPLAN_TYPES_LOADED = 'CONFIGURATIONPLAN_TYPES_LOADED';

export const CONFIGURATIONPLAN_EXHIBITION_SET = 'CONFIGURATIONPLAN_EXHIBITION_SET';
export const CONFIGURATIONPLAN_EXHIBITION_LOADING = 'CONFIGURATIONPLAN_EXHIBITION_LOADING';
export const CONFIGURATIONPLAN_EXHIBITION_LOADED = 'CONFIGURATIONPLAN_EXHIBITION_LOADED';

export const PAYMENT_PLAN_CONFIGURATION_SET = 'PAYMENT_PLAN_CONFIGURATION_SET';
export const PAYMENT_PLAN_CONFIGURATION_LOADING = 'PAYMENT_PLAN_CONFIGURATION_LOADING';
export const PAYMENT_PLAN_CONFIGURATION_LOADED = 'PAYMENT_PLAN_CONFIGURATION_LOADED';


