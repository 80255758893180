import styled from 'styled-components';
import { colors, fonts } from '@/template/styles';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    position: fixed;
    display: flex;
    top: 0;
    left: ${(props) => (props.sideBarOpen ? `220px` : `0px`)};
    right: 0;
    background-color: #fff;
    height: 70px;
    align-items: center;
    padding: 0px 2rem;
    justify-content: space-between;
    z-index: 2;

    transition: all 0.3s ease 0.1s;
`;

export const ButtonToogle = styled.button`
    background: transparent !important;
    border: none;
    transition: all 0.5s;

    &:hover {
        opacity: 0.4;
    }
`;

export const ButtonUser = styled.button`
    display: flex;
    align-items: center;
    background: transparent !important;
    border: none;
    transition: all 0.5s;
`;

export const DropDownMenu = styled.div`
    position: absolute;
    top: 70px;
    right: 2rem;
    width: 210px;
    background: ${colors.white};
    list-style: none;
    margin-left: 20px;
    z-index: 0;
    display: ${(props) => (props.dropOpen ? `block` : `none`)};
    opacity: ${(props) => (props.dropOpen ? `1` : `0`)};

    border: 1px solid ${colors.lighter};
    border-radius: 0px 0px 5px 5px;

    -webkit-box-shadow: -2px 3px 5px -1px rgba(204, 204, 204, 1);
    -moz-box-shadow: -2px 3px 5px -1px rgba(204, 204, 204, 1);
    box-shadow: -2px 3px 5px -1px rgba(204, 204, 204, 1);

    transition: all 0.2s;

    ul {
        list-style: none;
        text-align: left;
    }

    ul li {
        display: flex;
        align-items: center;
        height: 35px;
        font-size: ${fonts.regular};
        padding-left: 2rem;
    }

    ul li:hover {
        background: ${colors.lighter};
    }

    &:hover {
        opacity: 1;
    }

    svg {
        margin-right: 1rem;
    }
`;

export const LinkDropDown = styled(Link)`
    overflow: hidden;
    display: flex;
    align-items: center;
    text-decoration: none;
    line-height: 35px;
    width: 100%;

    color: ${colors.regular};
`;
