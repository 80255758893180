/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'react-flexbox-grid';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';

import {
  RiArrowDropDownLine,
  RiCheckboxBlankLine,
  RiCheckboxFill,
  RiDeleteBin2Line,
} from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from '@/components/DataTable';
import SelectOne from '@/components/Forms/SelectOne';
import ContentLoader from 'react-content-loader';
import Badge from '@/components/Badge';

import { translate } from '@/i18n/src/locales';
import TitlePage from '@/components/TitlePage';
import Switcher from '@/components/Forms/Switcher';
import InputMoney from '@/components/Forms/InputMoney';
import Input from '@/components/Forms/Input';
import Card from '@/components/Card';
import Check from '@/components/Forms/Check';
import { colors } from '@/template/styles';
import { toBase64 } from '@/utils/ConvertBase64';
import {
  fetchProductByIdAdmInternal,
  fetchAllTrailsProductAdmInternal,
  addProduct,
  editProduct,
  addProductIntegration,
  removeProductIntegration,
  updateImageProduct,
} from '@/store/actions/product';

import { fetchSignatureType } from '@/store/actions/signatureType';

import ImageGalery from '@/assets/images/image-gallery.svg';

import * as S from './styles';

function ProductCreate() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const productEdit = useSelector((state) => state.product.productEdit);
  const productIsLoading = useSelector((state) => state.product.productIsLoading);
  const productTrailsIsLoading = useSelector((state) => state.product.productTrailsIsLoading);
  const productModulesIsLoading = useSelector((state) => state.product.productModulesIsLoading);

  const isTrailOnProduct = useSelector((state) => state.product.isTrailOnProduct);
  const isModuleOnProduct = useSelector((state) => state.product.isModuleOnProduct);
  const productTrails = useSelector((state) => state.product.productTrails);
  const productModules = useSelector((state) => state.product.productModules);
  const signatureType = useSelector((state) => state.signatureType.signatureType);

  const [isEdit, setIsEdit] = useState(false);

  const [imageProduct, setImageProduct] = useState(null);
  const [image64Product, setImage64Product] = useState(null);
  const [openCollapseTrail, setOpenCollapseTrail] = useState(false);
  const [openCollapseModule, setOpenCollapseModule] = useState(false);

  const [productName, setProductName] = useState('');
  const [productActive, setProductActive] = useState(true);
  const [productNameStatus, setProductNameStatus] = useState({
    status: '',
    feedback: '',
  });

  const [productPrice, setProductPrice] = useState('');
  const [productPriceStatus, setProductPriceStatus] = useState({
    status: '',
    feedback: '',
  });

  const [productCharterable, setProductCharterable] = useState('');
  const [productIsKitApostila, setProductIsKitApostila] = useState('');

  const [productExternalCode, setProductExternalCode] = useState('');

  const [productIsSignature, setProductIsSignature] = useState(null);

  const [inputSignature, setInputSignature] = useState(null);
  const [selectedSignature, setSelectedSignature] = useState(0);

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      dispatch(fetchProductByIdAdmInternal(id));
    } else {
      setIsEdit(false);
    }

    dispatch(fetchSignatureType());
  }, [id]);

  useEffect(() => {
    if (signatureType.length > 0) {
      setInputSignature(
        signatureType.map((item) => ({
          label: `${item.NomeTipo} - ${item.QtdDias} dias`,
          value: String(item.IdTipoAssinatura),
        }))
      );
    }
  }, [signatureType]);

  useEffect(() => {
    if (isEdit && productEdit) {
      if (productEdit.IdTipoAssinatura === 0) {
        setProductIsSignature(false);
      } else {
        setProductIsSignature(true);
      }

      setProductName(productEdit.Nome);
      setImageProduct(productEdit.Imagem);
      setProductPrice(productEdit.PrecoPadrao.toFixed(2).replace('.', ','));
      setProductExternalCode(productEdit.CodigoExterno || '');
      setProductCharterable(productEdit.Fretavel);
      setProductIsKitApostila(productEdit.KitApostila);
      setProductActive(productEdit.Ativo);
      setSelectedSignature(productEdit.IdTipoAssinatura);

      dispatch(fetchAllTrailsProductAdmInternal(productEdit.IdProduto));
    }
  }, [isEdit, productEdit]);

  useEffect(() => {
    if (isTrailOnProduct) {
      setOpenCollapseTrail(true);
    } else {
      setOpenCollapseTrail(false);
    }

    if (isModuleOnProduct) {
      setOpenCollapseModule(true);
    } else {
      setOpenCollapseModule(false);
    }
  }, [isTrailOnProduct, isModuleOnProduct]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const img64 = await toBase64(acceptedFiles[0]);
      setImage64Product(img64.split('base64,')[1]);
      setImageProduct(URL.createObjectURL(acceptedFiles[0]));

      if (isEdit) {
        dispatch(updateImageProduct(id, img64.split('base64,')[1]));
      }
    },
    [dispatch, setImageProduct, setImage64Product, isEdit]
  );

  const changeSignatureType = useCallback((item) => {
    setSelectedSignature(item.value);
  }, []);

  const removeImageProduct = useCallback(() => {
    setImageProduct(null);
  }, []);

  const handleAddProduct = useCallback(() => {
    let formErro = false;

    if (!productName) {
      setProductNameStatus({ status: 'danger', feedback: 'Campo obrigatório' });
      formErro = true;
    }

    if (!productPrice) {
      setProductPriceStatus({ status: 'danger', feedback: 'Campo obrigatório' });
      formErro = true;
    }

    if (!formErro) {
      dispatch(
        addProduct({
          Nome: productName,
          Descricao: null,
          PrecoPadrao: productPrice.replace(',', '.'),
          PercentualDesconto: 0,
          MaximoParcelas: 1,
          Ativo: productActive,
          IdProdutoStatus: 1,
          PrecoReal: 0,
          CodigoExterno: productExternalCode,
          IdTipoAssinatura: String(selectedSignature),
          Fretavel: productCharterable,
          Informacao: null,
          ImagemBase64: image64Product,
          KitApostila: productIsKitApostila
        })
      );
    }
  }, [
    dispatch,
    productName,
    productPrice,
    productExternalCode,
    productCharterable,
    productIsKitApostila,
    image64Product,
    selectedSignature,
    productActive,
  ]);

  const handleEditProduct = useCallback(() => {
    let formErro = false;

    if (!productName) {
      setProductNameStatus({ status: 'danger', feedback: 'Campo obrigatório' });
      formErro = true;
    }

    if (!productPrice) {
      setProductPriceStatus({ status: 'danger', feedback: 'Campo obrigatório' });
      formErro = true;
    }

    if (!formErro) {
      dispatch(
        editProduct({
          IdProduto: id,
          Nome: productName,
          Descricao: null,
          PrecoPadrao: productPrice.replace(',', '.'),
          PercentualDesconto: 0,
          MaximoParcelas: 1,
          Ativo: productActive,
          IdProdutoStatus: 1,
          PrecoReal: 0,
          CodigoExterno: productExternalCode,
          IdTipoAssinatura: selectedSignature,
          Fretavel: productCharterable,
          KitApostila: productIsKitApostila,
          Informacao: null,
        })
      );
    }
  }, [
    id,
    dispatch,
    productName,
    productPrice,
    productExternalCode,
    productCharterable,
    productIsKitApostila,
    image64Product,
    selectedSignature,
    productActive,
  ]);

  const toogleProductIntegration = useCallback(
    (IdModuleOrTrail, IdIntegration, enable) => {
      if (enable) {
        dispatch(
          addProductIntegration({
            IdProduto: productEdit.IdProduto,
            IdIntegracao: IdIntegration,
            CodigoExterno: String(IdModuleOrTrail),
          })
        );
      } else {
        dispatch(
          removeProductIntegration({
            IdProduto: productEdit.IdProduto,
            IdIntegracao: IdIntegration,
            CodigoExterno: String(IdModuleOrTrail),
          })
        );
      }
    },
    [dispatch, productEdit]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <Helmet>
        <title>
          Administração Franqueadora -{' '}
          {isEdit ? translate('productEdit') : translate('productCreate')}
        </title>
      </Helmet>
      <TitlePage title={isEdit ? translate('productEdit') : translate('productCreate')} />
      <Card>
        {productIsLoading ? (
          <ContentLoader
            width="100%"
            speed={1}
            height="380px"
            backgroundColor="#c6c6c6"
            foregroundColor="#F0F0F5"
          >
            <rect x="0" y="0" rx="5px" ry="5px" width="320px" height="300px" />
            <rect x="340" y="0" rx="5px" ry="5px" width="60%" height="40px" />
            <rect x="340" y="50" rx="5px" ry="5px" width="45%" height="40px" />
            <rect x="340" y="100" rx="5px" ry="5px" width="45%" height="40px" />
            <rect x="0" y="320" rx="5px" ry="5px" width="100%" height="50px" />
          </ContentLoader>
        ) : (
          <>
            <Row>
              <Col xs={12} md={4}>
                {imageProduct === null ? (
                  <S.ContainerUpload {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Solte o arquivo aqui...</p>
                    ) : (
                      <div>
                        <S.Img src={ImageGalery} />
                        <p>Escolha uma imagem para o produto, tamanho recomendado 350x326px</p>
                      </div>
                    )}
                  </S.ContainerUpload>
                ) : (
                  <S.ContentImgPreview>
                    <div className="remove-image" onClick={removeImageProduct}>
                      <RiDeleteBin2Line size={25} />
                    </div>
                    <S.ImgPreview src={imageProduct} />
                  </S.ContentImgPreview>
                )}
              </Col>
              <Col xs={12} md={8}>
                <Row>
                  <Col xs={12}>
                    <Input
                      name="name-adm"
                      type="text"
                      label="Nome do produto"
                      value={productName}
                      change={(value) => setProductName(value)}
                      status={productNameStatus}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <InputMoney
                      value={productPrice}
                      label="Preço"
                      status={productPriceStatus}
                      change={(value) => setProductPrice(value)}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Input
                      name="name-adm"
                      type="text"
                      label="Código externo"
                      value={productExternalCode}
                      change={(value) => setProductExternalCode(value)}
                    />
                  </Col>
                  <Col xs={6} md={2}>
                    <Check
                      status={productCharterable}
                      set={setProductCharterable}
                      label="Fretável"
                    />
                  </Col>
                  <Col xs={6} md={2}>
                    <Switcher set={setProductActive} value={productActive} label="Produto Ativo" />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={4}>
                    <Check
                      status={productIsSignature}
                      set={setProductIsSignature}
                      label="Produto possuí assinatura?"
                    />
                  </Col>
                  <Col xs={6} md={4}>
                    <Check
                      status={productIsKitApostila}
                      set={setProductIsKitApostila}
                      label="Esse Produto é um kit de apostila?"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="mt">
                    {productIsSignature && inputSignature && (
                      <SelectOne
                        options={inputSignature}
                        value={inputSignature.find(
                          (item) => item.value === String(selectedSignature)
                        )}
                        change={(item) => changeSignatureType(item)}
                        searchable
                        placeholder="Selecione"
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {isEdit ? (
                  <button type="submit" className="btn btn-primary mt" onClick={handleEditProduct}>
                    Salvar Mudanças
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary mt" onClick={handleAddProduct}>
                    Salvar
                  </button>
                )}
              </Col>
            </Row>
          </>
        )}
      </Card>
      {productIsLoading || productTrailsIsLoading || productModulesIsLoading ? (
        <ContentLoader
          width="100%"
          speed={1}
          height="380px"
          backgroundColor="#c6c6c6"
          foregroundColor="#F0F0F5"
        >
          <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="80px" />
          <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="80px" />
        </ContentLoader>
      ) : (
        isEdit && (
          <>
            <S.CollapseItem>
              <div className="header" onClick={() => setOpenCollapseTrail(!openCollapseTrail)}>
                <div className="title">
                  {isTrailOnProduct ? (
                    <RiCheckboxFill size={25} color={colors.success} />
                  ) : (
                    <RiCheckboxBlankLine size={25} />
                  )}
                  <div>Produto possui trilhas ?</div>
                </div>
                <div className="item-action">
                  <S.ArrowCollapse open={openCollapseTrail}>
                    <RiArrowDropDownLine size={30} />
                  </S.ArrowCollapse>
                </div>
              </div>
              <S.CollapseBody
                isOpen={openCollapseTrail}
                transition="height 0.4s cubic-bezier(.4, 0, .2, 1"
              >
                {productTrails && (
                  <DataTable
                    data={productTrails}
                    searchPlaceHolder="Buscar Trilha"
                    columns={[
                      {
                        name: 'NomeTrilha',
                        selector: 'NomeTrilha',
                        sortable: true,
                      },
                      {
                        width: '60px',
                        cell: (row) => (
                          <div
                            className="check-trail-module"
                            onClick={() =>
                              toogleProductIntegration(row.IdTrilha, 1, !row.Integrado)
                            }
                          >
                            {row.Integrado ? (
                              <RiCheckboxFill size={25} color={colors.success} />
                            ) : (
                              <RiCheckboxBlankLine size={25} />
                            )}
                          </div>
                        ),
                      },
                    ]}
                    columnFilter="NomeTrilha"
                  />
                )}
              </S.CollapseBody>
            </S.CollapseItem>

            <S.CollapseItem>
              <div className="header" onClick={() => setOpenCollapseModule(!openCollapseModule)}>
                <div className="title">
                  {isModuleOnProduct ? (
                    <RiCheckboxFill size={25} color={colors.success} />
                  ) : (
                    <RiCheckboxBlankLine size={25} />
                  )}
                  <div>Produto possui módulos ?</div>
                </div>
                <div className="item-action">
                  <S.ArrowCollapse open={openCollapseModule}>
                    <RiArrowDropDownLine size={30} />
                  </S.ArrowCollapse>
                </div>
              </div>
              <S.CollapseBody
                isOpen={openCollapseModule}
                transition="height 0.4s cubic-bezier(.4, 0, .2, 1"
              >
                <DataTable
                  data={productModules}
                  searchPlaceHolder="Buscar Trilha"
                  columns={[
                    {
                      compact: true,
                      center: true,
                      width: '60px',
                      cell: (row) => <img src={row.ImagemCurso} alt={row.NomeCurso} />,
                    },
                    {
                      name: 'NomeCurso',
                      selector: 'NomeCurso',
                      sortable: true,
                    },
                    {
                      width: '200px',
                      right: true,
                      cell: (row) =>
                        row.IntegradoTrilha ? (
                          <Badge type="warning">Integrado por trilha</Badge>
                        ) : (
                          <div
                            className="check-trail-module"
                            onClick={() => toogleProductIntegration(row.IdCurso, 2, !row.Integrado)}
                          >
                            {row.Integrado ? (
                              <RiCheckboxFill size={25} color={colors.success} />
                            ) : (
                              <RiCheckboxBlankLine size={25} />
                            )}
                          </div>
                        ),
                    },
                  ]}
                  columnFilter="NomeCurso"
                />
              </S.CollapseBody>
            </S.CollapseItem>
          </>
        )
      )}
    </>
  );
}

export default ProductCreate;
