import { POLES_SET, POLES_LOADING, POLES_LOADED, POLE_CREATED } from '../actionTypes';

const initialState = {
    poles: [],
    polesIsLoading: false,
    poleCreated: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case POLES_SET:
            return {
                ...state,
                poles: action.payload,
            };
        case POLES_LOADING:
            return {
                ...state,
                polesIsLoading: true,
            };
        case POLES_LOADED:
            return {
                ...state,
                polesIsLoading: false,
            };
        case POLE_CREATED:
                return {
                    ...state,
                    poleCreated: action.payload,
                };
        default:
            return state;
    }
};

export default reducer;
