import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as ROUTES from '@/routes/routes';

import { isAuthenticated } from '@/services/localStorageService';

const PrivateRoute = ({ component: Component, role: role, ...rest }) => {
  const roles = useSelector((state) => state.user.roles);
  if (isAuthenticated()) {
    if (roles.includes(role)) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    } else {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{
                pathname: ROUTES.NO_PERMISSION,
                state: { from: props.location },
              }}
            />
          )}
        />
      );
    }
  } else {
    return (
      <Route
        {...rest}
        render={(props) => <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }} />}
      />
    );
  }
};

export default PrivateRoute;
