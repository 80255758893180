/* eslint-disable no-console */
import { push } from 'connected-react-router';
import { apiLoja } from '@/services/api';
import * as ROUTES from '@/routes/routes';
import { showAlert } from '@/store/actions/theme';
import { translate } from '@/i18n/src/locales';
import * as _ from 'lodash';
import {
  COUPONS_SET,
  COUPON_EDIT_SET,
  COUPON_LOADING,
  COUPON_LOADED,
  COUPON_CLEAR,
  COUPON_PRODUCTS_SET,
  COUPON_PRODUCTS_LOADING,
  COUPON_PRODUCTS_LOADED,
} from '../actionTypes';

export const setCoupons = (coupons) => ({
  type: COUPONS_SET,
  payload: coupons,
});

export const setCouponEdit = (coupons) => ({
  type: COUPON_EDIT_SET,
  payload: coupons,
});

export const loadingCoupon = () => ({
  type: COUPON_LOADING,
});

export const loadedCoupon = () => ({
  type: COUPON_LOADED,
});

export const clearCoupon = () => ({
  type: COUPON_CLEAR,
});

export const setCouponProducts = (products, isProductsOnCoupon) => ({
  type: COUPON_PRODUCTS_SET,
  payload: { products, isProductsOnCoupon },
});

export const loadingCouponProducts = () => ({
  type: COUPON_PRODUCTS_LOADING,
});

export const loadedCouponProducts = () => ({
  type: COUPON_PRODUCTS_LOADED,
});

export const fetchCoupon = () => {
  return (dispatch) => {
    dispatch(loadingCoupon());
    apiLoja
      .get(`/Cupom`)
      .then((data) => {
        dispatch(setCoupons(data.data));
        dispatch(loadedCoupon());
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const addCoupon = (coupon) => {
  return (dispatch) => {
    dispatch(loadingCoupon());
    apiLoja
      .post(`/Cupom`, {
        ...coupon,
      })
      .then((data) => {
        dispatch(setCouponEdit(data.data));
        dispatch(loadedCoupon());
        dispatch(push(`${ROUTES.COUPON_CREATE}/${data.data.IdCupom}`));
        dispatch(
          showAlert({
            type: 'success',
            message: translate('couponAdd'),
            time: 3000,
          })
        );
      })
      .catch((error) => {
        if (error.response.status == 300) {
          dispatch(clearCoupon());
          dispatch(
            showAlert({
              type: 'warning',
              message: translate('couponExists'),
              time: 3000,
            })
          );
        } else if (error.response.status == 412) {
          dispatch(clearCoupon());
          dispatch(
            showAlert({
              type: 'warning',
              message: translate(error.response.data),
              time: 3000,
            })
          );
        } else console.log('error comming', error);
      });
  };
};

export const editCoupon = (coupon) => {
  return (dispatch) => {
    dispatch(loadingCoupon());
    apiLoja
      .put(`/Cupom`, {
        ...coupon,
      })
      .then((data) => {
        dispatch(setCouponEdit(data.data));
        dispatch(push(`${ROUTES.COUPON_CREATE}/${coupon.idCupom}`));
        dispatch(loadedCoupon());
        dispatch(
          showAlert({
            type: 'success',
            message: translate('couponEdited'),
            time: 3000,
          })
        );
      })
      .catch((error) => {
        if (error.response.status == 412) {
          dispatch(clearCoupon());
          dispatch(
            showAlert({
              type: 'warning',
              message: translate(error.response.data),
              time: 3000,
            })
          );
        }
        else {
          console.log('error comming', error);
          dispatch(clearCoupon());
          dispatch(
            showAlert({
              type: 'warning',
              message: translate(error.response.data),
              time: 3000,
            })
          );
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      });
  };
};

export const fetchAllCouponLoja = () => {
  return (dispatch) => {
    dispatch(loadingCoupon());
    apiLoja
      .get(`/Cupom`)
      .then((data) => {
        dispatch(setCoupons(data.data));
        dispatch(loadedCoupon());
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const fetchCouponById = (IdCupom) => {
  return (dispatch) => {
    dispatch(loadingCoupon());
    apiLoja
      .get(`/Cupom/${IdCupom}`)
      .then((data) => {
        dispatch(setCouponEdit(data.data));
        dispatch(loadedCoupon());
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const fetchAllProductsCouponLoja = (IdCupom) => {
  return (dispatch) => {
    dispatch(loadingCouponProducts());
    apiLoja
      .get(`/CupomProduto/${IdCupom}`)
      .then((data) => {
        if (data.data) {
          const ordered = _.orderBy(data.data, 'Vinculado', 'desc');

          const isProductOnCoupon = _.find(ordered, { Vinculado: true }) || false;

          if (isProductOnCoupon) {
            dispatch(setCouponProducts(ordered, true));
          } else {
            dispatch(setCouponProducts(ordered, false));
          }
        }

        dispatch(loadedCouponProducts());
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const addCouponProduct = (vinculado) => {
  return (dispatch) => {
    dispatch(loadingCouponProducts());
    apiLoja
      .post(`/CupomProduto`, {
        ...vinculado,
      })
      .then(() => {
        dispatch(fetchAllProductsCouponLoja(vinculado.IdCupom));
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const updateCouponProduct = (vinculado) => {
  return (dispatch) => {
    dispatch(loadingCouponProducts());
    apiLoja
      .put(`/CupomProduto`, {
        ...vinculado,
      })
      .then((data) => {
        dispatch(fetchAllProductsCouponLoja(vinculado.IdCupom));
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const fetchCouponProductsLoja = (cupomProduto) => {
  return (dispatch) => {
    cupomProduto.Ativo = true;
    dispatch(loadingCouponProducts());
    apiLoja
      .get(`/CupomProduto/${cupomProduto.IdCupom}/${cupomProduto.IdProduto}`)

      .then((data) => {
        if (data.data) {
          dispatch(updateCouponProduct(cupomProduto));
        }
        else {
          dispatch(addCouponProduct(cupomProduto));
        }
        dispatch(loadedCouponProducts());
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};