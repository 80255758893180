/* eslint-disable no-console */
import { api } from '@/services/api';
import { POLES_SET, POLES_LOADING, POLES_LOADED, POLE_CREATED } from '../actionTypes';
import { showAlert } from './theme';

export const setPoles = (poles) => ({
  type: POLES_SET,
  payload: poles,
});

export const loadingPoles = () => ({
  type: POLES_LOADING,
});

export const loadedPoles = () => ({
  type: POLES_LOADED,
});

export const createdPole = (created) => ({
  type: POLE_CREATED,
  payload: created,
});

export const fetchPoles = () => {
  return (dispatch) => {
    dispatch(loadingPoles());

    api
      .get('/Polo/GetAll')
      .then((data) => {
        dispatch(setPoles(data.data));
        dispatch(loadedPoles());
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const createPole = (data) => {
  return (dispatch) => {
    dispatch(loadingPoles());

    api
      .post(`/Polo`, data)
      .then(() => {
        dispatch(loadedPoles());
        dispatch(createdPole(true));
      })
      .catch((error) => {
        dispatch(
          showAlert({
            type: 'danger',
            message: error.response.data.Message,
            time: 3000,
          })
        );
        dispatch(loadedPoles());
        dispatch(createdPole(false));
      });
  };
};
