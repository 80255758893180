import React, { useState, useEffect } from 'react';

import TitlePage from '@/components/TitlePage';
import FooterInvoice from '@/components/Revenues/FooterInvoice';
import DetailsItemInvoice from '@/components/Revenues/DetailsItemInvoice';
import { translate } from '@/i18n/src/locales';
import Card from '@/components/Card';
import Badge from '@/components/Badge';
import { api } from '@/services/api';

import { RiArrowLeftSLine, RiArrowRightSLine, RiArrowDropDownLine } from 'react-icons/ri';
import Select from 'react-select';
import SelectOne from '@/components/Forms/SelectOne';
import Carousel from 'nuka-carousel';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
import { pt } from 'date-fns/esm/locale';
import { useSelector, useDispatch } from 'react-redux';

import { fetchPoles } from '@/store/actions/pole';
import { fecthInvoices, fecthInvoiceItens } from '@/store/actions/invoice';
import ContentLoader from 'react-content-loader';

import {
  BoxInvoice,
  Item,
  BoxForm,
  ButtonCarousel,
  BoxItensInvoice,
  BoxFooter,
  HeaderInvoice,
  CollapseItem,
  ArrowCollapse,
  BoxPeriodoReferencia,
} from './styles';

function Revenues() {
  const poles = useSelector((state) => state.pole.poles);
  const polesIsLoading = useSelector((state) => state.pole.polesIsLoading);

  const invoices = useSelector((state) => state.invoice.invoices);
  const invoicesIsLoading = useSelector((state) => state.invoice.invoicesIsLoading);

  const invoiceItens = useSelector((state) => state.invoice.invoiceItens);
  const invoiceItensIsLoading = useSelector((state) => state.invoice.invoiceItensIsLoading);

  const [indexCarousel, setIndexCarousel] = useState(0);
  const [invoice, setInvoice] = useState(null);
  const [inputPoles, setInputPoles] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPoles());
  }, []);

  useEffect(() => {
    if (poles.length > 0) {
      setInputPoles(
        poles.map((item) => ({
          label: `${item.codigoExternoPolo} - ${item.nomePolo}`,
          value: String(item.idPolo),
        }))
      );
    }
  }, [poles]);

  useEffect(() => {
    if (invoicesIsLoading == false) {
      if (invoices.length > 0) {
        var lastItem = invoices[invoices.length - 1];
        setInvoice(lastItem);
        dispatch(fecthInvoiceItens(lastItem.idFatura, lastItem.idPolo));
        setIndexCarousel(invoices.length > 0 ? invoices.length - 1 : 0);
      }
    }
  }, [invoicesIsLoading]);

  function handleChangePolo(item) {
    dispatch(fecthInvoices(item.value));

    setInvoice();
    dispatch(fecthInvoiceItens());
  }

  function handleSelectItem(item, index) {
    setInvoice(item);
    setIndexCarousel(parseInt(index));
    dispatch(fecthInvoiceItens(item.idFatura, item.idPolo));
  }

  function handleAfterSlide(index) {
    setIndexCarousel(index);
  }

  return (
    <>
      <Helmet>
        <title>Administração Franqueadora - {translate('revenues')}</title>
      </Helmet>
      <TitlePage title={translate('revenues')} />
      <Card>
        <BoxForm>
          {polesIsLoading ? (
            <ContentLoader width={'100%'} speed={1} height={'40px'}>
              <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="40px" />
            </ContentLoader>
          ) : (
            <SelectOne
              options={inputPoles}
              change={(item) => handleChangePolo(item)}
              searchable={true}
              placeholder={translate('selectAUnit')}
            />
          )}
        </BoxForm>
        {invoicesIsLoading ? (
          <ContentLoader width={'100%'} speed={1} height={'110px'}>
            <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="110px" />
          </ContentLoader>
        ) : (
          <BoxInvoice>
            {invoices ? (
              <Carousel
                slideIndex={indexCarousel}
                afterSlide={(index) => handleAfterSlide(index)}
                dragging={false}
                slidesToShow={4}
                cellAlign={'center'}
                withoutControls={false}
                renderBottomCenterControls={null}
                renderCenterLeftControls={({ previousSlide }) => (
                  <ButtonCarousel onClick={previousSlide}>
                    <RiArrowLeftSLine color={'#fff'} size={40} />
                  </ButtonCarousel>
                )}
                renderCenterRightControls={({ nextSlide }) => (
                  <ButtonCarousel onClick={nextSlide}>
                    <RiArrowRightSLine color={'#fff'} size={40} />
                  </ButtonCarousel>
                )}
              >
                {invoices.map((item, index) => (
                  <ItemCarousel
                    key={item.idFatura}
                    item={item}
                    index={index}
                    handleSelectItem={(item, index) => handleSelectItem(item, index)}
                  />
                ))}
              </Carousel>
            ) : null}
          </BoxInvoice>
        )}

        {invoice ? (
          <BoxPeriodoReferencia>
            {translate('referenceMounth')}:{' '}
            {format(new Date(invoice.anoReferencia, invoice.mesReferencia - 1), 'MMM/yyyy', {
              locale: pt,
            })}
          </BoxPeriodoReferencia>
        ) : null}
        {invoiceItensIsLoading ? (
          <BoxItensInvoice>
            <ContentLoader width={'100%'} speed={1} height={'280px'}>
              <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="80px" />
              <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="80px" />
              <rect x="0" y="200" rx="5px" ry="5px" width="100%" height="80px" />
            </ContentLoader>
          </BoxItensInvoice>
        ) : (
          <BoxItensInvoice>
            {invoiceItens.map((item) => (
              <ItemInvoice key={item.idFaturaItem} item={item} />
            ))}
          </BoxItensInvoice>
        )}
      </Card>
      {invoiceItensIsLoading ? (
        <ContentLoader
          width={'100%'}
          speed={1}
          height={'120px'}
          backgroundColor={'#c6c6c6'}
          foregroundColor={'#F0F0F5'}
        >
          <rect x="0" y="20" rx="5px" ry="5px" width="33%" height="100px" />
          <rect x="34%" y="20" rx="5px" ry="5px" width="33%" height="100px" />
          <rect x="68%" y="20" rx="5px" ry="5px" width="33%" height="100px" />
        </ContentLoader>
      ) : (
        <BoxFooter>{invoice ? <FooterInvoice item={invoice} /> : null}</BoxFooter>
      )}
    </>
  );
}

const ItemCarousel = ({ item, handleSelectItem, index }) => {
  return (
    <Item key={item.idFatura} data-id-fatura={item.idFatura} onClick={() => handleSelectItem(item, index)}>
      <div>
        {format(new Date(item.anoCompetencia, item.mesCompetencia - 1), 'MMM/yyyy', {
          locale: pt,
        })}
      </div>
      <div>
        {item.valorFatura.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </div>
      {item.valorTotalFatura ? <div className="badge generated" /> : <div className="badge not-generated" />}
    </Item>
  );
};

const ItemInvoice = ({ item }) => {
  const [open, setOpen] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [detailsItemInvoice, setDetailsItemInvoice] = useState([]);

  useEffect(() => {
    if (open) {
      setIsLoading(true);

      let url = '';

      switch (item.poloLicenca.idLicencaTipoFaturamento) {
        case 40:
          url = `/Faturamento/GetFaturaItemDetalheVoucherGerado/${item.idFaturaItem}`;
          break;
        case 41:
          url = `/Faturamento/GetFaturaItemDetalheVoucherMatriculaAssinaturaTrilha/${item.idFaturaItem}`;
          break;
        default:
          url = `/Faturamento/GetFaturaItemDetalheAluno/${item.idFaturaItem}`;
          break;
      }

      api
        .get(url)
        .then(function (response) {
          setDetailsItemInvoice(response.data);
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [open]);

  function handleOpen() {
    setOpen(!open);
  }

  return (
    <>
      <HeaderInvoice onClick={() => handleOpen()}>
        <div>{item.poloLicenca.descricao}</div>
        <div className="item">
          <div className="titulo">{translate('plan')}</div>
          <div>{item.poloLicenca.licencaTipoFaturamento.descricao}</div>
        </div>
        <div className="item">
          <div className="titulo">{translate('value')}</div>
          <div>
            {item.valorItem.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </div>
        </div>
        <div className="item">
          <div className="titulo">{translate('discount')}</div>
          <div>
            {item.valorDesconto.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </div>
        </div>
        <div className="item">
          <div className="titulo">{translate('totalItemValue')}</div>
          <div>
            {item.valorTotal.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </div>
        </div>
        <div className="item-action">
          <ArrowCollapse open={open}>
            <RiArrowDropDownLine size={30} />
          </ArrowCollapse>
        </div>
      </HeaderInvoice>
      {detailsItemInvoice.length > 0 ? (
        <CollapseItem isOpen={open} transition={`height 0.4s cubic-bezier(.4, 0, .2, 1)`}>
          {isLoading ? (
            <ContentLoader viewBox="0 0 380 60">
              <rect x="0" y="0" rx="1" ry="1" width="100%" height="10" />
              <rect x="0" y="15" rx="1" ry="1" width="100%" height="10" />
              <rect x="0" y="30" rx="1" ry="1" width="100%" height="10" />
              <rect x="0" y="45" rx="1" ry="1" width="100%" height="10" />
              <rect x="0" y="60" rx="1" ry="1" width="100%" height="10" />
            </ContentLoader>
          ) : (
            <DetailsItemInvoice
              detailsItemInvoice={detailsItemInvoice}
              idLicencaTipoFaturamento={item.poloLicenca.idLicencaTipoFaturamento}
            />
          )}
        </CollapseItem>
      ) : null}
    </>
  );
};

export default Revenues;
