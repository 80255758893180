import React, { useState } from 'react';

import { RiArrowDropDownLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { HeadingItem, CollapseItem, HeadingItemCollapse, ContainerItem } from './styles';

function Item(props) {
    const [open, setOpen] = useState(false);

    function handleClick() {
        setOpen(!open);
    }

    if (props.collapse) {
        return (
            <ContainerItem>
                <HeadingItemCollapse onClick={() => handleClick()} open={open}>
                    <div className="label">
                        {props.icon}
                        {props.title}
                    </div>
                    <div className="arrow">
                        <RiArrowDropDownLine size={30} />
                    </div>
                </HeadingItemCollapse>
                <CollapseItem isOpen={open} transition={`height 0.4s cubic-bezier(.4, 0, .2, 1)`}>
                    {props.children}
                </CollapseItem>
            </ContainerItem>
        );
    } else {
        return (
            <ContainerItem>
                <HeadingItem>
                    {props.icon}
                    {props.children}
                </HeadingItem>
            </ContainerItem>
        );
    }
}

export default Item;
