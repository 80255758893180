import styled from 'styled-components';
import Collapse from '@kunukn/react-collapse';

export const ContainerItem = styled.div`
    color: white;
    width: 100%;
    cursor: pointer;
`;

export const HeadingItem = styled.div`
    overflow: hidden;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #eee;
    line-height: 50px;
    width: 100%;
    cursor: pointer;

    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    svg {
        margin-right: 1.5rem;
    }

    &:hover {
        color: #fff;
        background: #242f47;
    }

    .active {
        background: #242f47;
    }
`;

export const HeadingItemCollapse = styled.div`
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #eee;
    line-height: 50px;
    width: 100%;
    padding: 0px 0px 0px 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    cursor: pointer;

    &:hover {
        color: #fff;
        background: #242f47;
    }

    .label {
        display: flex;
        align-items: center;
    }

    .label svg {
        margin-right: 15px;
    }

    .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: ${(props) => {
            if (props.open) {
                return `rotate(180deg)`;
            } else {
                return `rotate(0deg)`;
            }
        }};

        transition: all 0.2s;
    }
`;

export const CollapseItem = styled(Collapse)`
    color: white;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    .active {
        background: #242f47;
    }

    svg {
        margin-left: 1.5rem;
        margin-right: 1rem;
    }
`;
