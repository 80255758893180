import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

  svg {
    margin-right: 0.5rem;
  }
`;
