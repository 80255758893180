import {
    INVOICES_SET,
    INVOICES_LOADING,
    INVOICES_LOADED,
    INVOICE_ITENS_SET,
    INVOICE_ITENS_LOADING,
    INVOICE_ITENS_LOADED,
} from '../actionTypes';

import { api } from '@/services/api';

export const setInvoices = (invoices) => ({
    type: INVOICES_SET,
    payload: invoices,
});

export const loadingInvoices = () => ({
    type: INVOICES_LOADING,
});

export const loadedInvoices = () => ({
    type: INVOICES_LOADED,
});

export const setInvoiceItens = (invoiceItens) => ({
    type: INVOICE_ITENS_SET,
    payload: invoiceItens,
});

export const loadingInvoiceItens = () => ({
    type: INVOICE_ITENS_LOADING,
});

export const loadedInvoiceItens = () => ({
    type: INVOICE_ITENS_LOADED,
});

export const fecthInvoices = (idPolo) => {
    return (dispatch, getState) => {
        dispatch(loadingInvoices());

        api.get(`/Faturamento/GetAll/${idPolo}`)
            .then((data) => {
                dispatch(setInvoices(data.data));
                dispatch(loadedInvoices());
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const fecthInvoiceItens = (idFatura, idPolo) => {
    return (dispatch, getState) => {
        dispatch(loadingInvoiceItens());
        api.get(`/Faturamento/GetFaturaItem/${idFatura}/${idPolo}`)
            .then((data) => {
                dispatch(setInvoiceItens(data.data));
                dispatch(loadedInvoiceItens());
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};
