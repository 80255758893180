import React from 'react';
import { Helmet } from 'react-helmet';

import { translate } from '@/i18n/src/locales';
import TitlePage from '@/components/TitlePage';
import Card from '@/components/Card';
// import { Container } from './styles';

function FranchisorCreate() {
  return (
    <>
      <Helmet>
        <title>Administração Franqueadora - {translate('franchisorCreate')}</title>
      </Helmet>
      <TitlePage title={translate('franchisorCreate')} />
      <Card>teste</Card>
    </>
  );
}

export default FranchisorCreate;
