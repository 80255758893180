import { PLANS_SET, PLANS_LOADING, PLANS_LOADED } from '../actionTypes';

const initialState = {
  plans: [],
  plansIsLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PLANS_SET:
      return {
        ...state,
        plans: action.payload,
      };
    case PLANS_LOADING:
      return {
        ...state,
        plansIsLoading: true,
      };
    case PLANS_LOADED:
      return {
        ...state,
        plansIsLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
