import styled from 'styled-components';
import { metrics, fonts } from '@/template/styles';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
`;

export const BoxLogin = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const Img = styled.img`
    width: 100%;
    padding: ${metrics.space};
`;
