import {
  PRODUCTS_SET,
  PRODUCT_EDIT_SET,
  PRODUCT_LOADING,
  PRODUCT_LOADED,
  PRODUCT_CLEAR,
  PRODUCT_TRAILS_SET,
  PRODUCT_TRAILS_LOADING,
  PRODUCT_TRAILS_LOADED,
  PRODUCT_MODULES_SET,
  PRODUCT_MODULES_LOADING,
  PRODUCT_MODULES_LOADED,
  PRODUCT_ID_EDIT_SET,
} from '../actionTypes';

const initialState = {
  products: [],
  productEdit: null,
  productIdEdit: null,
  productTrails: [],
  isTrailOnProduct: false,
  productModules: [],
  productIsModule: false,
  productIsLoading: false,
  productTrailsIsLoading: false,
  productModulesIsLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_SET:
      return {
        ...state,
        products: action.payload,
      };
    case PRODUCT_EDIT_SET:
      return {
        ...state,
        productEdit: action.payload,
      };
    case PRODUCT_ID_EDIT_SET:
      return {
        ...state,
        productIdEdit: action.payload,
      };
    case PRODUCT_LOADING:
      return {
        ...state,
        productIsLoading: true,
      };
    case PRODUCT_LOADED:
      return {
        ...state,
        productIsLoading: false,
      };
    case PRODUCT_CLEAR:
      return {
        ...state,
        products: [],
        productIsLoading: false,
      };
    case PRODUCT_TRAILS_SET:
      return {
        ...state,
        productTrails: action.payload.trails,
        isTrailOnProduct: action.payload.isTrailOnProduct,
        isModuleOnProduct: action.payload.isTrailOnProduct,
      };
    case PRODUCT_TRAILS_LOADING:
      return {
        ...state,
        productTrailsIsLoading: true,
        productIsTrail: false,
      };
    case PRODUCT_TRAILS_LOADED:
      return {
        ...state,
        productTrailsIsLoading: false,
      };
    case PRODUCT_MODULES_SET:
      return {
        ...state,
        productModules: action.payload.modules,
        isModuleOnProduct: action.payload.isModuleOnProduct,
      };
    case PRODUCT_MODULES_LOADING:
      return {
        ...state,
        productModulesIsLoading: true,
        productIsModule: false,
      };
    case PRODUCT_MODULES_LOADED:
      return {
        ...state,
        productModulesIsLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
