import {
  COUPONS_SET,
  COUPON_EDIT_SET,
  COUPON_LOADING,
  COUPON_LOADED,
  COUPON_CLEAR,
  COUPON_PRODUCTS_SET,
  COUPON_PRODUCTS_LOADING,
  COUPON_PRODUCTS_LOADED,
  COUPON_ID_EDIT_SET,
} from '../actionTypes';

const initialState = {
  coupons: [],
  couponEdit: null,
  couponIdEdit: null,
  couponProducts: [],
  isProductsOnCoupon: false,
  couponIsLoading: false,
  couponProductsIsLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COUPONS_SET:
      return {
        ...state,
        coupons: action.payload,
      };
    case COUPON_EDIT_SET:
      return {
        ...state,
        couponEdit: action.payload,
      };
    case COUPON_ID_EDIT_SET:
      return {
        ...state,
        couponIdEdit: action.payload,
      };
    case COUPON_LOADING:
      return {
        ...state,
        couponIsLoading: true,
      };
    case COUPON_LOADED:
      return {
        ...state,
        couponIsLoading: false,
      };
    case COUPON_CLEAR:
      return {
        ...state,
        coupons: [],
        couponIsLoading: false,
      };
    case COUPON_PRODUCTS_SET:
      return {
        ...state,
        couponProducts: action.payload.products,
        isProductsOnCoupon: action.payload.isProductsOnCoupon,
      };
    case COUPON_PRODUCTS_LOADING:
      return {
        ...state,
        couponProductsIsLoading: true,
        couponIsProduct: false,
      };
    case COUPON_PRODUCTS_LOADED:
      return {
        ...state,
        couponProductsIsLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
