import {
    SALES_SET,
    SALES_LOADING,
    SALES_LOADED,
    SALES_CLEAR,
    SALES_FILTER_SET,
} from '../actionTypes';

import { translate } from '@/i18n/src/locales';

const initialState = {
    sales: [],
    salesIsLoading: false,
    filters: [
        { id: 1, description: translate('last7Days'), active: true },
        { id: 2, description: translate('last30Days'), active: false },
        { id: 3, description: translate('period'), active: false },
    ],
    filterCurrent: { id: 1, description: translate('last7Days'), active: true },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SALES_SET:
            return {
                ...state,
                sales: action.payload,
            };
        case SALES_LOADING:
            return {
                ...state,
                salesIsLoading: true,
            };
        case SALES_LOADED:
            return {
                ...state,
                salesIsLoading: false,
            };
        case SALES_CLEAR:
            return {
                ...state,
                sales: [],
                salesIsLoading: false,
                filters: [
                    { id: 1, description: translate('last7Days'), active: true },
                    { id: 2, description: translate('last30Days'), active: false },
                    { id: 3, description: translate('period'), active: false },
                ],
                filterCurrent: { id: 1, description: translate('last7Days'), active: true },
            };
        case SALES_FILTER_SET:
            return {
                ...state,
                filters: action.payload,
                filterCurrent: action.payload.filter((item) => item.active === true)[0],
            };
        default:
            return state;
    }
};

export default reducer;
