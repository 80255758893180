/* eslint-disable no-console */
import { push } from 'connected-react-router';
import { apiLoja } from '@/services/api';
import * as ROUTES from '@/routes/routes';
import { showAlert } from '@/store/actions/theme';
import { translate } from '@/i18n/src/locales';
import * as _ from 'lodash';
import {
    CONFIGURATIONPLANS_SET,
    CONFIGURATIONPLAN_EDIT_SET,
    CONFIGURATIONPLAN_LOADING,
    CONFIGURATIONPLAN_LOADED,
    CONFIGURATIONPLAN_CLEAR,

    PAYMENT_PLAN_CONFIGURATION_SET,
    PAYMENT_PLAN_CONFIGURATION_LOADING,
    PAYMENT_PLAN_CONFIGURATION_LOADED
} from '../actionTypes';

export const setConfigurationPlans = (configurationPlans) => ({
    type: CONFIGURATIONPLANS_SET,
    payload: configurationPlans,
});

export const setConfigurationPlanEdit = (configurationPlans) => ({
    type: CONFIGURATIONPLAN_EDIT_SET,
    payload: configurationPlans,
});

export const loadingConfigurationPlan = () => ({
    type: CONFIGURATIONPLAN_LOADING,
});

export const loadedConfigurationPlan = () => ({
    type: CONFIGURATIONPLAN_LOADED,
});

export const clearConfigurationPlan = () => ({
    type: CONFIGURATIONPLAN_CLEAR,
});

export const setConfigurationPlansPayment = (plansConfiguration, isConfigurationPlanOnPaymentPlan) => ({
    type: PAYMENT_PLAN_CONFIGURATION_SET,
    payload: { plansConfiguration, isConfigurationPlanOnPaymentPlan },
});

export const loadingConfigurationPlansPayment = () => ({
    type: PAYMENT_PLAN_CONFIGURATION_LOADING,
});

export const loadedConfigurationPlansPayment = () => ({
    type: PAYMENT_PLAN_CONFIGURATION_LOADED,
});



export const addConfigurationPlan = (configurationPlan) => {
    return (dispatch) => {
        dispatch(loadingConfigurationPlan());
        apiLoja
            .post(`/PlanoConfiguracao/Add`, {
                ...configurationPlan,
            })
            .then((data) => {
                dispatch(setConfigurationPlanEdit(data.data));
                dispatch(loadedConfigurationPlan());
                dispatch(push(`${ROUTES.CONFIGURATIONPLAN_CREATE}/${data.data.Result.IdPlanoConfiguracao}`));
                dispatch(
                    showAlert({
                        type: 'success',
                        message: translate('paymentPlanAdd'),
                        time: 3000,
                    })
                );
            })
            .catch((error) => {
                if (error.response.status === 300) {
                    dispatch(clearConfigurationPlan());
                    dispatch(
                        showAlert({
                            type: 'warning',
                            message: translate('paymentPlanExists'),
                            time: 3000,
                        })
                    );
                } else if (error.response.status === 412) {
                    dispatch(clearConfigurationPlan());
                    dispatch(
                        showAlert({
                            type: 'warning',
                            message: translate(error.response.data),
                            time: 3000,
                        })
                    );
                } else console.log('error comming', error);
            });
    };
};

export const editConfigurationPlan = (configurationPlan) => {
    return (dispatch) => {
        dispatch(loadingConfigurationPlan());
        apiLoja
            .put(`/PlanoConfiguracao`, {
                ...configurationPlan,
            })
            .then((data) => {
                dispatch(setConfigurationPlanEdit(data.data));
                dispatch(push(`${ROUTES.CONFIGURATIONPLAN_CREATE}/${configurationPlan.IdPlanoConfiguracao}`));
                dispatch(loadedConfigurationPlan());
                dispatch(
                    showAlert({
                        type: 'success',
                        message: translate('paymentPlanEdited'),
                        time: 3000,
                    })
                );
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const fetchAllConfigurationPlans = () => {
    return (dispatch) => {
        dispatch(loadingConfigurationPlan());
        apiLoja
            .get(`/PlanoConfiguracao`)
            .then((data) => {
                dispatch(setConfigurationPlans(data.data));
                dispatch(loadedConfigurationPlan());
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const fetchConfigurationPlanById = (idPlanoConfiguracao) => {
    return (dispatch) => {
        dispatch(loadingConfigurationPlan());

        apiLoja
            .get(`/PlanoConfiguracao/${idPlanoConfiguracao}`)
            .then((data) => {
                dispatch(setConfigurationPlanEdit(data.data));
                dispatch(loadedConfigurationPlan());
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log('error comming', error);
            });
    };
};

export const fetchAllPlanConfigurationPlanLinked = (IdPlanoPagamento) => {
    return (dispatch) => {
        dispatch(loadingConfigurationPlansPayment());
        apiLoja
            .get(`/PlanoConfiguracaoIntegraca/${IdPlanoPagamento}`)
            .then((data) => {
                const ordered = _.orderBy(data.data, 'Vinculado', 'desc');
                const isConfigurationPlanOnPaymentPlan = _.find(ordered, { Vinculado: true }) || false;

                console.log(ordered)
                if (isConfigurationPlanOnPaymentPlan) {
                    dispatch(setConfigurationPlansPayment(ordered, true));
                } else {
                    dispatch(setConfigurationPlansPayment(ordered, false));
                }
                dispatch(loadedConfigurationPlansPayment());
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const addConfigurationPlanDetails = (configurationPlanDetails) => {
    return (dispatch) => {
        dispatch(loadingConfigurationPlan());
        apiLoja
            .post(`/PlanoPagamentoDetalhes/Add`, {
                ...configurationPlanDetails,
            })
            .then((data) => {
                dispatch(fetchAllPlanConfigurationPlanLinked(configurationPlanDetails.IdPlanoPagamento));
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};


export const removeConfigurationPlanDetails = (configurationPlanDetails) => {
    return (dispatch) => {
        dispatch(loadingConfigurationPlan());
        apiLoja
            .delete(`/PlanoPagamentoDetalhes`, {
                data: {
                    ...configurationPlanDetails,
                },
            })
            .then((data) => {
                dispatch(fetchAllPlanConfigurationPlanLinked(configurationPlanDetails.IdPlanoPagamento));
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};