/* eslint-disable jsx-a11y/click-events-have-key-events */
import { colors } from '@/template/styles';
import React from 'react';
import Switch from 'react-switch';

import { Container } from './styles';

function Switcher({ set, value, label, disabled = false }) {
  return (
    <Container>
      {label && <div className="label">{label}</div>}
      <Switch
        onChange={(v) => set(v)}
        checked={value}
        onColor={colors.success}
        offColor={colors.danger}
        disabled={disabled}
        width={50}
        height={20}
      />
    </Container>
  );
}

export default Switcher;
