import React from 'react';

import { AvatarImage } from './styles';
import AvatarImg from '@/assets/images/avatar-svgrepo-com.svg';

function Avatar() {
    return (
        <AvatarImage src={AvatarImg} />
    );
}

export default Avatar;
