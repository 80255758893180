import React from 'react';

import { Container, Label } from './styles';

function Input({ name, value, type, label, status = false, change, maxLength }) {
  return (
    <Container status={status.status}>
      <Label status={status.status}>{label}</Label>
      <input name={name} type={type} value={value} onChange={(e) => change(e.target.value)} maxLength={maxLength} />
      <div className="feedback">{status.feedback}</div>
    </Container>
  );
}

export default Input;
