// pt-BR.js
export default {
  signIn: 'Iniciar Sesisón',
  user: 'Usuario',
  password: 'Contraseña',
  start: 'Comienzo',
  revenues: 'Factura',
  financial: 'Financiero',
  store: 'Tienda',
  vouchers: 'Vales',
  voucherCreate: 'Crear vale',
  informAmount: 'Ingrese la cantidad de 1 a 25',
  sales: 'Ventas',
  pole: 'Polo',
  poles: 'Polos',
  poleCreate: 'Crear Polo',
  poleName: 'Nombre Polo',
  createAnother: 'Crea otro poste',
  invalidUserOrPassword: '¡Usuario / contraseña incorrecta!',
  hello: 'Hola',
  welcomeToYourAdministration: 'Bienvenido a su sitio, administrador.',
  logout: 'Logout',
  selectAUnit: 'Seleccionar unidad',
  plan: 'Plan',
  value: 'Valor',
  discount: 'Descuento',
  totalItemValue: 'Valor total del artículo',
  total: 'Total',
  retroactive: 'Retroactivo',
  referenceMounth: 'Mes de referencia',
  studentName: 'Nombre del estudiante',
  moduleTitle: 'Módulo de título',
  class: 'Clase',
  billable: 'Facturable',
  registrationDate: 'Fecha de Registro',
  billed: 'Facturado',
  unbilled: 'No facturado',
  selectAProduct: 'Seleccione un producto',
  selectAPlan: 'Seleccione un plan',
  all: 'Todas',
  available: 'Disponible',
  inUse: 'En uso',
  consumed: 'Consumado',
  product: 'Producto',
  products: 'Productos',
  productCreate: 'Crear Producto',
  productEdit: 'Cambiar de Producto',
  last7Days: 'Los últimos 7 días',
  last30Days: 'Los últimos 30 días',
  period: 'Período',
  status: 'Estado',
  registerDate: 'Fecha de Registro',
  expiredOn: 'Expirado el',
  student: 'Alumno',
  saleDate: 'Fecha de venta',
  typeOfSale: 'Tipo venta',
  dueDate: 'Fecha de vencimiento',
  paymentStatus: 'Estado de pago',
  formOfPayment: 'Forma de pago',
  installments: 'Parte',
  installmentValue: 'Valor a parte',
  quantity: 'Cantidad',
  interest: 'Interesar',
  dispatch: 'Flete',
  totalPaid: 'Total pagado',
  externalCode: 'Código externo',
  searchPoleName: 'Buscar nombre de polo',
  adminName: 'Nombre de administrador',
  adminEmail: 'Correo electrónico del administrador',
  invalidEmail: 'Email inválido',
  creatingPole: 'Creando poste',
  successfullyCreated: 'Creado con éxito',
  hash: 'Picadillo',
  genaratedDate: 'Fecha generada',
  signature: 'Firma',
  trail: 'Sendero',
  course: 'Curso',
  enrollmentDate: 'Fecha de inscripción',
  rg: 'RG',
  cpf: 'CPF',
  address: 'Dirección',
  number: 'Número',
  complement: 'Complemento',
  neighborhood: 'Barrio',
  city: 'Ciudad',
  state: 'Estado',
  legalPerson: 'Persona jurídica',
  physicalPerson: 'Pessoa Fisíca',
  selectedPearson: 'Persona física',
  admNameStatus: 'Ingrese el nombre del administrador del poste',
  poleNameStatus: 'Ingrese el nombre del poste',
  rgStatus: 'Informar el documento',
  cpfStatus: 'Informar el documento',
  telefoneStatus: 'Ingrese el teléfono con DDN',
  celularStatus: 'Ingrese el teléfono móvil con DDN',
  nomeFantasiaStatus: 'Ingrese su nombre de fantasía',
  razaoSocialStatus: 'Ingrese el nombre de la empresa',
  cnpjStatus: 'Informar al number empresa',
  ieStatus: 'Informar al number empresa',
  logradouroStatus: 'Ingrese la dirección',
  numberStatus: 'informar el número de la dirección',
  complementoStatus: 'Informar el complemento',
  neighborhoodStatus: 'Informar al vecindario',
  cityStatus: 'Informar a la ciudad',
  stateStatus: 'Ingrese el estado',
  cepStatus: 'Ingrese el código postal',
  nomeFantasia: 'Nombre de fantasía',
  razaoSocial: 'Razón social',
  cnpj: 'CNPJ',
  ie: 'IE',
  cep: 'Código Postal',
  differentPaymentAddress: '¿Dirección de pago diferente?',
  differentShippingAddress: '¿Dirección de entrega diferente?',
  addressMain: 'Dirección primaria',
  telefone: 'Teléfono',
  celular: 'Teléfono móvil',
  franchisor: 'Franquiciador',
  franchisors: 'Franquiciadores',
  franchisorCreate: 'Crear Franquiciador',
  franchisorEdit: 'Cambiar de Franquiciador',
  coupons: 'Cupones',
  couponCreate: 'Crear cupón',
  searchCouponName: 'Buscar el nombre del cupón',
  couponName: 'nombre del cupón',
  active: 'activo',
  inactive: 'Inactivo',
  type: 'Escribe',
  startDate: 'La fecha de inicio',
  language: 'es-ES',
  typeCoupom: 'Escribe del Cupón',
  activeCoupon: 'Cupón Activo',
  selectAType: 'seleccione un tipo',
  couponEdit: 'Editar cupón',
  couponExists: 'El cupón ya existe',
  validateDate: 'Fecha de expiracion inválida',
  couponAdd: '¡Cupón agregado exitosamente!',
  couponEdited: '¡Cupón cambiado correctamente!',
  validatePercentage: 'Porcentaje por encima del 100%',
  sections: 'Secciones',
  sectionCreate: 'Crear Sección',
  sectionEdit: 'Editar sección',
  searchSection: 'Buscar sección',
  searchPaymentPlan: 'Buscar plan de pago',
  searchProduct: 'Buscar producto',
  havePaymentPlan: '¿La sección tiene un plan de pago?',
  haveProduct: '¿La sección tiene un producto?',
  dropFile: 'Suelta el archivo aquí ...',
  chooseImageSection: 'Elija una imagen para la sección, tamaño recomendado 350x326px',
  BadRequestCoupon: 'Cambios no realizados en el Cupón',
  alertPlanAtSection: 'Solo se permite un plan de pago por sección.',
  changePlanSectionMessageSucess: 'Plan cambiado con éxito',
  changePlanSectionMessageError: 'No se puede cambiar de plan',
  chooseImageSection: 'Elija una imagen para la sección, tamaño recomendado 350x326px',
  nomeCompletoDocumento: 'Nombre Completo (Documento)',


  paymentPlans: 'Planes de pago',
  paymentPlanCreate: 'Crear plan de pago',
  searchPaymentPlanName: 'Buscar plan de pago',
  paymentPlanName: 'Plan de pago de nombre',
  paymentPlanEdit: 'Editar plan de pago',
  paymentPlanEdited: '¡El plan de pago se modificó correctamente!',
  paymentPlanAdd: '¡Plan de pago agregado exitosamente!',


  configurationPlans: 'Planes de Configuración',
  configurationPlanCreate: 'Crear plan de configuración',
  searchConfigurationPlansName: 'Plan de configuración de búsqueda',
  configurationPlansType: 'Tipo de plan de pago',
  description: 'Descripción',
  qtyTimes: 'Tiempos de cantidad',
  entryPercentage: '% de entrada',
  discountPercentage: '% de descuento',
  interestPercentage: '% de interés',
  main: 'Principal',
  emphasis: 'Énfasis',
  installmentLimitValue: 'Valor límite de pago a plazos',

  selectCouponTypeApplication: 'Seleccione el tipo de aplicación de cupón',
  firstPurchase: 'Primeira Compra',
};
