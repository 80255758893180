import styled from 'styled-components';
import { metrics, fonts } from '@/template/styles';

export const Container = styled.div`
    display: flex;
    width: 100%;
`;

export const Title = styled.h1`
    color: #009cde;
`;

export const Img = styled.img`
    width: 100%;
    padding: ${metrics.space};
`;

export const Row = styled.div`
    display: flex;
    width: 100%;

    .img-start {
        display: flex;
        justify-content: center;
        width: 50%;
        max-height: 600px;
        margin: 50px;
    }

    .box-name {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: ${fonts.bigger};
        font-weight: 700;
        width: 50%;
    }

    .name {
        font-size: ${fonts.bigger};
        font-weight: 700;
        margin-bottom: 20px;
    }

    .description {
        font-size: ${fonts.big};
        font-weight: 300;
    }

    .btn-voltar {
        width: 250px;
    }

    @media (max-width: 1300px) {
        flex-direction: column;
        align-items: center;

        .box-name {
            align-items: center;
            text-align: center;
        }
    }
`;
