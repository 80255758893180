import React from 'react';

import { Container, Header, Body, Footer } from './styles';

function Voucher({ item }) {
    return (
        <Container>
            <Header>
                <div className="entalhe-card"></div>
                <img src={item.Produto.Imagem} />
            </Header>
            <Body>
                <h3>{item.Produto.Nome}</h3>
            </Body>
            <Footer>
                <div className="label">Voucher</div>
                <div className="voucher">{item.Hash}</div>
            </Footer>
        </Container>
    );
}

export default Voucher;
