import {
    CONFIGURATIONPLAN_EXHIBITION_SET,
    CONFIGURATIONPLAN_EXHIBITION_LOADING,
    CONFIGURATIONPLAN_EXHIBITION_LOADED
} from '../actionTypes';

const initialState = {
    configurationPlanExhibition: [],
    configurationPlanExhibitionIsLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CONFIGURATIONPLAN_EXHIBITION_SET:
            return {
                ...state,
                configurationPlanExhibition: action.payload,
            };
        case CONFIGURATIONPLAN_EXHIBITION_LOADING:
            return {
                ...state,
                configurationPlanExhibitionIsLoading: true,
            };
        case CONFIGURATIONPLAN_EXHIBITION_LOADED:
            return {
                ...state,
                configurationPlanExhibitionIsLoading: false,
            };
        default:
            return state;
    }
};

export default reducer;
