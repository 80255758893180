import styled from 'styled-components';
import { colors } from '@/template/styles';

export const Container = styled.div`
  display: inline;
  padding: 0px 10px;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 3px;
  background: ${(props) => {
    if (props.type === 'success') {
      return colors.success;
    } else if (props.type === 'danger') {
      return colors.danger;
    } else if (props.type === 'warning') {
      return colors.warning;
    } else if (props.type === 'info') {
      return colors.info;
    }
  }};
`;
