import React from 'react';
import { translate } from '@/i18n/src/locales';
import Card from '@/components/Card';
import { format } from 'date-fns';
import { pt } from 'date-fns/esm/locale';
// import { BoxFooter } from './styles';

function FooterInvoice({ item }) {
    return (
        <>
            <Card>
                <div className="title">
                    {translate('total')}{' '}
                    {format(new Date(item.anoCompetencia, item.mesCompetencia, 1), 'MMM/yyyy', {
                        locale: pt,
                    })}
                </div>
                <div className="content">
                    {item.valorFatura.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </div>
            </Card>
            {item.ValorFaturaRetroativo > 0 ? (
                <Card>
                    <div className="title">{translate('retroactive')}</div>
                    <div className="content">
                        {item.valorFaturaRetroativo.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        })}
                    </div>
                </Card>
            ) : null}
            <Card>
                <div className="title">{translate('discount')}</div>
                <div className="content">
                    {item.valorDescontoFatura.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </div>
            </Card>
            <Card>
                <div className="title">{translate('total')}</div>
                <div className="content">
                    {item.valorTotalFatura.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </div>
            </Card>
        </>
    );
}

export default FooterInvoice;
