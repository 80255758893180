import { THEME_TOOGLE_SIDEBAR, THEME_SHOW_ALERT, THEME_CLOSE_ALERT } from '../actionTypes';

const initialState = {
    sideBarOpen: true,
    alert: { type: null, message: '', time: null },
    language:"pt-BR"
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case THEME_TOOGLE_SIDEBAR:
            return {
                ...state,
                sideBarOpen: action.payload,
            };
        case THEME_SHOW_ALERT:
            return {
                ...state,
                alert: action.payload,
            };
        case THEME_CLOSE_ALERT:
            return {
                ...state,
                alert: { type: null, message: '', time: null },
            };
        default:
            return state;
    }
};

export default reducer;
