import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { translate } from '@/i18n/src/locales';
import TitlePage from '@/components/TitlePage';
import Card from '@/components/Card';
import { fetchAllSection } from '@/store/actions/section';

import ContentLoader from 'react-content-loader';

import DataTable from '@/components/DataTable';
import Badge from '@/components/Badge';
import IcEdit from '@/assets/images/ic-edit.svg';
import * as ROUTES from '@/routes/routes';

function Sections() {
    const dispatch = useDispatch();

    const sections = useSelector((state) => state.section.sections);
    const sectionIsLoading = useSelector((state) => state.section.sectionIsLoading);

    useEffect(() => {
        dispatch(fetchAllSection());
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>Administração Franqueadora - {translate('sections')}</title>
            </Helmet>
            <TitlePage title={translate('sections')} link={ROUTES.SECTION_CREATE} txtButton={translate('sectionCreate')} />
            <Card>
                {sectionIsLoading ? (
                    <ContentLoader width="100%" speed={1} height="400px" backgroundColor="#c6c6c6" foregroundColor="#F0F0F5">
                        <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="60px" />
                        <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="40px" />
                        <rect x="0" y="150" rx="5px" ry="5px" width="100%" height="40px" />
                        <rect x="0" y="200" rx="5px" ry="5px" width="100%" height="40px" />
                        <rect x="0" y="250" rx="5px" ry="5px" width="100%" height="40px" />
                        <rect x="0" y="300" rx="5px" ry="5px" width="100%" height="40px" />
                        <rect x="0" y="350" rx="5px" ry="5px" width="100%" height="40px" />
                    </ContentLoader>
                ) : (
                    <DataTable
                        data={sections}
                        searchPlaceHolder={translate('searchSection')}
                        columns={[
                            {
                                compact: true,
                                center: true,
                                width: '60px',
                                cell: (row) => <img src={row.Imagem} alt={row.NomeSecao} />,
                            },
                            {
                                name: 'Nome',
                                selector: 'NomeSecao',
                                sortable: true,
                            },
                            {
                                name: 'Ativo',
                                selector: 'Ativo',
                                compact: true,
                                width: '120px',
                                cell: (row) => {
                                    return row.Ativo ? <Badge type="success">{translate('active')}</Badge> : <Badge type="danger">{translate('inactive')}</Badge>;
                                },
                            },
                            {
                                width: '60px',
                                cell: (row) => (
                                    <Link to={`${ROUTES.SECTION_CREATE}/${row.IdSecao}`}>
                                        <img className="icon-edit" src={IcEdit} alt={row.NomeSecao} />
                                    </Link>
                                ),
                            },
                        ]}
                        columnFilter="NomeSecao"
                    />
                )}
            </Card>
        </>
    );
}

export default Sections;
