import axios from 'axios';
import {
  getToken,
  setToken,
  getRefreshToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken,
} from '@/services/localStorageService';
import * as ROUTES from '@/routes/routes';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // 'Ocp-Apim-Subscription-Key': productKey,
    'x-api-version': process.env.API_VERSION,
    'api-version': process.env.API_VERSION,
  },
});

const apiRefresh = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // 'Ocp-Apim-Subscription-Key': productKey,
    'x-api-version': process.env.API_VERSION,
    'api-version': process.env.API_VERSION,
  },
});

const apiLoja = axios.create({
  baseURL: process.env.REACT_APP_APILOJA_URL,
  headers: {
    token: '90b0b515759f369e41fa67f2e92a43e8',
    tokenVoucher: 'dd31c222f80516131faa874cd6372e88',
  },
});

//Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== '/Passaporte/login') {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();
      const token = getToken();

      return apiRefresh
        .post(`/Passaporte/refreshToken`, {
          JwtToken: token,
          RefreshToken: refreshToken,
        })
        .then((res) => {
          if (res.status === 201) {
            let token = res.data.jwtToken;
            let refreshToken = res.data.refreshToken;

            originalRequest.headers['Authorization'] = 'Bearer ' + token;

            setToken(token);
            setRefreshToken(refreshToken);

            return axios(originalRequest);
          } else {
            removeToken();
            removeRefreshToken();
            return (window.location.href = ROUTES.LOGIN);
          }
        });
    }
    return Promise.reject(error);
  }
);

export { api, apiLoja };
