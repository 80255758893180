import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
`;

export const Label = styled.div`
    position: absolute;
    left: 10px;
    background: #fff;
    top: -9px;
    padding: 0px 10px;
    z-index: 5;
`;
