import styled from 'styled-components';
import { colors, metrics } from '@/template/styles/';

export const Container = styled.div`
    width: 100%;
    background: #fff;
    padding: 2rem;
    border-radius: 5px;
    margin-bottom: ${metrics.space};
`;
