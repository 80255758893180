import React, { useState, useEffect } from 'react';

import TitlePage from '@/components/TitlePage';
import Card from '@/components/Card';
import Voucher from '@/components/Voucher';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import { format, isAfter } from 'date-fns';
import { pt } from 'date-fns/esm/locale';
import formatISO from 'date-fns/formatISO';

import {
    RiArrowDropDownLine,
    RiCheckboxBlankCircleLine,
    RiCheckboxCircleLine,
} from 'react-icons/ri';
import {
    BoxForm,
    HeaderSales,
    ArrowCollapse,
    ContainerLoader,
    CollapseItem,
    StudentContainer,
    VoucherContainer,
    ResponsibleContainer,
    Products,
    FooterSale,
    ItemSale,
    CardFilter,
    Installments,
} from './styles';
import { translate } from '@/i18n/src/locales';
import { Helmet } from 'react-helmet';
import SelectOne from '@/components/Forms/SelectOne';
import ContentLoader from 'react-content-loader';
import { useSelector, useDispatch } from 'react-redux';

import { fetchPoles } from '@/store/actions/pole';
import { fetchSales, setFilterSales, clearSales } from '@/store/actions/sales';
import { apiLoja } from '@/services/api';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { showAlert } from '@/store/actions/theme';

function Sales() {
    registerLocale('pt', pt);

    const poles = useSelector((state) => state.pole.poles);
    const polesIsLoading = useSelector((state) => state.pole.polesIsLoading);

    const sales = useSelector((state) => state.sales.sales);
    const salesIsLoading = useSelector((state) => state.sales.salesIsLoading);

    const filters = useSelector((state) => state.sales.filters);
    const filterCurrent = useSelector((state) => state.sales.filterCurrent);

    const dispatch = useDispatch();

    const [inputPoles, setInputPoles] = useState(null);
    const [pole, setPole] = useState(null);

    const [initialDate, setInitialDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        dispatch(clearSales());
        dispatch(fetchPoles());

        let selected = filters.filter((item) => {
            if (item.active === true) {
                return item;
            }
        })[0];
    }, []);

    useEffect(() => {
        if (poles.length > 0) {
            setInputPoles(
                poles.map((item) => ({
                    label: `${item.codigoExternoPolo} - ${item.nomePolo}`,
                    value: String(item.idPolo),
                })),
            );
        }
    }, [poles]);

    useEffect(() => {
        if (filterCurrent.id === 1) {
            dispatch(
                fetchSales(pole, formatISO(sub(new Date(), { days: 7 })), formatISO(new Date())),
            );
        } else if (filterCurrent.id === 2) {
            dispatch(
                fetchSales(pole, formatISO(sub(new Date(), { days: 30 })), formatISO(new Date())),
            );
        }
    }, [filterCurrent]);

    function handleChangePolo(item) {
        setPole(item.value);
        dispatch(clearSales());

        if (filterCurrent.id === 1) {
            dispatch(
                fetchSales(
                    item.value,
                    formatISO(sub(new Date(), { days: 7 })),
                    formatISO(new Date()),
                ),
            );
        } else if (filterCurrent.id === 2) {
            fetchSales(item.value, formatISO(sub(new Date(), { days: 30 })), formatISO(new Date()));
        }
    }
    //#region handler filter
    function handleFilter(id) {
        let _filters = filters.map((item) => {
            if (item.id === id) {
                return { ...item, active: true };
            } else {
                return { ...item, active: false };
            }
        });

        dispatch(setFilterSales(_filters));
    }
    //#endregion
    function handleFilterDate() {
        const past = isAfter(initialDate, endDate);

        if (past) {
            dispatch(
                showAlert({
                    type: 'danger',
                    message: 'Data inicial maior que data final',
                    time: 3000,
                }),
            );
        } else {
            dispatch(fetchSales(pole, formatISO(initialDate), formatISO(endDate)));
        }
    }

    return (
        <>
            <Helmet>
                <title>Administração Franqueadora - {translate('vouchers')}</title>
            </Helmet>
            <TitlePage title={translate('sales')} />
            <Card>
                <BoxForm>
                    {polesIsLoading ? (
                        <ContentLoader width={'100%'} speed={1} height={'40px'}>
                            <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="40px" />
                        </ContentLoader>
                    ) : (
                        <SelectOne
                            options={inputPoles}
                            change={(item) => handleChangePolo(item)}
                            searchable={true}
                            placeholder={translate('selectAUnit')}
                        />
                    )}
                </BoxForm>
            </Card>
            {pole !== null ? (
                <CardFilter>
                    <div className="boxFilter">
                        {filters.map((item) => {
                            if (item.active === true) {
                                return (
                                    <div className="item active" key={item.id}>
                                        <RiCheckboxCircleLine size={20} color={'#17c671'} />
                                        <div className="description">{item.description}</div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        className="item"
                                        key={item.id}
                                        onClick={() => handleFilter(item.id)}>
                                        <RiCheckboxBlankCircleLine size={20} />
                                        <div className="description">{item.description}</div>
                                    </div>
                                );
                            }
                        })}
                    </div>

                    <div className="boxDate">
                        {filterCurrent && filterCurrent.id === 3 ? (
                            <>
                                <DatePicker
                                    locale="pt"
                                    dateFormat="dd/MM/yyyy"
                                    selected={initialDate}
                                    maxDate={new Date()}
                                    placeholderText="Data Inicial"
                                    onChange={(e) => setInitialDate(e)}
                                />
                                <div className="de"> de </div>
                                <DatePicker
                                    locale="pt"
                                    dateFormat="dd/MM/yyyy"
                                    selected={endDate}
                                    placeholderText="Data Final"
                                    maxDate={new Date()}
                                    onChange={(e) => setEndDate(e)}
                                />
                                <button
                                    className="btn btn-primary btn-filter"
                                    onClick={() => handleFilterDate()}>
                                    Filtrar
                                </button>
                            </>
                        ) : null}
                    </div>
                </CardFilter>
            ) : null}

            {salesIsLoading ? (
                <ContainerLoader>
                    <ContentLoader
                        width={'100%'}
                        speed={1}
                        height={'280px'}
                        backgroundColor={'#c6c6c6'}
                        foregroundColor={'#F0F0F5'}>
                        <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="80px" />
                        <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="80px" />
                        <rect x="0" y="200" rx="5px" ry="5px" width="100%" height="80px" />
                    </ContentLoader>
                </ContainerLoader>
            ) : (
                <>
                    {sales.length > 0
                        ? sales.map((item) => <ItemSales key={item.IdVenda} item={item} />)
                        : null}
                </>
            )}
        </>
    );
}

const ItemSales = ({ item }) => {
    const [open, setOpen] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [detailsSale, setDetailsSale] = useState(null);
    const [txtTotalPaid, setTxtTotalPaid] = useState(null);

    useEffect(() => {
        setDetailsSale(null);
        if (open) {
            setIsLoading(true);
            apiLoja
                .get(`/Vendas/GetVendaById/${item.IdVenda}`)
                .then(function (response) {
                    setDetailsSale(response.data);
                    setIsLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }, [open]);

    useEffect(() => {
        if (detailsSale != null) {
            let textoTotalPago = '';

            if (detailsSale.Entrada > 0) {
                textoTotalPago += `Entrada ${(
                    detailsSale.Entrada + detailsSale.Frete
                ).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} + `;
            }

            textoTotalPago += `${
                detailsSale.QtdeParcela
            }x ${detailsSale.ParcelaValor.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            })}`;

            setTxtTotalPaid(textoTotalPago);
        }
    }, [detailsSale]);

    function handleOpen() {
        setOpen(!open);
    }

    return (
        <ItemSale>
            <HeaderSales onClick={() => handleOpen()}>
                <div className="item">
                    <div className="titulo">{translate('saleDate')}</div>
                    <div>
                        {format(new Date(item.Data), 'dd/MM/yyyy', {
                            locale: pt,
                        })}
                    </div>
                </div>
                <div className="item">
                    <div className="titulo">{translate('typeOfSale')}</div>
                    <div>{item.TipoVenda}</div>
                </div>
                <div className="item">
                    <div className="titulo">{translate('formOfPayment')}</div>
                    <div>{item.FormaPagamento === 'boleto' ? `Boleto` : `Cartão de Crédito`}</div>
                </div>
                <div className="item">
                    <div className="titulo">{translate('dueDate')}</div>
                    <div>
                        {format(new Date(item.DataVencimento), 'dd/MM/yyyy', {
                            locale: pt,
                        })}
                    </div>
                </div>
                <div className="item">
                    <div className="titulo">{translate('paymentStatus')}</div>
                    <div>{item.StatusPagamento}</div>
                </div>
                <div className="item-action">
                    <ArrowCollapse open={open}>
                        <RiArrowDropDownLine size={30} />
                    </ArrowCollapse>
                </div>
            </HeaderSales>
            <CollapseItem isOpen={open} transition={`height 0.4s cubic-bezier(.4, 0, .2, 1)`}>
                {isLoading ? (
                    <ContentLoader width={'100%'} speed={1} height={'280px'}>
                        <rect x="0" y="20" rx="5px" ry="5px" width="100%" height="40px" />
                        <rect x="0" y="80" rx="5px" ry="5px" width="100%" height="40px" />
                        <rect x="0" y="140" rx="5px" ry="5px" width="100%" height="40px" />
                        <rect x="0" y="200" rx="5px" ry="5px" width="100%" height="80px" />
                    </ContentLoader>
                ) : null}
                {item.TipoVenda.toLowerCase() === 'matricula' ? (
                    detailsSale ? (
                        <UserData item={detailsSale} />
                    ) : null
                ) : null}
                {item.TipoVenda.toLowerCase() === 'voucher' ? (
                    detailsSale ? (
                        <VoucherContainer>
                            <div className="header">VOUCHERS</div>
                            <div className="body">
                                {detailsSale.Vouchers.map((item, index) => {
                                    return <Voucher key={index} item={item} />;
                                })}
                            </div>
                        </VoucherContainer>
                    ) : null
                ) : null}
                {detailsSale ? (
                    <>
                        <Installments>
                            <div className="header">{translate('installments')}</div>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{translate('formOfPayment')}</th>
                                            <th>{translate('installments')}</th>
                                            <th>{translate('installmentValue')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detailsSale.PagamentoParcelaViewModel.map((item) => (
                                            <tr key={item.IdPagamentoParcela}>
                                                <td>{item.FormaPagamento}</td>
                                                <td>{item.NumeroParcela}</td>
                                                <td>
                                                    {item.Valor.toLocaleString('pt-BR', {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    })}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Installments>
                        <Products>
                            <div className="header">{translate('products')}</div>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{translate('product')}</th>
                                            <th>{translate('quantity')}</th>
                                            <th>{translate('value')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detailsSale.VendaItem.map((item) => (
                                            <tr key={item.IdVendaItem}>
                                                <td>
                                                    <div className="box-prod">
                                                        <img
                                                            className="img-prod"
                                                            src={item.ImagemProduto}
                                                        />
                                                        {item.NomeProduto}
                                                    </div>
                                                </td>
                                                <td>{item.Quantidade}</td>
                                                <td>
                                                    {item.Preco.toLocaleString('pt-BR', {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    })}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Products>
                        <FooterSale>
                            <div className="item">
                                <div className="title-item">% {translate('discount')}(-)</div>
                                <div>{detailsSale.PorcentagemDesconto}</div>
                            </div>
                            <div className="item">
                                <div className="title-item">% {translate('interest')}(+)</div>
                                <div>{detailsSale.PorcentagemJuros}</div>
                            </div>
                            <div className="item">
                                <div className="title-item">{translate('dispatch')}(+)</div>
                                <div>
                                    {detailsSale.Frete.toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </div>
                            </div>
                            <div className="item total-pago">
                                <div className="title-item">{translate('totalPaid')}</div>
                                <div>{txtTotalPaid}</div>
                            </div>
                        </FooterSale>
                    </>
                ) : null}
            </CollapseItem>
        </ItemSale>
    );
};

const UserData = ({ item }) => {
    const { VendaUsuarioDados } = item;

    if (VendaUsuarioDados.ResponsavelDataNascimento === '0001-01-01T00:00:00') {
        return (
            <>
                <StudentContainer>
                    <div className="header">DADOS ALUNO</div>
                    <div className="body">
                        <div className="item">
                            <div className="title-item">NOME</div>
                            <div className="content-item">{VendaUsuarioDados.AlunoNome}</div>
                        </div>
                        <div className="item">
                            <div className="title-item">CPF</div>
                            <div className="content-item">{VendaUsuarioDados.AlunoCPF}</div>
                        </div>
                        <div className="item">
                            <div className="title-item">EMAIL</div>
                            <div className="content-item">{VendaUsuarioDados.AlunoEmail}</div>
                        </div>
                        <div className="item">
                            <div className="title-item">Telefone</div>
                            <div className="content-item">{VendaUsuarioDados.AlunoTelefone}</div>
                        </div>
                        <div className="item">
                            <div className="title-item">Celular</div>
                            <div className="content-item">{VendaUsuarioDados.AlunoCelular}</div>
                        </div>
                    </div>
                </StudentContainer>
            </>
        );
    } else {
        return (
            <>
                <StudentContainer>
                    <div className="header">DADOS ALUNO</div>
                    <div className="body">
                        <div className="item">
                            <div className="title-item">NOME</div>
                            <div className="content-item">{VendaUsuarioDados.AlunoNome}</div>
                        </div>
                        <div className="item">
                            <div className="title-item">CPF</div>
                            <div className="content-item">{VendaUsuarioDados.AlunoCPF}</div>
                        </div>
                        <div className="item">
                            <div className="title-item">EMAIL</div>
                            <div className="content-item">{VendaUsuarioDados.AlunoEmail}</div>
                        </div>
                    </div>
                </StudentContainer>

                <ResponsibleContainer>
                    <div className="header">DADOS RESPONSÁVEL</div>
                    <div className="body">
                        <div className="item">
                            <div className="title-item">NOME</div>
                            <div className="content-item">{VendaUsuarioDados.ResponsavelNome}</div>
                        </div>
                        <div className="item">
                            <div className="title-item">CPF</div>
                            <div className="content-item">{VendaUsuarioDados.ResponsavelCPF}</div>
                        </div>
                        <div className="item">
                            <div className="title-item">RG</div>
                            <div className="content-item">{VendaUsuarioDados.ResponsavelRG}</div>
                        </div>
                        <div className="item">
                            <div className="title-item">EMAIL</div>
                            <div className="content-item">{VendaUsuarioDados.ResponsavelEmail}</div>
                        </div>
                        <div className="item">
                            <div className="title-item">TELEFONE</div>
                            <div className="content-item">
                                {VendaUsuarioDados.ResponsavelTelefone}
                            </div>
                        </div>
                        <div className="item">
                            <div className="title-item">CELULAR</div>
                            <div className="content-item">
                                {VendaUsuarioDados.ResponsavelCelular}
                            </div>
                        </div>
                    </div>
                </ResponsibleContainer>
            </>
        );
    }
};

export default Sales;
