import styled from 'styled-components';
import { metrics } from '@/template/styles';
import Collapse from '@kunukn/react-collapse';

export const ContainerLoader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .container-succeso {
    display: flex;
    flex-direction: column;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const HeaderAdress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${metrics.space / 2} 0;
  cursor: pointer;
  font-weight: 700;

  .titulo {
    display: flex;
    align-items: center;
  }

  span {
    margin-left: 10px;
  }
`;

export const ArrowCollapse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props) => {
    if (props.open) {
      return `rotate(180deg)`;
    }
    return `rotate(0deg)`;
  }};

  transition: all 0.2s;
`;

export const CollapseItem = styled(Collapse)`
  .form {
    margin-top: 30px;
  }
`;
