import styled from 'styled-components';
import { colors, metrics } from '@/template/styles';

export const Container = styled.div`
  display: flex;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  margin: ${metrics.space} 0;
  margin-right: ${metrics.space};
  cursor: pointer;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border: 0.2rem solid #e0e0e0;
  border-radius: 50%;
`;

export const IconSelected = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-image: ${colors.gradientDefault};
`;

export const Label = styled.div`
  margin-left: 1rem;
`;
