/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'react-flexbox-grid';
import { useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { RiArrowDropDownLine, RiCheckboxBlankLine, RiCheckboxFill, RiDeleteBin2Line } from 'react-icons/ri';

import DataTable from '@/components/DataTable';
import Badge from '@/components/Badge';
import SelectOne from '@/components/Forms/SelectOne';
import ContentLoader from 'react-content-loader';
import { translate } from '@/i18n/src/locales';
import TitlePage from '@/components/TitlePage';
import Switcher from '@/components/Forms/Switcher';
import InputMoney from '@/components/Forms/InputMoney';
import InputPercentage from '@/components/Forms/InputPercentage';
import Input from '@/components/Forms/Input';
import FormatDate from '@/components/Forms/FormatDate';
import { showAlert } from '@/store/actions/theme';

import Card from '@/components/Card';
import Check from '@/components/Forms/Check';
import { colors } from '@/template/styles';
import { toBase64 } from '@/utils/ConvertBase64';
import {
    addConfigurationPlan,
    editConfigurationPlan,
    fetchConfigurationPlanById
}
    from '@/store/actions/configurationPlan';

import {
    fetchAllConfigurationPlanTypes
}
    from '@/store/actions/configurationPlanTypes';

import el from 'date-fns/esm/locale/el/index.js';
import { Container, Label } from './styles';
import * as S from './styles';
import { fetchAllConfigurationPlanExhibition } from '@/store/actions/configurationPlanExhibition';

function ConfigurationPlanCreate() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const configurationPlanEdit = useSelector((state) => state.configurationPlan.configurationPlanEdit);
    const configurationPlanIsLoading = useSelector((state) => state.configurationPlan.configurationPlanIsLoading);
    const [isEdit, setIsEdit] = useState(false);
    const [configurationPlanName, setConfigurationPlanName] = useState('');
    const [configurationPlanTyTimes, setConfigurationPlanTyTimes] = useState('');
    const [configurationPlanEntryPercentage, setConfigurationPlanEntryPercentage] = useState('');
    const [configurationPlanDiscountPercentage, setConfigurationPlanDiscountPercentage] = useState('');
    const [configurationPlanInterestPercentage, setConfigurationPlanInterestPercentage] = useState('');
    const [configurationPlanInstallmentLimitValue, setConfigurationPlanInstallmentLimitValue] = useState('');
    const [configurationPlanMainCharterable, setConfigurationPlanMainCharterable] = useState('');
    const [configurationPlanEmphasisCharterable, setConfigurationPlanEmphasisCharterable] = useState('');

    const [configurationPlanTyTimesStatus, setConfigurationPlanTyTimesStatus] = useState({
        status: '',
        feedback: '',
    });
    const [configurationPlanEntryPercentageStatus, setConfigurationPlanEntryPercentageStatus] = useState({
        status: '',
        feedback: '',
    });
    const [configurationPlanDiscountPercentageStatus, setConfigurationPlanDiscountPercentageStatus] = useState({
        status: '',
        feedback: '',
    });
    const [configurationPlanInterestPercentageStatus, setConfigurationPlanInterestPercentageStatus] = useState({
        status: '',
        feedback: '',
    });
    const [configurationPlanInstallmentLimitValueStatus, setConfigurationPlanInstallmentLimitValueStatus] = useState({
        status: '',
        feedback: '',
    });

    const [inputConfiguratioPlanTypes, setInputConfiguratioPlanTypes] = useState(null);
    const [inputConfiguratioPlanExhibition, setInputConfiguratioPlanExhibition] = useState(null);
    const typeConfigurationPlan = useSelector((state) => state.configurationPlanTypes.configurationPlanTypes);
    const [selectedConfigurationPlanTypes, setSelectedConfigurationPlanTypes] = useState(0);
    const [selectedConfigurationPlanExhibition, setSelectedConfigurationPlanExhibition] = useState(0);
    const exhibitionConfigurationPlan = useSelector((state) => state.configurationPlanExhibition.configurationPlanExhibition);

    useEffect(() => {
        if (id) {
            setIsEdit(true);
            dispatch(fetchConfigurationPlanById(id));
        } else {
            setIsEdit(false);
        }

        dispatch(fetchAllConfigurationPlanTypes());
        dispatch(fetchAllConfigurationPlanExhibition());
    }, [id]);

    useEffect(() => {
        if (isEdit && configurationPlanEdit) {
            setConfigurationPlanName(configurationPlanEdit.Descricao);
            setSelectedConfigurationPlanTypes(configurationPlanEdit.IdPlanoPagamentoTipo);
            setConfigurationPlanTyTimes(configurationPlanEdit.QtdVezes);
            setConfigurationPlanEntryPercentage(configurationPlanEdit.EntradaPorcentagem);
            setConfigurationPlanDiscountPercentage(configurationPlanEdit.DescontoPorcentagem);
            setConfigurationPlanInterestPercentage(configurationPlanEdit.JurosPorcentagem);
            setConfigurationPlanInstallmentLimitValue(configurationPlanEdit.ValorLimiteParcela);
            setConfigurationPlanMainCharterable(configurationPlanEdit.Principal);
            setConfigurationPlanEmphasisCharterable(configurationPlanEdit.Destaque);
            setSelectedConfigurationPlanExhibition(configurationPlanEdit.IdPlanoConfiguracaoExibicao);
        }
    }, [isEdit, configurationPlanEdit]);

    useEffect(() => {
        if (typeConfigurationPlan.length > 0) {
            setInputConfiguratioPlanTypes(
                typeConfigurationPlan.map((item) => ({
                    label: item.Tipo,
                    value: String(item.IdPlanoPagamentoTipo),
                }))
            );
        }

    }, [typeConfigurationPlan]);

    useEffect(() => {
        if (exhibitionConfigurationPlan.length > 0) {
            setInputConfiguratioPlanExhibition(
                exhibitionConfigurationPlan.map((item) => ({
                    label: item.Descricao,
                    value: String(item.IdPlanoConfiguracaoExibicao),
                }))
            );
        }

    }, [exhibitionConfigurationPlan]);

    const changeConfigurationPlanTypes = useCallback((item) => {
        setSelectedConfigurationPlanTypes(item.value);
    }, []);

    const changeConfigurationPlanExhibition = useCallback((item) => {
        setSelectedConfigurationPlanExhibition(item.value);
    }, []);

    const handleAddConfigurationPlan = useCallback(() => {
        let formErro = false;

        if (!configurationPlanTyTimes) {
            setConfigurationPlanTyTimesStatus({ status: 'danger', feedback: 'Campo obrigatório' });
            formErro = true;
        }

        if (!configurationPlanEntryPercentage) {
            setConfigurationPlanEntryPercentageStatus({ status: 'danger', feedback: 'Campo obrigatório' });
            formErro = true;
        }

        if (!configurationPlanDiscountPercentage) {
            setConfigurationPlanDiscountPercentageStatus({ status: 'danger', feedback: 'Campo obrigatório' });
            formErro = true;
        }

        if (!configurationPlanInterestPercentage) {
            setConfigurationPlanInterestPercentageStatus({ status: 'danger', feedback: 'Campo obrigatório' });
            formErro = true;
        }

        if (!configurationPlanInstallmentLimitValue) {
            setConfigurationPlanInstallmentLimitValueStatus({ status: 'danger', feedback: 'Campo obrigatório' });
            formErro = true;
        }

        if ((!configurationPlanEmphasisCharterable && !configurationPlanMainCharterable) || selectedConfigurationPlanTypes === 0) {
            dispatch(
                showAlert({
                    type: 'danger',
                    message: 'PREEMCHA TODOS OS CAMPOS',
                    time: 3000,
                })
            );
            formErro = true;
        }

        if (!formErro) {
            dispatch(
                addConfigurationPlan({
                    Token: '90b0b515759f369e41fa67f2e92a43e8',
                    IdPlanoPagamentoTipo: selectedConfigurationPlanTypes,
                    Descricao: configurationPlanName,
                    QtdVezes: configurationPlanTyTimes,
                    EntradaPorcentagem: configurationPlanEntryPercentage,
                    DescontoPorcentagem: configurationPlanDiscountPercentage,
                    JurosPorcentagem: configurationPlanInterestPercentage,
                    Principal: configurationPlanMainCharterable,
                    Destaque: configurationPlanEmphasisCharterable,
                    ValorLimiteParcela: configurationPlanInstallmentLimitValue,
                    IdPlanoConfiguracaoExibicao: selectedConfigurationPlanExhibition
                })
            );
        }
    }, [dispatch,
        selectedConfigurationPlanTypes,
        configurationPlanName,
        configurationPlanTyTimes,
        configurationPlanEntryPercentage,
        configurationPlanDiscountPercentage,
        configurationPlanInterestPercentage,
        configurationPlanMainCharterable,
        configurationPlanEmphasisCharterable,
        configurationPlanInstallmentLimitValue,
        selectedConfigurationPlanExhibition
    ]);

    const handleEditConfigurationPlan = useCallback(() => {
        let formErro = false;

        if (!configurationPlanTyTimes) {
            setConfigurationPlanTyTimesStatus({ status: 'danger', feedback: 'Campo obrigatório' });
            formErro = true;
        }

        if (!configurationPlanEntryPercentage) {
            setConfigurationPlanEntryPercentageStatus({ status: 'danger', feedback: 'Campo obrigatório' });
            formErro = true;
        }

        if (!configurationPlanDiscountPercentage) {
            setConfigurationPlanDiscountPercentageStatus({ status: 'danger', feedback: 'Campo obrigatório' });
            formErro = true;
        }

        if (!configurationPlanInterestPercentage) {
            setConfigurationPlanInterestPercentageStatus({ status: 'danger', feedback: 'Campo obrigatório' });
            formErro = true;
        }

        if (!configurationPlanInstallmentLimitValue) {
            setConfigurationPlanInstallmentLimitValueStatus({ status: 'danger', feedback: 'Campo obrigatório' });
            formErro = true;
        }

        if ((!configurationPlanEmphasisCharterable && !configurationPlanMainCharterable) || selectedConfigurationPlanTypes === 0) {
            dispatch(
                showAlert({
                    type: 'danger',
                    message: 'PREEMCHA TODOS OS CAMPOS',
                    time: 3000,
                })
            );
            formErro = true;
        }

        if (!formErro) {
            dispatch(
                editConfigurationPlan({
                    IdPlanoConfiguracao: id,
                    IdPlanoPagamentoTipo: selectedConfigurationPlanTypes,
                    Descricao: configurationPlanName,
                    QtdVezes: configurationPlanTyTimes,
                    EntradaPorcentagem: configurationPlanEntryPercentage,
                    DescontoPorcentagem: configurationPlanDiscountPercentage,
                    JurosPorcentagem: configurationPlanInterestPercentage,
                    Principal: configurationPlanMainCharterable,
                    Destaque: configurationPlanEmphasisCharterable,
                    ValorLimiteParcela: configurationPlanInstallmentLimitValue,
                    IdPlanoConfiguracaoExibicao: selectedConfigurationPlanExhibition
                })
            );
        }
    }, [id, dispatch,
        selectedConfigurationPlanTypes,
        configurationPlanName,
        configurationPlanTyTimes,
        configurationPlanEntryPercentage,
        configurationPlanDiscountPercentage,
        configurationPlanInterestPercentage,
        configurationPlanMainCharterable,
        configurationPlanEmphasisCharterable,
        configurationPlanInstallmentLimitValue,
        selectedConfigurationPlanExhibition
    ]);

    return (
        <>
            <Helmet>
                <title>Administração Franqueadora - {isEdit ? translate('configurationPlanCreate') : translate('configurationPlanCreate')}</title>
            </Helmet>
            <TitlePage title={isEdit ? translate('configurationPlanCreate') : translate('configurationPlanCreate')} />
            <Card>
                {configurationPlanIsLoading ? (
                    <ContentLoader width="100%" speed={1} height="380px" backgroundColor="#c6c6c6" foregroundColor="#F0F0F5">
                        <rect x="0" y="0" rx="5px" ry="5px" width="60%" height="40px" />
                        <rect x="0" y="60" rx="5px" ry="5px" width="20%" height="40px" />
                        <rect x="0" y="120" rx="5px" ry="5px" width="20%" height="40px" />
                        <rect x="220" y="120" rx="5px" ry="5px" width="20%" height="40px" />
                        <rect x="440" y="120" rx="5px" ry="5px" width="20%" height="40px" />
                        <rect x="0" y="230" rx="5px" ry="5px" width="100%" height="50px" />
                    </ContentLoader>
                ) : (
                    <>
                        <Row className="mt">
                            <Col xs={12} md={8}>
                                <Row>
                                    <Col xs={8}>
                                        <Input
                                            name="name-adm"
                                            type="text"
                                            label='Descrição'
                                            value={configurationPlanName}
                                            change={(value) => setConfigurationPlanName(value)}
                                        //status={configurationPlanNameStatus}
                                        />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        {inputConfiguratioPlanTypes != null && (
                                            <SelectOne
                                                options={inputConfiguratioPlanTypes}
                                                value={inputConfiguratioPlanTypes.find((item) => item.value === String(selectedConfigurationPlanTypes))}
                                                change={(item) => changeConfigurationPlanTypes(item)}
                                                searchable
                                                placeholder="Selecione"
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <Row className="mt">
                                    <Col xs={6} md={4}>
                                        <Input
                                            name="name-adm"
                                            type="number"
                                            label='QtsVezes'
                                            value={configurationPlanTyTimes}
                                            change={(value) => setConfigurationPlanTyTimes(value)}
                                            status={configurationPlanTyTimesStatus}
                                        />
                                    </Col>
                                    <Col xs={6} md={4}>
                                        <InputPercentage
                                            value={configurationPlanEntryPercentage}
                                            label='Entrada Porcentagem'
                                            status={configurationPlanEntryPercentageStatus}
                                            change={(value) => setConfigurationPlanEntryPercentage(value)}
                                            onChange={(value) => this.setState({ number: value })}
                                        />
                                    </Col>

                                    <Col xs={6} md={4}>
                                        <InputPercentage
                                            value={configurationPlanDiscountPercentage}
                                            label='Desconto Porcentagem'
                                            status={configurationPlanDiscountPercentageStatus}
                                            change={(value) => setConfigurationPlanDiscountPercentage(value)}
                                            onChange={(value) => this.setState({ number: value })}
                                        />
                                    </Col>

                                    <Col xs={6} md={4}>
                                        <InputPercentage
                                            value={configurationPlanInterestPercentage}
                                            label='Juros Porcentagem'
                                            status={configurationPlanInterestPercentageStatus}
                                            change={(value) => setConfigurationPlanInterestPercentage(value)}
                                            onChange={(value) => this.setState({ number: value })}
                                        />
                                    </Col>

                                    <Col xs={6} md={4}>
                                        <InputMoney
                                            value={configurationPlanInstallmentLimitValue}
                                            label='Valor Limite Parcela'
                                            status={configurationPlanInstallmentLimitValueStatus}
                                            change={(value) => setConfigurationPlanInstallmentLimitValue(value)}
                                        />
                                    </Col>

                                    <Col xs={6} md={2}>
                                        <Check
                                            status={configurationPlanMainCharterable}
                                            set={setConfigurationPlanMainCharterable}
                                            label="Principal"
                                        />
                                    </Col>

                                    <Col xs={6} md={2}>
                                        <Check
                                            status={configurationPlanEmphasisCharterable}
                                            set={setConfigurationPlanEmphasisCharterable}
                                            label="Destaque"
                                        />
                                    </Col>

                                    <Col xs={12} md={4}>
                                        {inputConfiguratioPlanExhibition != null && (
                                            <SelectOne
                                                options={inputConfiguratioPlanExhibition}
                                                value={inputConfiguratioPlanExhibition.find((item) => item.value === String(selectedConfigurationPlanExhibition))}
                                                change={(item) => changeConfigurationPlanExhibition(item)}
                                                searchable
                                                placeholder="Selecione o modo de exibição"
                                            />
                                        )}
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {isEdit ? (
                                    <button type="submit" className="btn btn-primary mt" onClick={handleEditConfigurationPlan}>
                                        Salvar Mudanças
                                    </button>
                                ) : (
                                    <button type="submit" className="btn btn-primary mt" onClick={handleAddConfigurationPlan}>
                                        Salvar
                                    </button>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </Card>
        </>
    );
}

export default ConfigurationPlanCreate;
