import React from 'react';

import * as S from './styles';

function Radio({ items, click }) {
  function handleClick(index) {
    const selected = items.map((item, i) => {
      if (index === i) {
        return { ...item, selected: true };
      }
      return { ...item, selected: false };
    });

    click(selected);
  }

  return (
    <S.Container>
      {items.map((item, index) => {
        return (
          <S.Item key={index} onClick={() => handleClick(index)}>
            <S.Icon>{item.selected ? <S.IconSelected /> : null}</S.Icon>
            <S.Label>{item.label}</S.Label>
          </S.Item>
        );
      })}
    </S.Container>
  );
}

export default Radio;
