import React from 'react';
import { translate } from '@/i18n/src/locales';
import Card from '@/components/Card';
import { format } from 'date-fns';
import { pt } from 'date-fns/esm/locale';
import Badge from '@/components/Badge';

function DetailsItemInvoice({ detailsItemInvoice, idLicencaTipoFaturamento }) {
    switch (idLicencaTipoFaturamento) {
        case 40: //Voucher Gerado
            return (
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>{translate('hash')}</th>
                                <th>{translate('product')}</th>
                                <th>{translate('value')}</th>
                                <th>{translate('genaratedDate')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detailsItemInvoice.map((item) => (
                                <tr
                                    key={
                                        item.idFaturaItemDetidFaturaItemDetalheVoucherGeradoalheAluno
                                    }>
                                    <td>{item.hash}</td>
                                    <td>{item.nomeProduto}</td>
                                    <td>{item.valorUnitario}</td>
                                    <td>
                                        {format(
                                            new Date(item.dataGerado),
                                            'dd/MM/yyyy : HH:mm:ss',
                                            {
                                                locale: pt,
                                            },
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
            break;
        case 41: //Voucher Assinatura Trilha
            return (
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>{translate('studentName')}</th>
                                <th>{translate('signature')}</th>
                                <th>{translate('trail')}</th>
                                <th>{translate('course')}</th>
                                <th>{translate('value')}</th>
                                <th>{translate('enrollmentDate')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detailsItemInvoice.map((item) => (
                                <tr key={item.idFaturaItemDetalheVoucherMatriculaTrilha}>
                                    <td>{item.nomeAluno}</td>
                                    <td>{item.nomeAssinatura}</td>
                                    <td>{item.nomeTrilha}</td>
                                    <td>{item.nomeCurso}</td>
                                    <td>{item.valorUnitario}</td>
                                    <td>
                                        {format(
                                            new Date(item.dataMatricula),
                                            'dd/MM/yyyy : HH:mm:ss',
                                            {
                                                locale: pt,
                                            },
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
            break;

        default:
            return (
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>{translate('studentName')}</th>
                                <th>{translate('moduleTitle')}</th>
                                <th>{translate('class')}</th>
                                <th>{translate('billable')}</th>
                                <th>{translate('registrationDate')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detailsItemInvoice.map((item) => (
                                <tr key={item.idFaturaItemDetalheAluno}>
                                    <td>{item.nomeAluno}</td>
                                    <td>{item.nomeCurso}</td>
                                    <td>{item.ultimaAula}</td>
                                    <td>
                                        {item.faturavel ? (
                                            <Badge type="success">{translate('billed')}</Badge>
                                        ) : (
                                            <Badge type="danger">{translate('unbilled')}</Badge>
                                        )}
                                    </td>
                                    <td>
                                        {format(
                                            new Date(item.dataHoraRegistro),
                                            'dd/MM/yyyy : HH:mm:ss',
                                            {
                                                locale: pt,
                                            },
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
            break;
    }
}

export default DetailsItemInvoice;
