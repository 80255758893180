import {
    SECTIONS_SET,
    SECTION_EDIT_SET,
    SECTION_ID_EDIT_SET,
    SECTION_LOADING,
    SECTION_LOADED,
    SECTION_CLEAR,
    SECTION_PRODUCTS_SET,
    SECTION_PRODUCTS_LOADING,
    SECTION_PRODUCTS_LOADED,
    SECTION_PAYMENTPLANS_SET,
    SECTION_PAYMENTPLANS_LOADING,
    SECTION_PAYMENTPLANS_LOADED
} from '../actionTypes';

const initialState = {
    sections: [],
    sectionProducts: [],
    sectionPaymentPlans: [],
    sectionEdit: null,
    sectionIdEdit: null,
    sectionIsLoading: false,
    isProdutcOnSection: false,
    sectionProductsIsLoading: false,
    sectionIsProducts: false,
    isSectionOnPaymentPlan: false,
    sectionPaymentPlansIsLoading: false,
    sectionIsPaymentPlans: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SECTIONS_SET:
            return {
                ...state,
                sections: action.payload,
            };
        case SECTION_EDIT_SET:
            return {
                ...state,
                sectionEdit: action.payload,
            };
        case SECTION_ID_EDIT_SET:
            return {
                ...state,
                sectionIdEdit: action.payload,
            };
        case SECTION_LOADING:
            return {
                ...state,
                sectionIsLoading: true,
            };
        case SECTION_LOADED:
            return {
                ...state,
                sectionIsLoading: false,
            };
        case SECTION_CLEAR:
            return {
                ...state,
                sections: [],
                sectionIsLoading: false,
            };
        case SECTION_PRODUCTS_SET:
            return {
                ...state,
                sectionProducts: action.payload.products,
                isProdutcOnSection: action.payload.isProdutcOnSection,
            };
        case SECTION_PRODUCTS_LOADING:
            return {
                ...state,
                sectionProductsIsLoading: true,
            };
        case SECTION_PRODUCTS_LOADED:
            return {
                ...state,
                sectionProductsIsLoading: false,
            };


        case SECTION_PAYMENTPLANS_SET:
            return {
                ...state,
                sectionPaymentPlans: action.payload.paymentPlans,
                isSectionOnPaymentPlan: action.payload.isSectionOnPaymentPlan,
            };
        case SECTION_PAYMENTPLANS_LOADING:
            return {
                ...state,
                sectionPaymentPlansIsLoading: true,
            };
        case SECTION_PAYMENTPLANS_LOADED:
            return {
                ...state,
                sectionPaymentPlansIsLoading: false,
            };
        default:
            return state;
    }
};

export default reducer;
