import React from 'react';

import Card from '@/components/Card';

import { useHistory } from 'react-router-dom';

import { translate } from '@/i18n/src/locales';
import { Helmet } from 'react-helmet';
import IsometricImg from '@/assets/images/erro_feedback.svg';

import { Img, Row } from './styles';

const Start = () => {
    const history = useHistory();
    return (
        <>
            <Helmet>
                <title>Administração Franqueadora - {translate('start')}</title>
            </Helmet>
            <Card>
                <Row>
                    <div className="img-start">
                        <Img src={IsometricImg} />
                    </div>
                    <div className="box-name">
                        <div className="name">Ops, algo deu errado</div>
                        <div className="description">
                            <button
                                type="button"
                                class="btn btn-primary btn-voltar"
                                onClick={() => history.goBack()}>
                                Voltar
                            </button>
                        </div>
                    </div>
                </Row>
            </Card>
        </>
    );
};

export default Start;
