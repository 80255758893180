import {
    CONFIGURATIONPLAN_TYPES_SET,
    CONFIGURATIONPLAN_TYPES_LOADING,
    CONFIGURATIONPLAN_TYPES_LOADED
} from '../actionTypes';

const initialState = {
    configurationPlanTypes: [],
    configurationPlanTypesIsLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CONFIGURATIONPLAN_TYPES_SET:
            return {
                ...state,
                configurationPlanTypes: action.payload,
            };
        case CONFIGURATIONPLAN_TYPES_LOADING:
            return {
                ...state,
                configurationPlanTypesIsLoading: true,
            };
        case CONFIGURATIONPLAN_TYPES_LOADED:
            return {
                ...state,
                configurationPlanTypesIsLoading: false,
            };
        default:
            return state;
    }
};

export default reducer;
