import React, { useEffect, useState } from 'react';

import { RiCloseCircleLine, RiCheckboxCircleLine, RiErrorWarningLine } from 'react-icons/ri';
import { AlertContainer } from './styles';

import { useSelector, useDispatch } from 'react-redux';
import { closeAlert } from '@/store/actions/theme';

function Alert(props) {

    const alert = useSelector((state) => state.theme.alert);
    const sideBarOpen = useSelector((state) => state.theme.sideBarOpen);
    const [height, setHeight] = useState(0);
    const [icon, setIcon] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (alert.type != null) {
            setHeight('60px');

            switch (alert.type) {
                case 'danger':
                    setIcon(<RiCloseCircleLine size={25} />);
                    break;
                case 'success':
                    setIcon(<RiCheckboxCircleLine size={25} />);
                    break;
                case 'warning':
                    setIcon(<RiErrorWarningLine size={25} />);
                    break;
                case 'info':
                    setIcon(<RiErrorWarningLine size={25} />);
                    break;

                default:
                    break;
            }

            setTimeout(
                function () {
                    setHeight('0px');
                    dispatch(closeAlert());
                },
                alert.time != null ? alert.time : 4000,
            );
        }
    }, [alert]);

    return (
        <AlertContainer height={height} type={alert.type} sideBarOpen={sideBarOpen}>
            {icon}
            {alert.message}
        </AlertContainer>
    );
}

export default Alert;
