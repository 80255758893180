import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { translate } from '@/i18n/src/locales';
import TitlePage from '@/components/TitlePage';
import Card from '@/components/Card';
import { fetchAllProductAdmInternal } from '@/store/actions/product';

import ContentLoader from 'react-content-loader';

import DataTable from '@/components/DataTable';
import Badge from '@/components/Badge';
import IcEdit from '@/assets/images/ic-edit.svg';
import * as ROUTES from '@/routes/routes';

function Products() {
  const dispatch = useDispatch();

  const products = useSelector((state) => state.product.products);
  const productIsLoading = useSelector((state) => state.product.productIsLoading);

  useEffect(() => {
    dispatch(fetchAllProductAdmInternal());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Administração Franqueadora - {translate('products')}</title>
      </Helmet>
      <TitlePage title={translate('products')} link={ROUTES.PRODUCT_CREATE} txtButton={translate('productCreate')} />
      <Card>
        {productIsLoading ? (
          <ContentLoader width="100%" speed={1} height="400px" backgroundColor="#c6c6c6" foregroundColor="#F0F0F5">
            <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="60px" />
            <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="150" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="200" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="250" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="300" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="350" rx="5px" ry="5px" width="100%" height="40px" />
          </ContentLoader>
        ) : (
          <DataTable
            data={products}
            searchPlaceHolder="Buscar produto"
            columns={[
              {
                compact: true,
                center: true,
                width: '60px',
                cell: (row) => <img src={row.Imagem} alt={row.NomeProduto} />,
              },
              {
                name: 'Nome',
                selector: 'Nome',
                sortable: true,
              },
              {
                name: 'Ativo',
                selector: 'Ativo',
                compact: true,
                width: '120px',
                cell: (row) => {
                  return row.Ativo ? <Badge type="success">Ativo</Badge> : <Badge type="danger">Inativo</Badge>;
                },
              },
              {
                width: '60px',
                cell: (row) => (
                  <Link to={`${ROUTES.PRODUCT_CREATE}/${row.IdProduto}`}>
                    <img className="icon-edit" src={IcEdit} alt={row.Nome} />
                  </Link>
                ),
              },
            ]}
            columnFilter="Nome"
          />
        )}
      </Card>
    </>
  );
}

export default Products;
