/* eslint-disable jsx-a11y/click-events-have-key-events */
import { colors } from '@/template/styles';
import React from 'react';
import { RiCheckboxFill, RiCheckboxBlankLine } from 'react-icons/ri';

import { Container } from './styles';

function Check({ status, set, label }) {
  function handleClick() {
    set(!status);
  }

  return (
    <Container onClick={() => handleClick()}>
      {status ? <RiCheckboxFill size={25} color={colors.success} /> : <RiCheckboxBlankLine size={25} />}
      <div>{label}</div>
    </Container>
  );
}

export default Check;
