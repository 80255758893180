import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { translate } from '@/i18n/src/locales';
import TitlePage from '@/components/TitlePage';
import Card from '@/components/Card';
import { fetchAllPaymentPlan } from '@/store/actions/paymentPlan';

import ContentLoader from 'react-content-loader';

import DataTable from '@/components/DataTable';
import Badge from '@/components/Badge';
import IcEdit from '@/assets/images/ic-edit.svg';
import * as ROUTES from '@/routes/routes';

function PaymentPlans() {
  const dispatch = useDispatch();

  const paymentPlans = useSelector((state) => state.paymentPlan.paymentPlans);
  const paymentPlanIsLoading = useSelector((state) => state.paymentPlan.paymentPlanIsLoading);

  useEffect(() => {
    dispatch(fetchAllPaymentPlan());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Administração Franqueadora -{translate('paymentPlans')}</title>
      </Helmet>
      <TitlePage title={translate('paymentPlans')} link={ROUTES.PAYMENTPLAN_CREATE} txtButton={translate('paymentPlanCreate')} />
      <Card>
        {paymentPlanIsLoading ? (
          <ContentLoader width="100%" speed={1} height="400px" backgroundColor="#c6c6c6" foregroundColor="#F0F0F5">
            <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="60px" />
            <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="150" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="200" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="250" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="300" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="350" rx="5px" ry="5px" width="100%" height="40px" />
          </ContentLoader>
        ) : (
          <DataTable
            data={paymentPlans}
            searchPlaceHolder={translate('searchPaymentPlanName')}
            columns={[
              { name: translate('paymentPlanName'), selector: 'Descricao', sortable: true },
              {
                width: '60px',
                cell: (row) => (
                  <Link to={`${ROUTES.PAYMENTPLAN_CREATE}/${row.IdPlanoPagamento}`}>
                    <img className="icon-edit" src={IcEdit} alt={row.Descricao} />
                  </Link>
                ),
              },
            ]}
            columnFilter="Descricao"
          />
        )}
      </Card>
    </>
  );
}

export default PaymentPlans;
