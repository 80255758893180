export const TOKEN_KEY = '@appfranqueadora:token';
export const REFRESH_KEY = '@appfranqueadora:refreshToken';

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const setToken = (token) => localStorage.setItem(TOKEN_KEY, token);
export const removeToken = () => localStorage.removeItem(TOKEN_KEY);

export const getRefreshToken = () => localStorage.getItem(REFRESH_KEY);
export const setRefreshToken = (refreshToken) => localStorage.setItem(REFRESH_KEY, refreshToken);
export const removeRefreshToken = () => localStorage.removeItem(REFRESH_KEY);
