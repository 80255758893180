import styled from 'styled-components';
import { colors, metrics } from '@/template/styles';
import { Link } from 'react-router-dom';

export const Container = styled.h1`
  color: ${colors.dark};
  margin-bottom: ${metrics.space};
  border-left: 4px solid #a2a2a2;
  padding-left: 10px;
`;

export const Button = styled(Link)`
  max-width: 300px !important;
`;

export const ContainerTextoButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  .texto {
    flex: 1;
    color: ${colors.dark};
    font-size: 2.8rem;
    font-weight: 700;
    border-left: 4px solid #a2a2a2;
    padding-left: 10px;
  }
`;
