/* eslint-disable no-console */
import { api } from '@/services/api';
import { PLANS_SET, PLANS_LOADING, PLANS_LOADED } from '../actionTypes';

export const setPlans = (plans) => ({
  type: PLANS_SET,
  payload: plans,
});

export const loadingPlans = () => ({
  type: PLANS_LOADING,
});

export const loadedPlans = () => ({
  type: PLANS_LOADED,
});

export const fetchFranchisorPlans = () => {
  return (dispatch) => {
    dispatch(loadingPlans());

    api
      .get(`/FranqueadoraPlano/GetAll/`)
      .then((data) => {
        console.log(data);
        dispatch(setPlans(data.data));
        dispatch(loadedPlans());
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};
