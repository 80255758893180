import { apiLoja } from '@/services/api';
import { SIGNATURE_TYPE_SET, SIGNATURE_TYPE_LOADING, SIGNATURE_TYPE_LOADED } from '../actionTypes';

export const setSignatureType = (plans) => ({
  type: SIGNATURE_TYPE_SET,
  payload: plans,
});

export const loadingSignatureType = () => ({
  type: SIGNATURE_TYPE_LOADING,
});

export const loadedSignatureType = () => ({
  type: SIGNATURE_TYPE_LOADED,
});

export const fetchSignatureType = () => {
  return (dispatch) => {
    dispatch(loadingSignatureType());

    apiLoja
      .get(`/TipoAssinatura`)
      .then((data) => {
        dispatch(setSignatureType(data.data));
        dispatch(loadedSignatureType());
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error comming', error);
      });
  };
};
