import React from 'react';

import { Container, Label } from './styles';
import InputMask from 'react-input-mask';

function Input({ name, value, type, label, status = false, change }) {
  return (
    <Container status={status.status}>
      <Label status={status.status}>{label}</Label>
      <InputMask
        name={name}
        type={type}
        value={value}
        onChange={(e) => change(e.target.value)}
        mask={"99999-999"}
      />
      <div className="feedback">{status.feedback}</div>
    </Container>
  );
}

export default Input;
