import styled from 'styled-components';
import { metrics, colors, fonts } from '@/template/styles';
import Collapse from '@kunukn/react-collapse';

export const Container = styled.div``;

export const BoxForm = styled.div``;

export const CardFilter = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    .boxFilter {
        display: flex;
        justify-content: space-between;
    }

    .boxDate {
        margin-top: ${metrics.space};
        display: flex;
    }

    .boxDate input {
        margin-right: ${metrics.space};
    }

    .de {
        display: flex;
        align-items: center;
        margin-left: ${metrics.space};
        margin-right: ${metrics.space};
    }

    .react-datepicker {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 1.4rem;
        background-color: #fff;
        color: #000;
        border: 1px solid #aeaeae;
        border-radius: 0.3rem;
        display: inline-block;
        position: relative;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        color: #000;
        display: inline-block;
        width: 3rem;
        line-height: 3rem;
        text-align: center;
        margin: 0.166rem;
    }

    .item {
        display: flex;
        align-items: center;
        height: 50px;
        width: 100%;
        margin-top: ${metrics.space};
        background: ${colors.white};
        border-radius: ${metrics.radius};
        padding: 1rem;
        border: 2px solid ${colors.white};
        cursor: pointer;
        margin-right: ${metrics.space};
        font-size: ${fonts.regular};
    }

    .item.active {
        border: 2px solid ${colors.success};
        color: ${colors.success};
        font-weight: 700;
    }

    .description {
        margin-left: 10px;
    }

    .btn-filter {
        margin-left: 2rem;
    }

    input {
        height: 50px;
        color: #4b515d;
        border: 1px solid #dcdce6;
        border-radius: 5px;
        padding-left: 15px;
    }
`;

export const ItemSale = styled.div`
    width: 100%;
    background: #fff;
    padding: 2rem;
    border-radius: 5px;
    margin-top: ${metrics.space};
    cursor: pointer;
`;

export const HeaderSales = styled.div`
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 40px;
    align-items: center;

    div:first-child {
        margin-right: 10px;
    }

    .titulo {
        font-size: ${fonts.smaller};
        color: ${colors.regular};
        font-weight: 700;
        text-transform: uppercase;
    }

    .status-voucher {
        display: flex;
        align-items: center;
    }

    .indicator {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin-right: 10px;
    }

    .indicator.success {
        background: ${colors.success};
    }

    .indicator.warning {
        background: ${colors.warning};
    }

    .indicator.danger {
        background: ${colors.danger};
    }
`;

export const ArrowCollapse = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${(props) => {
        if (props.open) {
            return `rotate(180deg)`;
        } else {
            return `rotate(0deg)`;
        }
    }};

    transition: all 0.2s;
`;

export const ContainerLoader = styled.div`
    margin-top: ${metrics.space};
`;

export const CollapseItem = styled(Collapse)``;

export const StudentContainer = styled.div`
    .header {
        font-size: ${fonts.small};
        border-bottom: 1px solid ${colors.gray};
        margin-top: ${metrics.space};
        padding-bottom: 1rem;
        font-weight: 700;
    }
    .body {
        display: flex;
        justify-content: space-around;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .title-item {
        font-size: ${fonts.smaller};
        color: ${colors.regular};
        font-weight: 700;
    }
`;

export const VoucherContainer = styled.div`
    .header {
        font-size: ${fonts.small};
        border-bottom: 1px solid ${colors.gray};
        margin-top: ${metrics.space};
        padding-bottom: 1rem;
        font-weight: 700;
    }
    .body {
        display: flex;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        flex-wrap: wrap;
    }

    .title-item {
        font-size: ${fonts.smaller};
        color: ${colors.regular};
        font-weight: 700;
    }
`;
export const ResponsibleContainer = styled.div`
    .header {
        font-size: ${fonts.small};
        border-bottom: 1px solid ${colors.gray};
        margin-top: ${metrics.space};
        padding-bottom: 1rem;
        font-weight: 700;
    }
    .body {
        display: flex;
        justify-content: space-around;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .title-item {
        font-size: ${fonts.smaller};
        color: ${colors.regular};
        font-weight: 700;
    }
`;

export const Installments = styled.div`
    .header {
        font-size: ${fonts.small};
        border-bottom: 1px solid ${colors.gray};
        margin-top: ${metrics.space};
        padding-bottom: 1rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    th {
        font-size: ${fonts.smaller};
        padding: 1rem 1rem;
        border-top: 1px solid #e9eaeb;
        border-bottom: 1px solid #e9eaeb;
        text-align: left;
        text-transform: uppercase;
    }

    td {
        border-bottom: 1px solid #d3d3d3;
        padding: 1rem 1rem;
    }
`;

export const Products = styled.div`
    .header {
        font-size: ${fonts.small};
        border-bottom: 1px solid ${colors.gray};
        margin-top: ${metrics.space};
        padding-bottom: 1rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    .box-prod {
        display: flex;
        align-items: center;
    }

    .img-prod {
        width: 50px;
        border-radius: ${metrics.radius};
        margin-right: ${metrics.space};
    }

    th {
        font-size: ${fonts.smaller};
        padding: 1rem 1rem;
        border-top: 1px solid #e9eaeb;
        border-bottom: 1px solid #e9eaeb;
        text-align: left;
        text-transform: uppercase;
    }

    td {
        border-bottom: 1px solid #d3d3d3;
        padding: 1rem 1rem;
    }
`;

export const FooterSale = styled.div`
    display: flex;
    justify-content: space-between;
    background: #1e273b;
    padding: ${metrics.space};
    border-radius: ${metrics.radius};
    color: ${colors.white};

    .title-item {
        font-size: ${fonts.smaller};
        font-weight: 700;
        text-transform: uppercase;
    }

    .total-pago {
        color: ${colors.success};
        font-weight: 700;
    }
`;
