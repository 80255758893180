import React from 'react';

import CurrencyInput from 'react-currency-input-field';
import { Container, Label } from './styles';

function Input({ name, value, label, status, change }) {
  return (
    <Container status={status.status}>
      <Label status={status.status}>{label}</Label>
      <CurrencyInput
        id="input-example"
        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
        name={name}
        value={value}
        allowNegativeValue={false}
        disableGroupSeparators
        decimalsLimit={2}
        onValueChange={(v) => change(v)}
      />
      <div className="feedback">{status.feedback}</div>
    </Container>
  );
}

export default Input;
