import { apiLoja } from '@/services/api';
import { SALES_SET, SALES_LOADING, SALES_LOADED, SALES_CLEAR, SALES_FILTER_SET } from '../actionTypes';

export const setSales = (sales) => ({
  type: SALES_SET,
  payload: sales,
});

export const loadingSales = () => ({
  type: SALES_LOADING,
});

export const loadedSales = () => ({
  type: SALES_LOADED,
});

export const clearSales = () => ({
  type: SALES_CLEAR,
});

export const setFilterSales = (filters) => ({
  type: SALES_FILTER_SET,
  payload: filters,
});

export const fetchSales = (idPole, initialDate, endDate) => {
  return (dispatch) => {
    dispatch(loadingSales());

    apiLoja
      .post(`/Vendas/GetAll`, {
        IdPolo: idPole,
        DataInicial: initialDate,
        DataFinal: endDate,
      })
      .then((data) => {
        dispatch(setSales(data.data));
        dispatch(loadedSales());
      })
      .catch(() => {
        dispatch(loadedSales());
      });
  };
};
