import { COUPON_TYPE_APPLICATION_SET, COUPON_TYPE_APPLICATION_LOADING, COUPON_TYPE_APPLICATION_LOADED } from '../actionTypes';

const initialState = {
    couponTypeApplication: [],
    couponTypeApplicationIsLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case COUPON_TYPE_APPLICATION_SET:
            return {
                ...state,
                couponTypeApplication: action.payload,
            };
        case COUPON_TYPE_APPLICATION_LOADING:
            return {
                ...state,
                couponTypeApplicationIsLoading: true,
            };
        case COUPON_TYPE_APPLICATION_LOADED:
            return {
                ...state,
                couponTypeApplicationIsLoading: false,
            };
        default:
            return state;
    }
};

export default reducer;
