import { USER_SET, USER_LOADING, USER_LOADED, USER_ERROR } from '../actionTypes';
import { api } from '@/services/api';
import decode from 'jwt-decode';

import { setLanguageToI18n } from '@/i18n/src/locales';

import { getToken, setToken, removeToken, setRefreshToken, removeRefreshToken } from '@/services/localStorageService';

export const logout = () => {
  removeToken();
  removeRefreshToken();

  api.defaults.headers.common['Authorization'] = ``;
  delete api.defaults.headers.common['Authorization'];
  return (dispatch) => {
    dispatch(
      setUser({
        user: null,
        roles: null,
        isUserLoading: false,
        isUserError: false,
      })
    );
  };
};

export const setUser = (user) => ({
  type: USER_SET,
  payload: user,
});

export const loadingUser = () => ({
  type: USER_LOADING,
});

export const loadedUser = () => ({
  type: USER_LOADED,
});

export const userError = () => ({
  type: USER_ERROR,
});

export const Login = (login, senha) => {
  return (dispatch) => {
    dispatch(loadingUser());

    api
      .post('/Passaporte/login', {
        login,
        senha,
      })
      .catch((err) => {
        console.log(err);
        dispatch(userError(err));
      })
      .then((res) => {
        if (res === undefined) {
          dispatch(userError());
        } else {
          const token = res.data.jwtToken;
          const refreshToken = res.data.refreshToken;
          setToken(token);
          setRefreshToken(refreshToken);

          const user = decode(token);
          const roles = user.role;
          setLanguageToI18n(user.Linguagem);

          dispatch(
            setUser({
              user,
              roles,
            })
          );

          dispatch(loadedUser());
        }
      });
  };
};
