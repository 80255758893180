import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { translate } from '@/i18n/src/locales';
import TitlePage from '@/components/TitlePage';
import Card from '@/components/Card';
import { fetchAllConfigurationPlans } from '@/store/actions/configurationPlan';
import { fetchAllConfigurationPlanTypes } from '@/store/actions/configurationPlanTypes';
import { fetchAllConfigurationPlanExhibition } from '@/store/actions/configurationPlanExhibition';

import ContentLoader from 'react-content-loader';

import DataTable from '@/components/DataTable';
import Badge from '@/components/Badge';
import IcEdit from '@/assets/images/ic-edit.svg';
import * as ROUTES from '@/routes/routes';

function PaymentPlans() {
    const dispatch = useDispatch();

    const configurationPlans = useSelector((state) => state.configurationPlan.configurationPlans);
    const configurationPlanIsLoading = useSelector((state) => state.configurationPlan.configurationPlanIsLoading);
    const [inputConfiguratioPlanTypes, setInputConfiguratioPlanTypes] = useState(null);
    const [inputConfigurationPlanExhibition, setInputConfigurationPlanExhibition] = useState(null);
    const configurationPlanTypes = useSelector((state) => state.configurationPlanTypes.configurationPlanTypes);
    const configurationPlanExhibition = useSelector((state) => state.configurationPlanExhibition.configurationPlanExhibition);

    useEffect(() => {
        dispatch(fetchAllConfigurationPlans());
        dispatch(fetchAllConfigurationPlanTypes());
        dispatch(fetchAllConfigurationPlanExhibition());
    }, [dispatch]);

    useEffect(() => {
        if (configurationPlanTypes.length > 0) {
            setInputConfiguratioPlanTypes(
                configurationPlanTypes.map((item) => ({
                    label: item.Tipo,
                    value: String(item.IdPlanoPagamentoTipo),
                }))
            );
        }

    }, [configurationPlanTypes]);

    useEffect(() => {
        if (configurationPlanExhibition.length > 0) {
            setInputConfigurationPlanExhibition(
                configurationPlanExhibition.map((item) => ({
                    label: item.Descricao,
                    value: String(item.IdPlanoConfiguracaoExibicao),
                }))
            );
        }

    }, [configurationPlanExhibition]);

    return (
        <>
            <Helmet>
                <title>Administração Franqueadora -{translate('configurationPlans')}</title>
            </Helmet>
            <TitlePage title={translate('configurationPlans')} link={ROUTES.CONFIGURATIONPLAN_CREATE} txtButton={translate('configurationPlanCreate')} />
            <Card>
                {configurationPlanIsLoading ? (
                    <ContentLoader width="100%" speed={1} height="400px" backgroundColor="#c6c6c6" foregroundColor="#F0F0F5">
                        <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="60px" />
                        <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="40px" />
                        <rect x="0" y="150" rx="5px" ry="5px" width="100%" height="40px" />
                        <rect x="0" y="200" rx="5px" ry="5px" width="100%" height="40px" />
                        <rect x="0" y="250" rx="5px" ry="5px" width="100%" height="40px" />
                        <rect x="0" y="300" rx="5px" ry="5px" width="100%" height="40px" />
                        <rect x="0" y="350" rx="5px" ry="5px" width="100%" height="40px" />
                    </ContentLoader>
                ) : (
                    <DataTable
                        data={configurationPlans}
                        searchPlaceHolder={translate('searchConfigurationPlansName')}
                        columns={[
                            {
                                name: translate('configurationPlansType'),
                                selector: 'IdPlanoPagamentoTipo',
                                sortable: true,
                                cell: (row) => {
                                    return `${configurationPlanTypes === null ? '' :
                                        configurationPlanTypes.find(v => v.IdPlanoPagamentoTipo === row.IdPlanoPagamentoTipo).Tipo}`
                                }
                            },
                            {
                                name: translate('description'),
                                selector: 'Descricao',
                                sortable: true,
                                width: '350px',
                            },
                            {
                                name: translate('qtyTimes'),
                                selector: 'QtdVezes',
                                sortable: true,
                                compact: true,
                                width: '100px',
                            },
                            {
                                name: translate('entryPercentage'),
                                selector: 'EntradaPorcentagem',
                                sortable: true,
                                cell: (row) => {
                                    return `${row.EntradaPorcentagem}%`
                                },
                            },
                            {
                                name: translate('discountPercentage'),
                                selector: 'DescontoPorcentagem',
                                sortable: true,
                                cell: (row) => {
                                    return `${row.DescontoPorcentagem}%`
                                },
                            },
                            {
                                name: translate('interestPercentage'),
                                selector: 'JurosPorcentagem',
                                sortable: true,
                                cell: (row) => {
                                    return `${row.JurosPorcentagem}%`
                                },
                            },
                            {
                                name: translate('main'),
                                selector: 'Principal',
                                compact: true,
                                width: '100px',
                                cell: (row) => {
                                    return row.Principal ? <Badge type="success">Ativo</Badge> : <Badge type="danger">Inativo</Badge>;
                                }
                            },
                            {
                                name: translate('emphasis'),
                                selector: 'Destaque',
                                compact: true,
                                width: '100px',
                                cell: (row) => {
                                    return row.Destaque ? <Badge type="success">Ativo</Badge> : <Badge type="danger">Inativo</Badge>;
                                }
                            },
                            {
                                name: translate('installmentLimitValue'),
                                selector: 'ValorLimiteParcela',
                                sortable: true,
                                cell: (row) => {
                                    return row.ValorLimiteParcela === null ? '' :
                                        row.ValorLimiteParcela.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                                }
                            },
                            {
                                name: "Tipo Exibição",
                                selector: 'IdPlanoConfiguracaoExibicao',
                                sortable: true,
                                cell: (row) => {
                                    if (row.IdPlanoConfiguracaoExibicao === null) {
                                        return 'Todos'
                                    }
                                    else {
                                        return `${configurationPlanExhibition === null ? '' :
                                            configurationPlanExhibition.find(v => v.IdPlanoConfiguracaoExibicao === row.IdPlanoConfiguracaoExibicao).Descricao}`
                                    }
                                }
                            },
                            {
                                width: '60px',
                                cell: (row) => (
                                    <Link to={`${ROUTES.CONFIGURATIONPLAN_CREATE}/${row.IdPlanoConfiguracao}`}>
                                        <img className="icon-edit" src={IcEdit} alt={row.Descricao} />
                                    </Link>
                                ),
                            },
                        ]}
                        columnFilter="Descricao"
                    />
                )}
            </Card>
        </>
    );
}

export default PaymentPlans;
