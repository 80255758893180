import { Body } from '@/components/Voucher/styles';
import { apiLoja } from '@/services/api';
import { COUPON_POLES_SET, COUPON_POLES_LOADING, COUPON_POLES_LOADED } from '../actionTypes';
import * as _ from 'lodash';


export const setCouponPoles = (couponsPole, isPolesOnCoupon) => ({
    type: COUPON_POLES_SET,
    payload: { couponsPole, isPolesOnCoupon },
});

export const loadingCouponPoles = () => ({
    type: COUPON_POLES_LOADING,
});

export const loadedCouponPoles = () => ({
    type: COUPON_POLES_LOADED,
});

export const fetchAllPolesCouponLoja = (IdCupom) => {
    return (dispatch) => {
        dispatch(loadingCouponPoles());
        apiLoja
            .get(`/CupomPolo/GetAllCupomPoloByIdCupom/${IdCupom}`)
            .then((data) => {
                if (data.data) {
                    const ordered = _.orderBy(data.data, 'Ativo', 'desc');
                    const isPolesOnCoupon = _.find(ordered, { Ativo: true }) || false;

                    if (isPolesOnCoupon) {
                        dispatch(setCouponPoles(ordered, true));
                    } else {
                        dispatch(setCouponPoles(ordered, false));
                    }
                }
                dispatch(loadedCouponPoles());
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const fetchCouponPolesLoja = (couponPole) => {
    return (dispatch) => {
        dispatch(loadingCouponPoles());
        apiLoja
            .get(`/CupomPolo/GetCupomPolo/${couponPole.IdCupom}/${couponPole.IdPolo}`)

            .then((data) => {
                if (data.data) {
                    dispatch(updateCouponPole(couponPole));
                }
                else {
                    dispatch(addCouponPoles(couponPole));
                }
                dispatch(loadedCouponPoles());
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const addCouponPoles = (data) => {
    return (dispatch) => {
        dispatch(loadingCouponPoles());
        apiLoja
            .post(`/CupomPolo/AddCupomPoloByIdCupom`, [data])
            .then(() => {
                dispatch(fetchAllPolesCouponLoja(data.IdCupom));
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

export const updateCouponPole = (couponPole) => {
    return (dispatch) => {
        dispatch(loadingCouponPoles());
        apiLoja
            .put(`/CupomPolo`, {
                ...couponPole,
            })
            .then((data) => {
                dispatch(fetchAllPolesCouponLoja(couponPole.IdCupom));
            })
            .catch((error) => {
                console.log('error comming', error);
            });
    };
};

