import styled from 'styled-components';
import { metrics, colors, fonts } from '@/template/styles';
import Collapse from '@kunukn/react-collapse';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const BoxInvoice = styled.section`
  display: flex;
  width: 100%;
  border-radius: ${metrics.radius};
  align-items: center;

  background: #1e273b; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #1e273b 0%, #2f3640 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #1e273b 0%, #2f3640 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #1e273b 0%, #2f3640 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e273b', endColorstr='#2f3640',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  .slide-current {
    background: #3e4856;
  }
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${colors.white};

  div {
    text-align: center;
  }

  .badge {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-top: 10px;
  }

  .generated {
    background: ${colors.success};
  }

  .not-generated {
    background: ${colors.danger};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const BoxForm = styled.div`
  padding-bottom: ${metrics.space};
`;

export const ButtonCarousel = styled.button`
  background: transparent;
  border: none;
`;

export const BoxItensInvoice = styled.section`
  margin-top: ${metrics.space};
`;

export const HeaderInvoice = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 40px;
  align-items: center;
  height: 100px;
  cursor: pointer;
  border-top: 3px solid #e9eaeb;

  &.item {
    display: flex;
    flex-direction: columns;
  }

  .titulo {
    font-size: ${fonts.smaller};
    color: ${colors.regular};
    font-weight: 700;
    text-transform: uppercase;
  }
`;

export const ArrowCollapse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props) => {
    if (props.open) {
      return `rotate(180deg)`;
    } else {
      return `rotate(0deg)`;
    }
  }};

  transition: all 0.2s;
`;

export const CollapseItem = styled(Collapse)`
  th {
    text-transform: uppercase;
    font-size: 1rem;
  }
`;

export const BoxFooter = styled.section`
  display: flex;
  width: 100%;
  margin-top: ${metrics.space};

  div {
    margin-right: ${metrics.space};
  }

  div:last-child {
    margin-right: 0;
  }

  .title {
    font-size: ${fonts.bigger};
    text-transform: uppercase;
    margin: 0;
  }

  .content {
    margin: 0;
  }
`;

export const BoxPeriodoReferencia = styled.section`
  margin-top: ${metrics.space};
  font-weight: 700;
  text-transform: uppercase;
`;
