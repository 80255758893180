import {
	THEME_TOOGLE_SIDEBAR,
	THEME_SHOW_ALERT,
	THEME_CLOSE_ALERT,
} from '../actionTypes';

export const setSideBarOpen = (sideBarOpen) => ({
	type: THEME_TOOGLE_SIDEBAR,
	payload: sideBarOpen,
});

export const showAlert = (alert) => ({
	type: THEME_SHOW_ALERT,
	payload: alert,
});

export const closeAlert = (alert) => ({
	type: THEME_CLOSE_ALERT,
});
