import {
    VOUCHER_SET,
    VOUCHER_LOADING,
    VOUCHER_LOADED,
    VOUCHER_FILTER_SET,
    VOUCHER_CLEAR,
} from '../actionTypes';

import { apiLoja } from '@/services/api';

export const setVoucher = (vouchers) => ({
    type: VOUCHER_SET,
    payload: vouchers,
});

export const loadingVoucher = () => ({
    type: VOUCHER_LOADING,
});

export const loadedVoucher = () => ({
    type: VOUCHER_LOADED,
});

export const filterVoucher = (status) => ({
    type: VOUCHER_FILTER_SET,
    payload: status,
});

export const clearVoucher = () => ({
    type: VOUCHER_CLEAR,
});

export const fetchVoucher = (idPolo, idProduto) => {
    return (dispatch, getState) => {
        dispatch(loadingVoucher());

        apiLoja
            .get(`/Voucher/GetAllVoucher/${idPolo}/${idProduto}`)
            .then((data) => {
                dispatch(setVoucher(data.data));
                dispatch(loadedVoucher());
            })
            .catch((error) => {
                console.log('error comming', error);
                dispatch(loadedVoucher());
            });
    };
};

export const createVoucher = (idPole, idProduct, qty) => {
    return (dispatch, getState) => {
        dispatch(loadingVoucher());

        apiLoja
            .post(`/Voucher/AddFranqueadora`, {
                idPolo: idPole,
                idProduto: idProduct,
                qtde: qty,
            })
            .then((data) => {
                dispatch(setVoucher(data.data));
                dispatch(loadedVoucher());
            })
            .catch((error) => {
                console.log('error comming', error);
                dispatch(loadedVoucher());
            });
    };
};
