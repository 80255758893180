import styled from 'styled-components';
import { metrics, colors, fonts } from '@/template/styles';

export const Container = styled.div``;

export const BoxForm = styled.div``;

export const HeaderVoucher = styled.div`
    width: 100%;
    background: #fff;
    padding: 2rem;
    border-radius: 5px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 40px;
    align-items: center;
    height: 80px;
    cursor: pointer;
    margin-top: ${metrics.space};

    div:first-child {
        margin-right: 10px;
    }

    .titulo {
        font-size: ${fonts.smaller};
        color: ${colors.regular};
        font-weight: 700;
    }

    .hash {
        font-size: ${fonts.regular};
        color: ${colors.regular};
        font-weight: 700;
    }

    .status-voucher {
        display: flex;
        align-items: center;
    }

    .indicator {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin-right: 10px;
    }

    .indicator.success {
        background: ${colors.success};
    }

    .indicator.warning {
        background: ${colors.warning};
    }

    .indicator.danger {
        background: ${colors.danger};
    }
`;

export const ArrowCollapse = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${(props) => {
        if (props.open) {
            return `rotate(180deg)`;
        } else {
            return `rotate(0deg)`;
        }
    }};

    transition: all 0.2s;
`;

export const ContentCards = styled.div`
    display: flex;
    flex-direction: row;

    div {
        margin-right: ${metrics.space};
    }

    div:last-child {
        margin-right: 0;
    }

    .item {
        width: 100%;
        margin-top: ${metrics.space};
        background: ${colors.white};
        border-radius: ${metrics.radius};
        padding: ${metrics.space};
        border: 2px solid ${colors.white};
        cursor: pointer;
    }

    .item.active {
        border: 2px solid ${colors.success};
    }
    .qtd {
        font-size: ${fonts.bigger};
    }
`;

export const ContainerLoader = styled.div`
    margin-top: ${metrics.space};
`;
