import { SIGNATURE_TYPE_SET, SIGNATURE_TYPE_LOADING, SIGNATURE_TYPE_LOADED } from '../actionTypes';

const initialState = {
  signatureType: [],
  signatureTypeIsLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNATURE_TYPE_SET:
      return {
        ...state,
        signatureType: action.payload,
      };
    case SIGNATURE_TYPE_LOADING:
      return {
        ...state,
        signatureTypeIsLoading: true,
      };
    case SIGNATURE_TYPE_LOADED:
      return {
        ...state,
        signatureTypeIsLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
