import React, { useState, useEffect } from 'react';

import { translate } from '@/i18n/src/locales';
import { Helmet } from 'react-helmet';
import Card from '@/components/Card';
import TitlePage from '@/components/TitlePage';
import Input from '@/components/Forms/Input';
import InputCEP from '@/components/Forms/InputCEP';
import Radio from '@/components/Forms/Radio';
import { ValidaEmail } from '@/utils/Validacoes';
import SelectOne from '@/components/Forms/SelectOne';
import { useSelector, useDispatch } from 'react-redux';
import { showAlert } from '@/store/actions/theme';
import { createPole, createdPole } from '@/store/actions/pole';
import { fetchFranchisorPlans } from '@/store/actions/plans';
import Lottie from 'lottie-react-web';
import animacao from '@/assets/animated/loading-success.json';
import { RiArrowDropDownLine, RiCheckboxBlankLine, RiCheckboxFill } from 'react-icons/ri';

import { Row, Col } from 'react-flexbox-grid';

import { ContainerLoader, HeaderAdress, ArrowCollapse, CollapseItem } from './styles';

function PoleCreate() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);

  const franqueadoraConfiguracao = JSON.parse(user.FranqueadoraConfiguracao);

  const franqueadoraConfig = franqueadoraConfiguracao.filter((obj) => obj.IdFranqueadoraConfiguracaoTipo === 4);

  const [hideFields, setHideFields] = useState(franqueadoraConfig.length > 0);

  const plans = useSelector((state) => state.plans.plans);

  const [inputPlans, setInputPlans] = useState(null);
  const [inputPlansStatus, setInputPlansStatus] = useState(null);

  const [controlAnimation, setControlAnimation] = useState({
    start: 0,
    end: 57,
    loop: true,
  });
  const polesIsLoading = useSelector((state) => state.pole.polesIsLoading);
  const poleCreated = useSelector((state) => state.pole.poleCreated);

  const [poleName, setPoleName] = useState('');
  const [poleNameStatus, setPoleNameStatus] = useState({
    status: '',
    feedback: '',
  });

  const [admName, setAdmName] = useState('');
  const [admNameStatus, setAdmNameStatus] = useState({
    status: '',
    feedback: '',
  });

  const [admEmail, setAdmEmail] = useState('');
  const [admEmailStatus, setAdmEmailStatus] = useState({
    status: '',
    feedback: '',
  });

  const [cpf, setCPF] = useState('');
  const [cpfStatus, setCPFStatus] = useState({ status: '', feedback: '' });

  const [rg, setRG] = useState('');
  const [rgStatus, setRGStatus] = useState({ status: '', feedback: '' });

  const [telefone, setTelefone] = useState('');
  const [telefoneStatus, setTelefoneStatus] = useState({ status: '', feedback: '' });

  const [celular, setCelular] = useState('');
  const [celularStatus, setCelularStatus] = useState({ status: '', feedback: '' });

  const [cnpj, setCNPJ] = useState('');
  const [cnpjStatus, setCnpjStatus] = useState({ status: '', feedback: '' });

  const [ie, setIE] = useState('');
  const [ieStatus, setIeStatus] = useState({ status: '', feedback: '' });

  const [nomeFantasia, setNomeFantasia] = useState('');
  const [nomeFantasiaStatus, setNomeFantasiaStatus] = useState({
    status: '',
    feedback: '',
  });

  const [razaoSocial, setRazaoSocial] = useState('');
  const [razaoSocialStatus, setRazaoSocialStatus] = useState({
    status: '',
    feedback: '',
  });

  const [openCollapseAddressMain, setOpenCollapseAddressMain] = useState(true);
  const [openCollapseAddressShipping, setOpenCollapseAddressShipping] = useState(false);
  const [openCollapseAddressPayment, setOpenCollapseAddressPayment] = useState(false);

  const [logradouroMain, setLogradouroMain] = useState('');
  const [logradouroMainStatus, setLogradouroMainStatus] = useState({
    status: '',
    feedback: '',
  });

  const [numberMain, setNumberMain] = useState('');
  const [numberMainStatus, setNumberMainStatus] = useState({
    status: '',
    feedback: '',
  });

  const [neighborhoodMain, setNeighborhoodMain] = useState('');
  const [neighborhoodMainStatus, setNeighborhoodMainStatus] = useState({
    status: '',
    feedback: '',
  });

  const [cityMain, setCityMain] = useState('');
  const [cityMainStatus, setCityMainStatus] = useState({
    status: '',
    feedback: '',
  });

  const [stateMain, setStateMain] = useState('');
  const [stateMainStatus, setStateMainStatus] = useState({
    status: '',
    feedback: '',
  });

  const [cepMain, setCepMain] = useState('');
  const [cepMainStatus, setCepMainStatus] = useState({
    status: '',
    feedback: '',
  });

  const [logradouroShipping, setLogradouroShipping] = useState('');
  const [logradouroShippingStatus, setLogradouroShippingStatus] = useState({
    status: '',
    feedback: '',
  });

  const [numberShipping, setNumberShipping] = useState('');
  const [numberShippingStatus, setNumberShippingStatus] = useState({
    status: '',
    feedback: '',
  });

  const [neighborhoodShipping, setNeighborhoodShipping] = useState('');
  const [neighborhoodShippingStatus, setNeighborhoodShippingStatus] = useState({
    status: '',
    feedback: '',
  });

  const [cityShipping, setCityShipping] = useState('');
  const [cityShippingStatus, setCityShippingStatus] = useState({
    status: '',
    feedback: '',
  });

  const [stateShipping, setStateShipping] = useState('');
  const [stateShippingStatus, setStateShippingStatus] = useState({
    status: '',
    feedback: '',
  });

  const [cepShipping, setCepShipping] = useState('');
  const [cepShippingStatus, setCepShippingStatus] = useState({
    status: '',
    feedback: '',
  });

  const [logradouroPayment, setLogradouroPayment] = useState('');
  const [logradouroPaymentStatus, setLogradouroPaymentStatus] = useState({
    status: '',
    feedback: '',
  });

  const [numberPayment, setNumberPayment] = useState('');
  const [numberPaymentStatus, setNumberPaymentStatus] = useState({
    status: '',
    feedback: '',
  });

  const [neighborhoodPayment, setNeighborhoodPayment] = useState('');
  const [neighborhoodPaymentStatus, setNeighborhoodPaymentStatus] = useState({
    status: '',
    feedback: '',
  });

  const [cityPayment, setCityPayment] = useState('');
  const [cityPaymentStatus, setCityPaymentStatus] = useState({
    status: '',
    feedback: '',
  });

  const [statePayment, setStatePayment] = useState('');
  const [statePaymentStatus, setStatePaymentStatus] = useState({
    status: '',
    feedback: '',
  });

  const [cepPayment, setCepPayment] = useState('');
  const [cepPaymentStatus, setCepPaymentStatus] = useState({
    status: '',
    feedback: '',
  });

  const [radioPerson, setRadioPerson] = useState([
    { label: translate('physicalPerson'), selected: false, type: 'F' },
    { label: translate('legalPerson'), selected: false, type: 'J' },
  ]);

  const [planSelected, setPlanSelected] = useState(null);

  useEffect(() => {
    dispatch(createdPole(false));
    dispatch(fetchFranchisorPlans());
  }, []);

  useEffect(() => {
    // console.log(radioPerson);
  }, [radioPerson]);

  useEffect(() => {
    if (plans.length > 0) {
      setInputPlans(
        plans.map((item) => ({
          label: `${item.nomePlano}`,
          value: String(item.idFranqueadoraPlano),
        }))
      );
    }
  }, [plans]);

  useEffect(() => {
    if (poleCreated === true) {
      setControlAnimation({ start: 60, end: 90, loop: false });
    }
  }, [poleCreated]);

  function handleCreatePole(e) {
    e.preventDefault();
    clearInputStatus();

    const typePersonSelected = radioPerson.filter((item) => item.selected === true);

    let formIsValid = true;

    // Verifica se marcou pessoa fisíca ou juridica
    if (typePersonSelected.length === 0) {
      dispatch(
        showAlert({
          type: 'danger',
          message: translate('selectedPearson'),
          time: 3000,
        })
      );
      formIsValid = false;
      return false;
    }

    // Verifica se marcou o nome do administrador
    if (admName.length <= 0) {
      formIsValid = false;
      setAdmNameStatus({ status: 'danger', feedback: translate('admNameStatus') });
    }

    // Verifica se o e-mail do administrador é valido
    if (!ValidaEmail(admEmail)) {
      formIsValid = false;
      setAdmEmailStatus({ status: 'danger', feedback: translate('invalidEmail') });
    }

    // Verifica se selecionou algum plano
    if (planSelected === null) {
      dispatch(
        showAlert({
          type: 'danger',
          message: translate('selectAPlan'),
          time: 3000,
        })
      );
      formIsValid = false;
      return false;
    }

    // Verifica se está marcando Pessoa Fisica ou Juridica e valida os campos
    if (typePersonSelected[0].type === 'F') {
      if (poleName.length <= 0) {
        formIsValid = false;
        setPoleNameStatus({ status: 'danger', feedback: translate('poleNameStatus') });
      }

      if (!hideFields) {
        if (razaoSocial.length <= 0) {
          formIsValid = false;
          setRazaoSocialStatus({ status: 'danger', feedback: translate('razaoSocialStatus') });
        }

        if (rg.length <= 0) {
          formIsValid = false;
          setRGStatus({ status: 'danger', feedback: translate('rgStatus') });
        }

        if (cpf.replace(/[.]|[-]|[ ]/g, '').length !== 11) {
          formIsValid = false;
          setCPFStatus({ status: 'danger', feedback: translate('cpfStatus') });
        }
      }
    } else {
      if (nomeFantasia.length <= 0) {
        formIsValid = false;
        setNomeFantasiaStatus({ status: 'danger', feedback: translate('nomeFantasiaStatus') });
      }

      if (!hideFields) {
        if (razaoSocial.length <= 0) {
          formIsValid = false;
          setRazaoSocialStatus({ status: 'danger', feedback: translate('razaoSocialStatus') });
        }

        if (cnpj.replace(/[.]|[-]|[ ]|[\/]/g, '').length !== 14) {
          formIsValid = false;
          setCnpjStatus({ status: 'danger', feedback: translate('cnpjStatus') });
        }

        if (ie.length <= 0) {
          formIsValid = false;
          setIeStatus({ status: 'danger', feedback: translate('ieStatus') });
        }
      }
    }

    if (!hideFields) {
      if (telefone.replace(/[.]|[-]|[ ]|[()]/g, '').length !== 10) {
        formIsValid = false;
        setTelefoneStatus({ status: 'danger', feedback: translate('telefoneStatus') });
      }

      if (celular.replace(/[.]|[-]|[ ]|[()]/g, '').length !== 11) {
        formIsValid = false;
        setCelularStatus({ status: 'danger', feedback: translate('celularStatus') });
      }
    }

    if (!hideFields) {
      if (logradouroMain.length <= 0 || logradouroMain.length > 60) {
        formIsValid = false;
        setLogradouroMainStatus({ status: 'danger', feedback: translate('logradouroStatus') });
      }

      if (numberMain.length <= 0 || numberMain.length > 5) {
        formIsValid = false;
        setNumberMainStatus({ status: 'danger', feedback: translate('numberStatus') });
      }

      if (neighborhoodMain.length <= 0 || neighborhoodMain.length > 30) {
        formIsValid = false;
        setNeighborhoodMainStatus({ status: 'danger', feedback: translate('neighborhoodStatus') });
      }

      if (cityMain.length <= 0) {
        formIsValid = false;
        setCityMainStatus({ status: 'danger', feedback: translate('cityStatus') });
      }

      if (stateMain.length <= 0) {
        formIsValid = false;
        setStateMainStatus({ status: 'danger', feedback: translate('stateStatus') });
      }

      if (cepMain.length <= 0 || cepMain.replace('-', '').replace('_', '').length < 8) {
        formIsValid = false;
        setCepMainStatus({ status: 'danger', feedback: translate('cepStatus') });
      }

      if (openCollapseAddressShipping === true) {
        if (logradouroShipping.length <= 0 || logradouroShipping.length > 60) {
          formIsValid = false;
          setLogradouroShippingStatus({
            status: 'danger',
            feedback: translate('logradouroStatus'),
          });
        }

        if (numberShipping.length <= 0 || numberShipping.length > 5) {
          formIsValid = false;
          setNumberShippingStatus({ status: 'danger', feedback: translate('numberStatus') });
        }

        if (neighborhoodShipping.length <= 0 || neighborhoodShipping.length > 30) {
          formIsValid = false;
          setNeighborhoodShippingStatus({
            status: 'danger',
            feedback: translate('neighborhoodStatus'),
          });
        }

        if (cityShipping.length <= 0) {
          formIsValid = false;
          setCityShippingStatus({ status: 'danger', feedback: translate('cityStatus') });
        }

        if (stateShipping.length <= 0) {
          formIsValid = false;
          setStateShippingStatus({ status: 'danger', feedback: translate('stateStatus') });
        }

        if (cepShipping.length <= 0 || cepShipping.replace('-', '').replace('_', '').length < 8) {
          formIsValid = false;
          setCepShippingStatus({ status: 'danger', feedback: translate('cepStatus') });
        }
      }

      if (openCollapseAddressPayment === true) {
        if (logradouroPayment.length <= 0 || logradouroPayment.length > 60) {
          formIsValid = false;
          setLogradouroPaymentStatus({ status: 'danger', feedback: translate('cepStatus') });
        }

        if (numberPayment.length <= 0 || numberPayment.length > 5) {
          formIsValid = false;
          setNumberPaymentStatus({ status: 'danger', feedback: translate('numberStatus') });
        }

        if (neighborhoodPayment.length <= 0 || neighborhoodPayment.length > 30) {
          formIsValid = false;
          setNeighborhoodPaymentStatus({
            status: 'danger',
            feedback: translate('neighborhoodStatus'),
          });
        }

        if (cityPayment.length <= 0) {
          formIsValid = false;
          setCityPaymentStatus({ status: 'danger', feedback: translate('cityStatus') });
        }

        if (statePayment.length <= 0) {
          formIsValid = false;
          setStatePaymentStatus({ status: 'danger', feedback: translate('stateStatus') });
        }

        if (cepPayment.length <= 0 || cepPayment.replace('-', '').replace('_', '').length < 8) {
          formIsValid = false;
          setCepPaymentStatus({ status: 'danger', feedback: translate('cepStatus') });
        }
      }
    }

    if (formIsValid) {
      const data = {
        IdFranqueadoraPlano: Number(planSelected.value),
        NomePolo: typePersonSelected[0].type === 'F' ? poleName : nomeFantasia,
        EmailAdministrador: admEmail,
        NomeAdministrador: admName,
        RazaoSocial: razaoSocial,
        CNPJ_CPF:
          typePersonSelected[0].type === 'F' ? cpf.replace(/[.]|[-]|[ ]/g, '') : cnpj.replace(/[.]|[-]|[ ]|[\/]/g, ''),
        IE_RG: typePersonSelected[0].type === 'F' ? rg.replace(/[.]|[-]|[ ]/g, '') : ie.replace(/[.]|[-]|[ ]/g, ''),
        IdPessoaTipo: typePersonSelected[0].type === 'F' ? 2 : 1,
        Telefone: telefone.replace(/[.]|[-]|[ ]|[()]/g, ''),
        Celular: celular.replace(/[.]|[-]|[ ]|[()]/g, ''),
        EnderecoPrincipal: {
          Logradouro: logradouroMain,
          Numero: numberMain,
          Bairro: neighborhoodMain,
          Cidade: cityMain,
          Estado: stateMain,
          CEP: cepMain.replace(/|[-]|[ ]|/g, ''),
        },
        EnderecoEntrega: {
          Logradouro: logradouroShipping,
          Numero: numberShipping,
          Bairro: neighborhoodShipping,
          Cidade: cityShipping,
          Estado: stateShipping,
          CEP: cepShipping,
          MesmoPrincipal: !openCollapseAddressShipping,
        },
        EnderecoCobranca: {
          Logradouro: logradouroPayment,
          Numero: numberPayment,
          Bairro: neighborhoodPayment,
          Cidade: cityPayment,
          Estado: statePayment,
          CEP: cepPayment,
          MesmoPrincipal: !openCollapseAddressPayment,
        },
      };
      dispatch(createPole(data));
    }
  }

  function handlerCreateAnother() {
    setControlAnimation({ start: 0, end: 57, loop: true });
    setPoleName('');
    setAdmName('');
    setAdmEmail('');
    setPlanSelected(null);
    setAdmEmailStatus({ status: '', feedback: '' });

    setNomeFantasia('');
    setRazaoSocial('');
    setCNPJ('');
    setIE('');
    setRG('');
    setCPF('');
    setTelefone('');
    setCelular('');

    setLogradouroMain('');
    setNumberMain('');
    setNeighborhoodMain('');
    setCityMain('');
    setStateMain('');
    setCepMain('');

    setLogradouroShipping('');
    setNumberShipping('');
    setNeighborhoodShipping('');
    setCityShipping('');
    setStateShipping('');
    setCepShipping('');

    setLogradouroPayment('');
    setNumberPayment('');
    setNeighborhoodPayment('');
    setCityPayment('');
    setStatePayment('');
    setCepPayment('');

    dispatch(createdPole(false));
  }

  function clearInputStatus() {
    setAdmNameStatus({ status: '', feedback: '' });
    setAdmEmailStatus({ status: '', feedback: '' });
    setPoleNameStatus({ status: '', feedback: '' });
    setRGStatus({ status: '', feedback: '' });
    setTelefoneStatus({ status: '', feedback: '' });
    setCelularStatus({ status: '', feedback: '' });
    setCPFStatus({ status: '', feedback: '' });
    setNomeFantasiaStatus({ status: '', feedback: '' });
    setRazaoSocialStatus({ status: '', feedback: '' });
    setCnpjStatus({ status: '', feedback: '' });
    setIeStatus({ status: '', feedback: '' });
    setLogradouroMainStatus({ status: '', feedback: '' });
    setNumberMainStatus({ status: '', feedback: '' });
    setNeighborhoodMainStatus({ status: '', feedback: '' });
    setCityMainStatus({ status: '', feedback: '' });
    setStateMainStatus({ status: '', feedback: '' });
    setCepMainStatus({ status: '', feedback: '' });
    setLogradouroShippingStatus({ status: '', feedback: '' });
    setNumberShippingStatus({ status: '', feedback: '' });
    setNeighborhoodShippingStatus({ status: '', feedback: '' });
    setCityShippingStatus({ status: '', feedback: '' });
    setStateShippingStatus({ status: '', feedback: '' });
    setCepShippingStatus({ status: '', feedback: '' });
    setLogradouroPaymentStatus({ status: '', feedback: '' });
    setNumberPaymentStatus({ status: '', feedback: '' });
    setNeighborhoodPaymentStatus({ status: '', feedback: '' });
    setCityPaymentStatus({ status: '', feedback: '' });
    setStatePaymentStatus({ status: '', feedback: '' });
    setCepPaymentStatus({ status: '', feedback: '' });
  }

  return (
    <>
      <Helmet>
        <title>Administração Franqueadora -{translate('newPole')}</title>
      </Helmet>
      <TitlePage title={translate('poleCreate')} />

      {polesIsLoading === true || poleCreated ? (
        <ContainerLoader>
          <Lottie
            options={{
              animationData: animacao,
              loop: controlAnimation.loop,
            }}
            height={150}
            forceSegments
            segments={[controlAnimation.start, controlAnimation.end]}
            width={150}
          />
          {poleCreated ? (
            <div className="container-succeso">
              <div>
                {translate('pole')} {poleName} {translate('successfullyCreated')}.
              </div>
              <button
                type="submit"
                className="btn btn-primary mt btn-sm"
                // disabled={vouchersIsLoading}
                onClick={(e) => handlerCreateAnother(e)}
              >
                {translate('createAnother')}
              </button>
            </div>
          ) : (
            <div>
              {translate('creatingPole')}
              ...
            </div>
          )}
        </ContainerLoader>
      ) : (
        <>
          <Card>
            <Radio items={radioPerson} click={(items) => setRadioPerson(items)} />
            {radioPerson.map((item, index) => {
              if (item.selected && item.type === 'F') {
                return !hideFields ? (
                  <>
                    <Row key={index}>
                      <Col xs={12} md={4}>
                        <Input
                          name="name-adm"
                          type="text"
                          label={translate('adminName')}
                          value={admName}
                          change={(value) => setAdmName(value)}
                          status={admNameStatus}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Input
                          name="email-adm"
                          type="text"
                          label={translate('adminEmail')}
                          value={admEmail}
                          change={(value) => setAdmEmail(value)}
                          status={admEmailStatus}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        {plans.length > 0 ? (
                          <SelectOne
                            options={inputPlans}
                            change={(selectItem) => setPlanSelected(selectItem)}
                            searchable={false}
                            label={translate('plan')}
                            placeholder={translate('selectAPlan')}
                          />
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <Input
                          name="pole-name"
                          type="text"
                          label={translate('poleName')}
                          value={poleName}
                          change={(value) => setPoleName(value)}
                          status={poleNameStatus}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={12}>
                        <Input
                          name="razaoSocial"
                          type="text"
                          label={translate("nomeCompletoDocumento")}
                          value={razaoSocial}
                          change={(value) => setRazaoSocial(value)}
                          status={razaoSocialStatus}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4}>
                        <Input
                          name="rg"
                          type="text"
                          label={translate('rg')}
                          value={rg}
                          change={(value) => setRG(value)}
                          status={rgStatus}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Input
                          name="cpf"
                          type="text"
                          label={translate('cpf')}
                          value={cpf}
                          change={(value) => setCPF(value)}
                          status={cpfStatus}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4}>
                        <Input
                          name="telefone"
                          type="text"
                          label={translate('telefone')}
                          value={telefone}
                          change={(value) => setTelefone(value)}
                          status={telefoneStatus}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Input
                          name="celular"
                          type="text"
                          label={translate('celular')}
                          value={celular}
                          change={(value) => setCelular(value)}
                          status={celularStatus}
                        />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row key={index}>
                      <Col xs={12} md={4}>
                        <Input
                          name="name-adm"
                          type="text"
                          label={translate('adminName')}
                          value={admName}
                          change={(value) => setAdmName(value)}
                          status={admNameStatus}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Input
                          name="email-adm"
                          type="text"
                          label={translate('adminEmail')}
                          value={admEmail}
                          change={(value) => setAdmEmail(value)}
                          status={admEmailStatus}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        {plans.length > 0 ? (
                          <SelectOne
                            options={inputPlans}
                            change={(selectItem) => setPlanSelected(selectItem)}
                            searchable={false}
                            label={translate('plan')}
                            placeholder={translate('selectAPlan')}
                          />
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <Input
                          name="pole-name"
                          type="text"
                          label={translate('poleName')}
                          value={poleName}
                          change={(value) => setPoleName(value)}
                          status={poleNameStatus}
                        />
                      </Col>
                    </Row>
                  </>
                );
              }
              if (item.selected && item.type === 'J') {
                return !hideFields ? (
                  <>
                    <Row>
                      <Col xs={12} md={4}>
                        <Input
                          name="name-adm"
                          type="text"
                          label={translate('adminName')}
                          value={admName}
                          change={(value) => setAdmName(value)}
                          status={admNameStatus}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Input
                          name="email-adm"
                          type="text"
                          label={translate('adminEmail')}
                          value={admEmail}
                          change={(value) => setAdmEmail(value)}
                          status={admEmailStatus}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        {plans.length > 0 ? (
                          <SelectOne
                            options={inputPlans}
                            change={(selectItem) => setPlanSelected(selectItem)}
                            searchable={false}
                            label={translate('plan')}
                            placeholder={translate('selectAPlan')}
                          />
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="nomeFantasia"
                          type="text"
                          label={translate('nomeFantasia')}
                          value={nomeFantasia}
                          change={(value) => setNomeFantasia(value)}
                          status={nomeFantasiaStatus}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name="razaoSocial"
                          type="text"
                          label={translate('razaoSocial')}
                          value={razaoSocial}
                          change={(value) => setRazaoSocial(value)}
                          status={razaoSocialStatus}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="cnpj"
                          type="text"
                          label={translate('cnpj')}
                          value={cnpj}
                          change={(value) => setCNPJ(value)}
                          status={cnpjStatus}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name="ie"
                          type="text"
                          label={translate('ie')}
                          value={ie}
                          change={(value) => setIE(value)}
                          status={ieStatus}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4}>
                        <Input
                          name="telefone"
                          type="text"
                          label={translate('telefone')}
                          value={telefone}
                          change={(value) => setTelefone(value)}
                          status={telefoneStatus}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Input
                          name="celular"
                          type="text"
                          label={translate('celular')}
                          value={celular}
                          change={(value) => setCelular(value)}
                          status={celularStatus}
                        />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col xs={12} md={4}>
                        <Input
                          name="name-adm"
                          type="text"
                          label={translate('adminName')}
                          value={admName}
                          change={(value) => setAdmName(value)}
                          status={admNameStatus}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Input
                          name="email-adm"
                          type="text"
                          label={translate('adminEmail')}
                          value={admEmail}
                          change={(value) => setAdmEmail(value)}
                          status={admEmailStatus}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        {plans.length > 0 ? (
                          <SelectOne
                            options={inputPlans}
                            change={(selectItem) => setPlanSelected(selectItem)}
                            searchable={false}
                            label={translate('plan')}
                            placeholder={translate('selectAPlan')}
                          />
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <Input
                          name="nomeFantasia"
                          type="text"
                          label={translate('nomeFantasia')}
                          value={nomeFantasia}
                          change={(value) => setNomeFantasia(value)}
                          status={nomeFantasiaStatus}
                        />
                      </Col>
                    </Row>
                  </>
                );
              }
              return null;
            })}
          </Card>
          {radioPerson.filter((item) => item.selected === true).length > 0 && !hideFields ? (
            <>
              <Card>
                <HeaderAdress onClick={() => setOpenCollapseAddressMain(true)}>
                  <div className="titulo">
                    {openCollapseAddressMain ? <RiCheckboxFill size={25} /> : <RiCheckboxBlankLine size={25} />}
                    <span>{translate('addressMain')}</span>
                  </div>
                  <div className="item-action">
                    <ArrowCollapse open={openCollapseAddressMain}>
                      <RiArrowDropDownLine size={30} />
                    </ArrowCollapse>
                  </div>
                </HeaderAdress>
                <CollapseItem
                  open={openCollapseAddressMain}
                  isOpen={openCollapseAddressMain}
                  transition="height 0.4s cubic-bezier(.4, 0, .2, 1)"
                >
                  <form name="formMainAdress" className="form">
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="logradouroMain"
                          type="text"
                          label={translate('address')}
                          value={logradouroMain}
                          change={(value) => setLogradouroMain(value)}
                          status={logradouroMainStatus}
                          maxLength={60}
                        />
                      </Col>
                      <Col xs={12} md={1}>
                        <Input
                          name="numberMain"
                          type="text"
                          label={translate('number')}
                          value={numberMain}
                          change={(value) => setNumberMain(value)}
                          status={numberMainStatus}
                          maxLength={5}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Input
                          name="neighborhoodMain"
                          type="text"
                          label={translate('neighborhood')}
                          value={neighborhoodMain}
                          change={(value) => setNeighborhoodMain(value)}
                          status={neighborhoodMainStatus}
                          maxLength={30}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={5}>
                        <Input
                          name="cityMain"
                          type="text"
                          label={translate('city')}
                          value={cityMain}
                          change={(value) => setCityMain(value)}
                          status={cityMainStatus}
                        />
                      </Col>
                      <Col xs={12} md={3}>
                        <Input
                          name="stateMain"
                          type="text"
                          label={translate('state')}
                          value={stateMain}
                          change={(value) => setStateMain(value)}
                          status={stateMainStatus}
                        />
                      </Col>
                      <Col xs={12} md={3}>
                        {user.Linguagem == 'pt_BR' ?
                          <InputCEP
                            name="stateMain"
                            type="text"
                            label={translate('cep')}
                            value={cepMain}
                            change={(value) => setCepMain(value)}
                            status={cepMainStatus}
                          />
                          :
                          <Input
                            name="stateMain"
                            type="text"
                            label={translate('cep')}
                            value={cepMain}
                            change={(value) => setCepMain(value)}
                            status={cepMainStatus}
                          />
                        }
                      </Col>
                    </Row>
                  </form>
                </CollapseItem>
              </Card>

              <Card>
                <HeaderAdress onClick={() => setOpenCollapseAddressShipping(!openCollapseAddressShipping)}>
                  <div className="titulo">
                    {openCollapseAddressShipping ? <RiCheckboxFill size={25} /> : <RiCheckboxBlankLine size={25} />}
                    <span>{translate('differentShippingAddress')}</span>
                  </div>
                  <div className="item-action">
                    <ArrowCollapse open={openCollapseAddressShipping}>
                      <RiArrowDropDownLine size={30} />
                    </ArrowCollapse>
                  </div>
                </HeaderAdress>
                <CollapseItem
                  open={openCollapseAddressShipping}
                  isOpen={openCollapseAddressShipping}
                  transition="height 0.4s cubic-bezier(.4, 0, .2, 1)"
                >
                  <form name="formShippingAdress" className="form">
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="logradouroShipping"
                          type="text"
                          label={translate('address')}
                          value={logradouroShipping}
                          change={(value) => setLogradouroShipping(value)}
                          status={logradouroShippingStatus}
                          maxLength={60}
                        />
                      </Col>
                      <Col xs={12} md={1}>
                        <Input
                          name="numberShipping"
                          type="text"
                          label={translate('number')}
                          value={numberShipping}
                          change={(value) => setNumberShipping(value)}
                          status={numberShippingStatus}
                          maxLength={5}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Input
                          name="neighborhoodShipping"
                          type="text"
                          label={translate('neighborhood')}
                          value={neighborhoodShipping}
                          change={(value) => setNeighborhoodShipping(value)}
                          status={neighborhoodShippingStatus}
                          maxLength={30}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={5}>
                        <Input
                          name="cityShipping"
                          type="text"
                          label={translate('city')}
                          value={cityShipping}
                          change={(value) => setCityShipping(value)}
                          status={cityShippingStatus}
                        />
                      </Col>
                      <Col xs={12} md={3}>
                        <Input
                          name="stateShipping"
                          type="text"
                          label={translate('state')}
                          value={stateShipping}
                          change={(value) => setStateShipping(value)}
                          status={stateShippingStatus}
                        />
                      </Col>
                      <Col xs={12} md={3}>
                        {user.Linguagem == 'pt_BR' ?
                          <InputCEP
                            name="stateShipping"
                            type="text"
                            label={translate('cep')}
                            value={cepShipping}
                            change={(value) => setCepShipping(value)}
                            status={cepShippingStatus}
                          />
                          :
                          <Input
                            name="stateShipping"
                            type="text"
                            label={translate('cep')}
                            value={cepShipping}
                            change={(value) => setCepShipping(value)}
                            status={cepShippingStatus}
                          />
                        }
                      </Col>
                    </Row>
                  </form>
                </CollapseItem>
              </Card>

              <Card>
                <HeaderAdress onClick={() => setOpenCollapseAddressPayment(!openCollapseAddressPayment)}>
                  <div className="titulo">
                    {openCollapseAddressPayment ? <RiCheckboxFill size={25} /> : <RiCheckboxBlankLine size={25} />}
                    <span>{translate('differentPaymentAddress')}</span>
                  </div>
                  <div className="item-action">
                    <ArrowCollapse open={openCollapseAddressPayment}>
                      <RiArrowDropDownLine size={30} />
                    </ArrowCollapse>
                  </div>
                </HeaderAdress>
                <CollapseItem
                  open={openCollapseAddressPayment}
                  isOpen={openCollapseAddressPayment}
                  transition="height 0.4s cubic-bezier(.4, 0, .2, 1)"
                >
                  <form name="formPaymentAdress" className="form">
                    <Row>
                      <Col xs={12} md={6}>
                        <Input
                          name="logradouroPayment"
                          type="text"
                          label={translate('address')}
                          value={logradouroPayment}
                          change={(value) => setLogradouroPayment(value)}
                          status={logradouroPaymentStatus}
                          maxLength={60}
                        />
                      </Col>
                      <Col xs={12} md={1}>
                        <Input
                          name="numberPayment"
                          type="text"
                          label={translate('number')}
                          value={numberPayment}
                          change={(value) => setNumberPayment(value)}
                          status={numberPaymentStatus}
                          maxLength={5}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <Input
                          name="neighborhoodPayment"
                          type="text"
                          label={translate('neighborhood')}
                          value={neighborhoodPayment}
                          change={(value) => setNeighborhoodPayment(value)}
                          status={neighborhoodPaymentStatus}
                          maxLength={30}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={5}>
                        <Input
                          name="cityPayment"
                          type="text"
                          label={translate('city')}
                          value={cityPayment}
                          change={(value) => setCityPayment(value)}
                          status={cityPaymentStatus}
                        />
                      </Col>
                      <Col xs={12} md={3}>
                        <Input
                          name="statePayment"
                          type="text"
                          label={translate('state')}
                          value={statePayment}
                          change={(value) => setStatePayment(value)}
                          status={statePaymentStatus}
                        />
                      </Col>
                      <Col xs={12} md={3}>
                        {user.Linguagem == 'pt_BR' ?
                          <InputCEP
                            name="statePayment"
                            type="text"
                            label={translate('cep')}
                            value={cepPayment}
                            change={(value) => setCepPayment(value)}
                            status={cepPaymentStatus}
                          />
                          :
                          <Input
                            name="statePayment"
                            type="text"
                            label={translate('cep')}
                            value={cepPayment}
                            change={(value) => setCepPayment(value)}
                            status={cepPaymentStatus}
                          />
                        }
                      </Col>
                    </Row>
                  </form>
                </CollapseItem>
              </Card>
            </>
          ) : null}
          <button type="button" className="btn btn-primary" disabled={false} onClick={(e) => handleCreatePole(e)}>
            {translate('poleCreate')}
          </button>
        </>
      )}
    </>
  );
}

export default PoleCreate;
