import {
    VOUCHER_SET,
    VOUCHER_LOADING,
    VOUCHER_LOADED,
    VOUCHER_FILTER_SET,
    VOUCHER_CLEAR,
} from '../actionTypes';

import { translate } from '@/i18n/src/locales';

const initialState = {
    vouchers: [],
    voucherIsLoading: false,
    status: [
        { idStatus: 0, description: translate('all'), selected: true },
        { idStatus: 1, description: translate('available'), selected: false },
        { idStatus: 2, description: translate('inUse'), selected: false },
        { idStatus: 3, description: translate('consumed'), selected: false },
    ],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case VOUCHER_SET:
            return {
                ...state,
                vouchers: action.payload,
            };
        case VOUCHER_LOADING:
            return {
                ...state,
                voucherIsLoading: true,
            };
        case VOUCHER_LOADED:
            return {
                ...state,
                voucherIsLoading: false,
            };
        case VOUCHER_FILTER_SET:
            return {
                ...state,
                status: action.payload,
            };
        case VOUCHER_CLEAR:
            return {
                ...state,
                vouchers: [],
                voucherIsLoading: false,
                status: [
                    { idStatus: 0, description: translate('all'), selected: true },
                    { idStatus: 1, description: translate('available'), selected: false },
                    { idStatus: 2, description: translate('inUse'), selected: false },
                    { idStatus: 3, description: translate('consumed'), selected: false },
                ],
            };
        default:
            return state;
    }
};

export default reducer;
