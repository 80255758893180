import {

    PAYMENTPLANS_SET,
    PAYMENTPLAN_EDIT_SET,
    PAYMENTPLAN_LOADING,
    PAYMENTPLAN_LOADED,
    PAYMENTPLAN_CLEAR,
    PAYMENTPLAN_ID_EDIT_SET,
} from '../actionTypes';

const initialState = {
    paymentPlans: [],
    paymentPlanEdit: null,
    paymentPlanIdEdit: null,
    paymentPlanIsLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENTPLANS_SET:
            return {
                ...state,
                paymentPlans: action.payload,
            };
        case PAYMENTPLAN_EDIT_SET:
            return {
                ...state,
                paymentPlanEdit: action.payload,
            };
        case PAYMENTPLAN_ID_EDIT_SET:
            return {
                ...state,
                paymentPlanIdEdit: action.payload,
            };
        case PAYMENTPLAN_LOADING:
            return {
                ...state,
                paymentPlanIsLoading: true,
            };
        case PAYMENTPLAN_LOADED:
            return {
                ...state,
                paymentPlanIsLoading: false,
            };
        case PAYMENTPLAN_CLEAR:
            return {
                ...state,
                paymentPlans: [],
                paymentPlanIsLoading: false,
            };
        default:
            return state;
    }
};

export default reducer;
