import { apiLoja } from '@/services/api';
import { COUPON_TYPE_APPLICATION_SET, COUPON_TYPE_APPLICATION_LOADING, COUPON_TYPE_APPLICATION_LOADED } from '../actionTypes';

export const setCouponTypeApplication = (typesCoupon) => ({
    type: COUPON_TYPE_APPLICATION_SET,
    payload: typesCoupon,
});

export const loadingCouponTypeApplication = () => ({
    type: COUPON_TYPE_APPLICATION_LOADING,
});

export const loadedCouponTypeApplication = () => ({
    type: COUPON_TYPE_APPLICATION_LOADED,
});

export const fetchCouponTypeApplication = () => {
    return (dispatch) => {
        dispatch(loadingCouponTypeApplication());

        apiLoja
            .get(`/CupomTipoAplicacao`)
            .then((data) => {
                dispatch(setCouponTypeApplication(data.data));
                dispatch(loadedCouponTypeApplication());
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log('error comming', error);
            });
    };
};
