import React from 'react';

import { Container, ContainerTextoButton, Button } from './styles';

function TitlePage(props) {
    if (props.link) {
        return (
            <ContainerTextoButton>
                <div className="texto">
                    {props.title}
                    {props.subtitle}
                </div>
                <Button className="btn btn-primary button" to={props.link}>
                    {props.txtButton}
                </Button>
            </ContainerTextoButton>
        );
    } else {
        return (
            <Container>
                {props.title}
                {props.subtitle}
            </Container>
        );
    }
}

export default TitlePage;
