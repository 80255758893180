import { apiLoja } from '@/services/api';
import {
    CONFIGURATIONPLAN_TYPES_SET,
    CONFIGURATIONPLAN_TYPES_LOADING,
    CONFIGURATIONPLAN_TYPES_LOADED
} from '../actionTypes';

export const setConfiguratioPlanTypes = (configurationTypes) => ({
    type: CONFIGURATIONPLAN_TYPES_SET,
    payload: configurationTypes,
});

export const loadingConfigurationPlanTypes = () => ({
    type: CONFIGURATIONPLAN_TYPES_LOADING,
});

export const loadedConfigurationPlanTypes = () => ({
    type: CONFIGURATIONPLAN_TYPES_LOADED,
});

export const fetchAllConfigurationPlanTypes = () => {
    return (dispatch) => {
        dispatch(loadingConfigurationPlanTypes());

        apiLoja
            .get(`/PlanoPagamentoTipo`)
            .then((data) => {
                dispatch(setConfiguratioPlanTypes(data.data));
                dispatch(loadedConfigurationPlanTypes());
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log('error comming', error);
            });
    };
};