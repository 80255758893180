import React, { useMemo } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { Table, Container } from './styles';

const FilterComponent = ({ filterText, onFilter, onClear, searchPlaceHolder }) => (
  <>
    <input id="search" type="text" placeholder={searchPlaceHolder} value={filterText} onChange={onFilter} />
    {filterText.length > 0 ? (
      <div className="limpa-buscar" onClick={onClear}>
        <RiCloseLine size={35} />
      </div>
    ) : null}
  </>
);

const DataTable = ({ data, columns, columnFilter, searchPlaceHolder }) => {
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');
  const filteredItems = data.filter(
    (item) => item[columnFilter] && item[columnFilter].toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        searchPlaceHolder={searchPlaceHolder}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Container>
      <Table
        data={filteredItems}
        subHeader={true}
        noHeader={true}
        subHeaderComponent={subHeaderComponentMemo}
        pagination
        theme="solarized"
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        columns={columns}
      />
    </Container>
  );
};

export default DataTable;
