import {

    CONFIGURATIONPLANS_SET,
    CONFIGURATIONPLAN_EDIT_SET,
    CONFIGURATIONPLAN_LOADING,
    CONFIGURATIONPLAN_LOADED,
    CONFIGURATIONPLAN_CLEAR,
    CONFIGURATIONPLAN_ID_EDIT_SET,

    PAYMENT_PLAN_CONFIGURATION_SET,
    PAYMENT_PLAN_CONFIGURATION_LOADING,
    PAYMENT_PLAN_CONFIGURATION_LOADED
} from '../actionTypes';

const initialState = {
    configurationPlans: [],
    configurationPlanEdit: null,
    configurationPlanIdEdit: null,
    configurationPlanIsLoading: false,
    configurationPlansPayment: [],
    ConfigurationPlanIsLoading: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CONFIGURATIONPLANS_SET:
            return {
                ...state,
                configurationPlans: action.payload,
            };
        case CONFIGURATIONPLAN_EDIT_SET:
            return {
                ...state,
                configurationPlanEdit: action.payload,
            };
        case CONFIGURATIONPLAN_ID_EDIT_SET:
            return {
                ...state,
                configurationPlanIdEdit: action.payload,
            };
        case CONFIGURATIONPLAN_LOADING:
            return {
                ...state,
                configurationPlanIsLoading: true,
            };
        case CONFIGURATIONPLAN_LOADED:
            return {
                ...state,
                configurationPlanIsLoading: false,
            };
        case CONFIGURATIONPLAN_CLEAR:
            return {
                ...state,
                configurationPlans: [],
                configurationPlanIsLoading: false,
            };

        case PAYMENT_PLAN_CONFIGURATION_SET:
            return {
                ...state,
                configurationPlansPayment: action.payload.plansConfiguration,
                isConfigurationPlanOnPaymentPlan: action.payload.isConfigurationPlanOnPaymentPlan,
            };
        case PAYMENT_PLAN_CONFIGURATION_LOADING:
            return {
                ...state,
                ConfigurationPlanIsLoading: true,
            };
        case PAYMENT_PLAN_CONFIGURATION_LOADED:
            return {
                ...state,
                ConfigurationPlanIsLoading: false,
            };
        default:
            return state;
    }
};

export default reducer;
