import React from 'react';

import CurrencyInput from 'react-currency-input-field';
import { Container, Label } from './styles';

function Input({ name, value, label, status, change }) {
  return (
    <Container status={status.status}>
      <Label status={status.status}>{label}</Label>
      <CurrencyInput
        suffix="%"
        id="input-example"
        name={name}
        value={value}
        allowNegativeValue={false}
        disableGroupSeparators
        onValueChange={(v) => change(v)}
        onChange={(e) => {
          var value = parseInt(e.target.value, 10);

          if (value > 100) value = 100;
          if (String(value) == "NaN") value = '';

          change(value);
        }}
      />
      <div className="feedback">{status.feedback}</div>
    </Container>
  );
}

export default Input;
