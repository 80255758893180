import styled from 'styled-components';
import { colors, metrics } from '@/template/styles';
import Collapse from '@kunukn/react-collapse';

export const ContainerUpload = styled.div`
  display: flex;
  background: ${colors.light};
  max-width: 35rem;
  width: 100%;
  height: 32.6rem;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
`;

export const Img = styled.img`
  width: 40%;
  padding: ${metrics.space};
`;

export const ContentImgPreview = styled.div`
  position: relative;
  width: 35rem;
  height: 32.6rem;

  .remove-image {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 5rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    cursor: pointer;
  }

  transition: all 2s;

  .remove-image:hover {
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.25), 0 0px 0px rgba(0, 0, 0, 0.22);
  }
`;

export const ImgPreview = styled.img`
  width: 35rem;
  height: 32.6rem;
  border-radius: 0.5rem;
`;

export const CollapseItem = styled.div`
  background: ${colors.white};
  padding: 2rem;
  border-radius: 5px;
  margin-bottom: ${metrics.space};

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    font-weight: bold;
    cursor: pointer;
  }

  .header .title {
    display: flex;
    align-items: center;
  }

  .header .title svg {
    margin-right: 1rem;
  }
`;

export const CollapseBody = styled(Collapse)`
  margin-top: ${(props) => {
    if (props.isOpen) {
      return `2rem`;
    }
    return `0rem`;
  }};

  transition: margin-top ease 0.2s;

  .check-trail-module {
    cursor: pointer;
  }
`;

export const ArrowCollapse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props) => {
    if (props.open) {
      return `rotate(180deg)`;
    }
    return `rotate(0deg)`;
  }};

  transition: all 0.2s;
`;

export const Label = styled.div`
  position: absolute;
  left: 10px;
  background: #fff;
  top: -9px;
  padding: 0px 10px;
  color: ${(props) => {
    if (props.status === 'success') {
      return `${colors.success} !important`;
    }
    if (props.status === 'danger') {
      return `${colors.danger} !important`;
    }
    return '#000';
  }};
`;


export const Container = styled.div`
  position: relative;
  margin-bottom: 1.5rem;

  input {
    transition: all 0.2;
    border-color: ${(props) => {
    if (props.status === 'success') {
      return `${colors.success} !important`;
    }
    if (props.status === 'danger') {
      return `${colors.danger} !important`;
    }
    return '#dcdce6';
  }};
    color: ${(props) => {
    if (props.status === 'success') {
      return `${colors.success} !important`;
    }
    if (props.status === 'danger') {
      return `${colors.danger} !important`;
    }
    return '#000';
  }};
  }

  input:focus {
    transition: all 0.2;
    border-color: ${colors.blue};
    border-width: 0.2rem;
  }

  .feedback {
    margin-top: 2px;
    font-size: 1.2rem;
    color: ${(props) => {
    if (props.status === 'success') {
      return `${colors.success} !important`;
    }
    if (props.status === 'danger') {
      return `${colors.danger} !important`;
    }
    return '#000';
  }};
  }
`;