// pt-BR.js
export default {
  signIn: 'Entrar',
  user: 'Usuário',
  password: 'Senha',
  start: 'Inicio',
  revenues: 'Faturamento',
  financial: 'Financeiro',
  store: 'Loja',
  vouchers: 'Vouchers',
  voucherCreate: 'Criar Voucher',
  informAmount: 'Informe a Quantidade de 1 até 25',
  sales: 'Vendas',
  pole: 'Polo',
  poles: 'Polos',
  poleCreate: 'Criar Polo',
  poleName: 'Nome Polo',
  createAnother: 'Criar outro polo',
  invalidUserOrPassword: 'Usuário ou senha inválido',
  hello: 'Olá',
  welcomeToYourAdministration: 'Bem vindo a sua administração.',
  logout: 'Logout',
  selectAUnit: 'Selecione a unidade',
  plan: 'Plano',
  value: 'Valor',
  discount: 'Desconto',
  totalItemValue: 'Valor total item',
  total: 'Total',
  retroactive: 'Retroativo',
  referenceMounth: 'Mês Referência',
  studentName: 'Nome Aluno',
  moduleTitle: 'Título Módulo',
  class: 'Aula',
  billable: 'Faturável',
  registrationDate: 'Data Registro',
  billed: 'Faturado',
  unbilled: 'Não Faturado',
  selectAProduct: 'Selecione um produto',
  selectAPlan: 'Selecione um plano',
  all: 'Todos',
  available: 'Disponível',
  inUse: 'Em Uso',
  consumed: 'Consumido',
  product: 'Produto',
  products: 'Produtos',
  productCreate: 'Criar Produto',
  productEdit: 'Editar Produto',
  last7Days: 'Últimos 7 dias',
  last30Days: 'Últimos 30 dias',
  period: 'Período',
  status: 'Status',
  registerDate: 'Data de Cadastro',
  expiredOn: 'Expirado Em',
  student: 'Aluno',
  saleDate: 'Data da venda',
  typeOfSale: 'Tipo venda',
  dueDate: 'Vencimento',
  paymentStatus: 'Status Pagamento',
  formOfPayment: 'Forma de Pagamento',
  installments: 'Parcelas',
  installmentValue: 'Valor parcela',
  quantity: 'Quantidade',
  interest: 'Juros',
  dispatch: 'Frete',
  totalPaid: 'Total pago',
  externalCode: 'Código externo',
  searchPoleName: 'Buscar nome do polo',
  adminName: 'Nome administrador',
  adminEmail: 'Email administrador',
  invalidEmail: 'Email inválido',
  creatingPole: 'Criando polo',
  successfullyCreated: 'Criado com sucesso',
  hash: 'Hash',
  genaratedDate: 'Data Gerado',
  signature: 'Assinatura',
  trail: 'Trilha',
  course: 'Curso',
  enrollmentDate: 'Data Matrícula',
  rg: 'RG',
  cpf: 'CPF',
  address: 'Endereço',
  number: 'Número',
  complement: 'Complemento',
  neighborhood: 'Bairro',
  city: 'Cidade',
  state: 'Estado',
  legalPerson: 'Pessoa Juridíca',
  physicalPerson: 'Pessoa Fisíca',
  selectedPearson: 'Selecione Pessoa Física ou Jurídica',
  admNameStatus: 'Informe o nome do administrador do polo',
  poleNameStatus: 'Informe o nome do polo',
  rgStatus: 'Informe o RG',
  cpfStatus: 'Informe o CFP',
  telefoneStatus: 'Informe o Telefone com DDD',
  celularStatus: 'Informe o Celular com DDD',
  nomeFantasiaStatus: 'Informe o nome fantasia',
  razaoSocialStatus: 'Informe a razão social',
  cnpjStatus: 'Informe o CNPJ',
  ieStatus: 'Informe o IE',
  logradouroStatus: 'Informe o endereço',
  numberStatus: 'Informe o número',
  complementoStatus: 'Informe o complemento',
  neighborhoodStatus: 'Informe o bairro',
  cityStatus: 'Informe a cidade',
  stateStatus: 'Informe o estado',
  cepStatus: 'Informe o CEP',
  nomeFantasia: 'Nome Fantasia',
  razaoSocial: 'Razão Social',
  cnpj: 'CNPJ',
  ie: 'IE',
  cep: 'CEP',
  differentPaymentAddress: 'Endereço de Pagamento Diferente?',
  differentShippingAddress: 'Endereço de Entrega Diferente?',
  addressMain: 'Endereço Principal',
  telefone: 'Telefone',
  celular: 'Celular',
  franchisor: 'Franqueadora',
  franchisors: 'Franqueadoras',
  franchisorCreate: 'Criar Franqueadora',
  franchisorEdit: 'Editar Franqueadora',
  coupons: 'Cupons',
  couponCreate: 'Criar Cupom',
  searchCouponName: 'Buscar Cupom',
  couponName: 'Nome Cupom',
  active: 'Ativo',
  inactive: 'Inativo',
  type: 'Tipo',
  startDate: 'Data Inicial',
  language: 'PT-br',
  typeCoupom: 'Tipo do Cupom',
  activeCoupon: 'Cupom Ativo',
  selectAType: 'Selecione um tipo',
  couponEdit: 'Editar Cupom',
  couponExists: 'O cupom já existe',
  validateDate: 'Data de vencimento inválida',
  couponAdd: 'Cupom adicionado com sucesso!',
  couponEdited: 'Cupom alterado com sucesso!',
  validatePercentage: 'Porcentagem acima de 100%',
  sections: 'Seções',
  sectionCreate: 'Criar Seção',
  sectionEdit: 'Editar seção',
  searchSection: 'Buscar seção',
  searchPaymentPlan: 'Buscar Plano Pagamento',
  searchProduct: 'Buscar Produto',
  havePaymentPlan: 'Seção possui plano de pagamento?',
  haveProduct: 'Seção possui produto?',
  dropFile: 'Solte o arquivo aqui...',
  chooseImageSection: 'Escolha uma imagem para a Seção, tamanho recomendado 350x326px',
  BadRequestCoupon: 'Alterações não realidas no Cupom',
  alertPlanAtSection: 'Só é permitido um plano de pagamento por Seção',
  changePlanSectionMessageSucess: 'Plano alterado com sucesso',
  changePlanSectionMessageError: 'Não foi possível alterar o plano',
  SectionProductUpdateMessage: 'Produto atualizado na seção com sucesso',
  chooseImageSection: 'Escolha uma imagem para a Seção, tamanho recomendado 350x326px',
  nomeCompletoDocumento: 'Nome Completo (Documento)',

  paymentPlans: 'Planos de Pagamento',
  paymentPlanCreate: 'Criar Plano de Pagamento',
  searchPaymentPlanName: 'Buscar Plano de pagamento',
  paymentPlanName: 'Nome Plano de pagamento',
  paymentPlanEdit: 'Editar Plano de pagamento',
  paymentPlanEdited: 'Plano de pagamento alterado com sucesso!',
  paymentPlanAdd: 'Plano de pagamento adicionado com sucesso!',


  configurationPlans: 'Planos de Configuração',
  configurationPlanCreate: 'Criar Plano de Configuração',
  searchConfigurationPlansName: 'Buscar Plano de Configuração',
  configurationPlansType: 'Tipo Plano Pagamento',
  description: 'Descrição',
  qtyTimes: 'QtdVezes',
  entryPercentage: 'Entrada %',
  discountPercentage: 'Desconto %',
  interestPercentage: 'Juros %',
  main: 'Principal',
  emphasis: 'Destaque',
  installmentLimitValue: 'Valor Limite Parcela',

  selectCouponTypeApplication: 'Selecione o tipo de aplicação do cupom',
  firstPurchase: 'Primeira Compra',
};
