import styled, { createGlobalStyle } from 'styled-components';
import { metrics } from '.';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      outline: 0;
    }
    html,
    body,
    #root {
      height: 100%;
    }
    html{
      font-size: 62.5%;
    }
    body {
      text-rendering: optimizeLegibility !important;
      font: 400 1.4rem Roboto, sans-serif;
      background: #f0f0f5;
      -webkit-font-smoothing: antialiased;
    }

    input,
button,
textarea {
  font: 400 1.6rem Roboto, sans-serif;
}

input, select {
  width: 100%;
  height: 50px;
  color: #4b515d;
  border: 1px solid;
  border-color:#dcdce6;
  border-radius: 5px;
  padding-left: 15px;
}

.input-sm{
    height: 30px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #d2d2d2;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d2d2d2;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d2d2d2;
}

button {
  cursor: pointer;
}


table {
    width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
}

th {
    padding: 2rem 1rem;
    border-top: 3px solid #e9eaeb;
    border-bottom: 3px solid #e9eaeb;
    text-align:left;
}

td {
    border-bottom: 1px solid #d3d3d3;
    padding: 1rem 1rem;
}

.btn {
  width: 100%;
  height: 50px;
  border: 0;
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  font-size: 1.8rem;
  line-height: 50px;
  transition: filter 0.2s;
  cursor:pointer;
}

.btn-sm {
  height: 30px;
  line-height: 30px;
  font-size: 1.5rem;
  transition: filter 0.2s;
  cursor:pointer;
}

.btn:hover {
  filter: brightness(85%);
}

.btn-primary {
  background-color: #17c671;
  color: #fff;
}

.mt {
  margin-top: ${metrics.space};
}

.mb {
  margin-bottom: ${metrics.space};
}

.ml {
  margin-left: ${metrics.space};
}

.mr {
  margin-right:${metrics.space};
}

.rc-input-number{
    height:50px !important;
}

.rc-input-number-handler {
    height: 25px !important;
    cursor:pointer !important;
}

.rc-input-number-input{
    padding-left: 10px !important;
}

    @media(max-width:450px){
        html{
            font-size: 40% !important;
        }
    }
`;
