import React from 'react';

import { useSelector } from 'react-redux';

import Sidebar from '@/components/Sidebar';
import Header from '@/components/Header';
import Alert from '@/components/Alert';
import { Container } from '@/template/styles/stylesGlobal';

import { Content, Wrapper } from './styles';

const Main = ({ children }) => {
  const sideBarOpen = useSelector((state) => state.theme.sideBarOpen);

  return (
    <Container>
      <Sidebar />
      <Content sideBarOpen={sideBarOpen}>
        <Header />
        <Alert />
        <Wrapper>{children}</Wrapper>
      </Content>
    </Container>
  );
};

export default Main;
