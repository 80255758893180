import React, { useState, useEffect } from 'react';

import TitlePage from '@/components/TitlePage';
import Card from '@/components/Card';

import { translate } from '@/i18n/src/locales';
import { Helmet } from 'react-helmet';
import SelectOne from '@/components/Forms/SelectOne';
import ContentLoader from 'react-content-loader';
import { useSelector, useDispatch } from 'react-redux';

import { fetchPoles } from '@/store/actions/pole';
import { fetchProduct, clearProduct } from '@/store/actions/product';
import { createVoucher, clearVoucher } from '@/store/actions/voucher';
import { BoxForm, HeaderVoucher, ContainerLoader, InputNumberQty } from './styles';

function VoucherCreate() {
  const poles = useSelector((state) => state.pole.poles);
  const polesIsLoading = useSelector((state) => state.pole.polesIsLoading);

  const products = useSelector((state) => state.product.products);
  const productIsLoading = useSelector((state) => state.product.productIsLoading);

  const vouchers = useSelector((state) => state.voucher.vouchers);
  const vouchersIsLoading = useSelector((state) => state.voucher.voucherIsLoading);

  const dispatch = useDispatch();

  const [inputPoles, setInputPoles] = useState(null);
  const [inputProducts, setInputProducts] = useState(null);
  const [idPole, setIdPole] = useState(null);
  const [idProduct, setIdProduct] = useState(null);
  const [qty, setQty] = useState(null);

  useEffect(() => {
    dispatch(clearVoucher());
    dispatch(clearProduct());
    dispatch(fetchPoles());
  }, []);

  useEffect(() => {
    if (poles.length > 0) {
      setInputPoles(
        poles.map((item) => ({
          label: `${item.codigoExternoPolo} - ${item.nomePolo}`,
          value: String(item.idPolo),
        }))
      );
    }
  }, [poles]);

  useEffect(() => {
    if (products.length > 0) {
      setInputProducts(
        products.map((item) => ({
          label: `${item.IdProduto} - ${item.Nome}`,
          value: String(item.IdProduto),
        }))
      );
    }
  }, [products]);

  function handleChangePolo(item) {
    setIdPole(item.value);
    setIdProduct(null);
    dispatch(clearProduct());
    dispatch(fetchProduct(item.value));
  }

  function handleChangeProduct(item) {
    setIdProduct(item.value);
  }

  function handleCreateVoucher() {
    dispatch(createVoucher(idPole, idProduct, qty));
  }

  return (
    <>
      <Helmet>
        <title>Administração Franqueadora - {translate('voucherCreate')}</title>
      </Helmet>
      <TitlePage title={translate('voucherCreate')} />
      <Card>
        <BoxForm>
          {polesIsLoading ? (
            <ContentLoader width="100%" speed={1} height="40px">
              <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="40px" />
            </ContentLoader>
          ) : (
            <SelectOne
              options={inputPoles}
              change={(item) => handleChangePolo(item)}
              searchable
              placeholder={translate('selectAUnit')}
            />
          )}
        </BoxForm>
        <BoxForm className="mt">
          {productIsLoading ? (
            <ContentLoader width="100%" speed={1} height="40px">
              <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="40px" />
            </ContentLoader>
          ) : (
            <div>
              {products.length > 0 ? (
                <SelectOne
                  options={inputProducts}
                  change={(item) => handleChangeProduct(item)}
                  searchable
                  placeholder={translate('selectAProduct')}
                />
              ) : null}
            </div>
          )}
        </BoxForm>
        <BoxForm className="mt">
          {idPole && idProduct ? (
            <InputNumberQty
              value={qty}
              min={0}
              max={25}
              placeholder={translate('informAmount')}
              onChange={(value) => setQty(value)}
            />
          ) : null}
        </BoxForm>
        <BoxForm className="mt">
          {idPole && idProduct && qty > 0 ? (
            <button
              type="button"
              className="btn btn-primary"
              disabled={vouchersIsLoading}
              onClick={() => handleCreateVoucher()}
            >
              {translate('voucherCreate')}
            </button>
          ) : null}
        </BoxForm>
      </Card>

      {vouchersIsLoading ? (
        <ContainerLoader>
          <ContentLoader
            width="100%"
            speed={1}
            height="280px"
            backgroundColor="#c6c6c6"
            foregroundColor="#F0F0F5"
          >
            <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="80px" />
            <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="80px" />
            <rect x="0" y="200" rx="5px" ry="5px" width="100%" height="80px" />
          </ContentLoader>
        </ContainerLoader>
      ) : (
        <>
          {vouchers.length > 0
            ? vouchers.map((item) => <ItemVoucher key={item.Hash} item={item} />)
            : null}
        </>
      )}
    </>
  );
}

const ItemVoucher = ({ item }) => {
  let htmlStatus = '';

  if (item.StatusVoucher === 1) {
    htmlStatus = (
      <div className="status-voucher">
        <div className="indicator success" />
        Disponível
      </div>
    );
  } else if (item.StatusVoucher === 2) {
    htmlStatus = (
      <div className="status-voucher">
        <div className="indicator warning" />
        Em Uso
      </div>
    );
  } else if (item.StatusVoucher === 3) {
    htmlStatus = (
      <div className="status-voucher">
        <div className="indicator danger" />
        Consumido
      </div>
    );
  }

  return (
    <>
      <HeaderVoucher>
        <div className="item">
          <div className="hash">{item.Hash}</div>
          <div>{item.TipoAssinatura ? item.TipoAssinatura.NomeTipo : null}</div>
        </div>
        <div className="item">
          <div className="titulo">{translate('product')}</div>
          <div>{item.Produto.Nome}</div>
        </div>
        <div className="item" />
        <div className="item">
          <div className="titulo">{translate('status')}</div>
          {htmlStatus}
        </div>
        <div className="item" />
      </HeaderVoucher>
    </>
  );
};

export default VoucherCreate;
