import React from 'react';
import Select from 'react-select';
import { Container, Label } from './styles';

function SelectOne({ options, change, placeholder, searchable, label, value }) {
  const customStyles = {
    input: (provided, state) => ({
      height: 45,
    }),
  };

  return (
    <Container>
      <Label>{label}</Label>
      <Select
        value={value}
        options={options}
        onChange={(item) => change(item)}
        search={searchable}
        styles={customStyles}
        placeholder={placeholder}
      />
    </Container>
  );
}

export default SelectOne;
