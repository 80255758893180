import { apiLoja } from '@/services/api';
import { COUPON_TYPES_SET, COUPON_TYPES_LOADING, COUPON_TYPES_LOADED } from '../actionTypes';

export const setCouponTypes = (typesCoupon) => ({
    type: COUPON_TYPES_SET,
    payload: typesCoupon,
});

export const loadingCouponTypes = () => ({
    type: COUPON_TYPES_LOADING,
});

export const loadedCouponTypes = () => ({
    type: COUPON_TYPES_LOADED,
});

export const fetchCouponTypes = () => {
    return (dispatch) => {
        dispatch(loadingCouponTypes());

        apiLoja
            .get(`/CupomTipo`)
            .then((data) => {
                dispatch(setCouponTypes(data.data));
                dispatch(loadedCouponTypes());
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log('error comming', error);
            });
    };
};
