import React from 'react';

import TitlePage from '@/components/TitlePage';
import Card from '@/components/Card';

import { useSelector } from 'react-redux';

import { translate } from '@/i18n/src/locales';
import { Helmet } from 'react-helmet';
import IsometricImg from '@/assets/images/isometrico-inicial.svg';

import { Img, Row } from './styles';

const Start = () => {
  const user = useSelector((state) => state.user.user);

  return (
    <>
      <Helmet>
        <title>Administração Franqueadora - {translate('start')}</title>
      </Helmet>
      <TitlePage title={translate('start')} />
      <Card>
        <Row>
          <div className="img-start">
            <Img src={IsometricImg} />
          </div>
          <div className="box-name">
            <div className="name">
              {translate('hello')}, {user.NomeCompleto}
            </div>
            <div className="description">{translate('welcomeToYourAdministration')}.</div>
          </div>
        </Row>
      </Card>
    </>
  );
};

export default Start;
