/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'react-flexbox-grid';
import { useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { RiArrowDropDownLine, RiCheckboxBlankLine, RiCheckboxFill, RiDeleteBin2Line } from 'react-icons/ri';

import DataTable from '@/components/DataTable';
import Badge from '@/components/Badge';
import SelectOne from '@/components/Forms/SelectOne';
import ContentLoader from 'react-content-loader';
import { translate } from '@/i18n/src/locales';
import TitlePage from '@/components/TitlePage';
import Switcher from '@/components/Forms/Switcher';
import InputMoney from '@/components/Forms/InputMoney';
import InputPercentage from '@/components/Forms/InputPercentage';
import Input from '@/components/Forms/Input';
import FormatDate from '@/components/Forms/FormatDate';

import Card from '@/components/Card';
import Check from '@/components/Forms/Check';
import { colors } from '@/template/styles';
import { toBase64 } from '@/utils/ConvertBase64';
import {
    fetchAllPaymentPlan,
    fetchPaymentPlanById,
    addPaymentPlan,
    editPaymentPlan
}
    from '@/store/actions/paymentPlan';

import {
    fetchAllPlanConfigurationPlanLinked,
    addConfigurationPlanDetails,
    removeConfigurationPlanDetails
} from '@/store/actions/configurationPlan';

import { fetchCouponTypes } from '@/store/actions/couponTypes';
import el from 'date-fns/esm/locale/el/index.js';
import { Container, Label } from './styles';
import * as S from './styles';

function PaymentPlanCreate() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const paymentPlanEdit = useSelector((state) => state.paymentPlan.paymentPlanEdit);
    const paymentPlanIsLoading = useSelector((state) => state.paymentPlan.paymentPlanIsLoading);
    const [isEdit, setIsEdit] = useState(false);
    const [paymentPlanName, setPaymentPlanName] = useState('');
    const [paymentPlanNameStatus, setPaymentPlanNameStatus] = useState({
        status: '',
        feedback: '',
    });

    const configurationIsLoading = useSelector((state) => state.configurationPlan.PaymentPlansIsLoading);
    const [openCollapseConfiguration, setOpenCollapseConfigurationPlan] = useState(false);
    const isConfigurationPlanOnPaymentPlan = useSelector((state) => state.configurationPlan.isConfigurationPlanOnPaymentPlan);
    const configurationPlansPayment = useSelector((state) => state.configurationPlan.configurationPlansPayment);


    useEffect(() => {
        if (id) {
            setIsEdit(true);
            dispatch(fetchPaymentPlanById(id));
        } else {
            setIsEdit(false);
        }
    }, [id]);

    useEffect(() => {
        if (isConfigurationPlanOnPaymentPlan) {
            setOpenCollapseConfigurationPlan(true);
        } else {
            setOpenCollapseConfigurationPlan(false);
        }
    }, [isConfigurationPlanOnPaymentPlan]);

    useEffect(() => {
        if (isEdit && paymentPlanEdit) {
            setPaymentPlanName(paymentPlanEdit.Descricao);

            dispatch(fetchAllPaymentPlan());
            dispatch(fetchAllPlanConfigurationPlanLinked(id));
        }
    }, [isEdit, paymentPlanEdit]);

    const handleAddPaymentPlan = useCallback(() => {
        let formErro = false;

        if (!paymentPlanName) {
            setPaymentPlanNameStatus({ status: 'danger', feedback: 'Campo obrigatório' });
            formErro = true;
        }

        if (!formErro) {
            dispatch(
                addPaymentPlan({
                    Token: '90b0b515759f369e41fa67f2e92a43e8',
                    Descricao: paymentPlanName,
                })
            );
        }
    }, [dispatch, paymentPlanName]);

    const handleEditPaymentPlan = useCallback(() => {
        let formErro = false;

        if (!paymentPlanName) {
            setPaymentPlanNameStatus({ status: 'danger', feedback: 'Campo obrigatório' });
            formErro = true;
        }

        if (!formErro) {
            dispatch(
                editPaymentPlan({
                    IdPlanoPagamento: id,
                    Descricao: paymentPlanName,
                })
            );
        }
    }, [id, dispatch, paymentPlanName]);

    const toogleConfigurationPlanDetails = useCallback(
        (idPlanoConfiguracao, enable) => {
            if (enable) {
                dispatch(
                    addConfigurationPlanDetails({
                        Token: '90b0b515759f369e41fa67f2e92a43e8',
                        IdPlanoPagamento: id,
                        IdPlanoConfiguracao: idPlanoConfiguracao,
                    })
                );
            } else {
                dispatch(
                    removeConfigurationPlanDetails({
                        IdPlanoPagamento: id,
                        IdPlanoConfiguracao: idPlanoConfiguracao,
                    })
                );
            }
        },
        [dispatch, paymentPlanEdit]
    );

    return (
        <>
            <Helmet>
                <title>Administração Franqueadora - {isEdit ? translate('paymentPlanEdit') : translate('paymentPlanCreate')}</title>
            </Helmet>
            <TitlePage title={isEdit ? translate('paymentPlanEdit') : translate('paymentPlanCreate')} />
            <Card>
                {paymentPlanIsLoading ? (
                    <ContentLoader width="100%" speed={1} height="380px" backgroundColor="#c6c6c6" foregroundColor="#F0F0F5">
                        <rect x="0" y="0" rx="5px" ry="5px" width="60%" height="40px" />
                        <rect x="0" y="60" rx="5px" ry="5px" width="20%" height="40px" />
                        <rect x="0" y="120" rx="5px" ry="5px" width="20%" height="40px" />
                        <rect x="220" y="120" rx="5px" ry="5px" width="20%" height="40px" />
                        <rect x="440" y="120" rx="5px" ry="5px" width="20%" height="40px" />
                        <rect x="0" y="230" rx="5px" ry="5px" width="100%" height="50px" />
                    </ContentLoader>
                ) : (
                    <>
                        <Row>
                            <Col xs={12} md={8}>
                                <Row>
                                    <Col xs={12}>
                                        <Input
                                            name="name-adm"
                                            type="text"
                                            label={translate('paymentPlanName')}
                                            value={paymentPlanName}
                                            change={(value) => setPaymentPlanName(value)}
                                            status={paymentPlanNameStatus}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {isEdit ? (
                                    <button type="submit" className="btn btn-primary mt" onClick={handleEditPaymentPlan}>
                                        Salvar Mudanças
                                    </button>
                                ) : (
                                    <button type="submit" className="btn btn-primary mt" onClick={handleAddPaymentPlan}>
                                        Salvar
                                    </button>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </Card>

            {paymentPlanIsLoading || configurationIsLoading ? (
                <ContentLoader width="100%" speed={1} height="380px" backgroundColor="#c6c6c6" foregroundColor="#F0F0F5">
                    <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="80px" />
                    <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="80px" />
                </ContentLoader>
            ) : (
                isEdit && (
                    <>
                        <S.CollapseItem>
                            <div className="header" onClick={() => setOpenCollapseConfigurationPlan(!openCollapseConfiguration)}>
                                <div className="title">
                                    {isConfigurationPlanOnPaymentPlan ? (
                                        <RiCheckboxFill size={25} color={colors.success} />
                                    ) : (
                                        <RiCheckboxBlankLine size={25} />
                                    )}
                                    <div>Adicionar Plano configuração?</div>
                                </div>
                                <div className="item-action">
                                    <S.ArrowCollapse open={openCollapseConfiguration}>
                                        <RiArrowDropDownLine size={30} />
                                    </S.ArrowCollapse>
                                </div>
                            </div>
                            <S.CollapseBody isOpen={openCollapseConfiguration} transition="height 0.4s cubic-bezier(.4, 0, .2, 1">
                                {configurationPlansPayment && (
                                    < DataTable
                                        data={configurationPlansPayment}
                                        searchPlaceHolder="Buscar Produtos"
                                        columns={[
                                            {
                                                name: translate('type'),
                                                selector: 'Tipo',
                                                sortable: true,
                                                width: '200px',
                                            },
                                            {
                                                name: translate('description'),
                                                selector: 'Descricao',
                                                sortable: true,
                                                width: '350px',
                                            },
                                            {
                                                name: translate('qtyTimes'),
                                                selector: 'QtdVezes',
                                                sortable: true,
                                                compact: true,
                                                width: '100px',
                                            },
                                            {
                                                name: translate('entryPercentage'),
                                                selector: 'EntradaPorcentagem',
                                                sortable: true,
                                                cell: (row) => {
                                                    return `${row.EntradaPorcentagem}%`
                                                },
                                            },
                                            {
                                                name: translate('discountPercentage'),
                                                selector: 'DescontoPorcentagem',
                                                sortable: true,
                                                cell: (row) => {
                                                    return `${row.DescontoPorcentagem}%`
                                                },
                                            },
                                            {
                                                name: translate('interestPercentage'),
                                                selector: 'JurosPorcentagem',
                                                sortable: true,
                                                cell: (row) => {
                                                    return `${row.JurosPorcentagem}%`
                                                },
                                            },
                                            {
                                                name: translate('main'),
                                                selector: 'Principal',
                                                compact: true,
                                                width: '100px',
                                                cell: (row) => {
                                                    return row.Principal ? <Badge type="success">Ativo</Badge> : <Badge type="danger">Inativo</Badge>;
                                                }
                                            },
                                            {
                                                name: translate('emphasis'),
                                                selector: 'Destaque',
                                                compact: true,
                                                width: '100px',
                                                cell: (row) => {
                                                    return row.Destaque ? <Badge type="success">Ativo</Badge> : <Badge type="danger">Inativo</Badge>;
                                                }
                                            },
                                            {
                                                name: translate('installmentLimitValue'),
                                                selector: 'ValorLimiteParcela',
                                                sortable: true,
                                                cell: (row) => {
                                                    return row.ValorLimiteParcela === null ? '' :
                                                        row.ValorLimiteParcela.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                                                }
                                            },
                                            {
                                                width: '60px',
                                                cell: (row) => (
                                                    <div
                                                        className="check-trail-module"
                                                        onClick={() => toogleConfigurationPlanDetails(row.IdPlanoConfiguracao, !row.Vinculado)}
                                                    >
                                                        {row.Vinculado ? (
                                                            <RiCheckboxFill size={25} color={colors.success} />
                                                        ) : (
                                                            <RiCheckboxBlankLine size={25} />
                                                        )}
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        columnFilter="Descricao"
                                    />
                                )}
                            </S.CollapseBody>
                        </S.CollapseItem>
                    </>
                )
            )}
        </>
    );
}

export default PaymentPlanCreate;
