import { USER_SET, USER_LOADING, USER_LOADED, USER_ERROR } from '../actionTypes';

const initialState = {
    user: null,
    roles: ['Start'],
    isUserLoading: false,
    isUserError: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_SET:
            return {
                ...state,
                user: action.payload.user,
                roles: action.payload.roles,
            };
        case USER_LOADING:
            return {
                ...state,
                isUserLoading: true,
                isUserError: false,
            };
        case USER_LOADED:
            return {
                ...state,
                isUserLoading: false,
                isUserError: false,
            };
        case USER_ERROR:
            return {
                ...state,
                isUserLoading: false,
                isUserError: true,
            };

        default:
            return state;
    }
};

export default reducer;
