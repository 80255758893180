import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { translate } from '@/i18n/src/locales';
import TitlePage from '@/components/TitlePage';
import Card from '@/components/Card';
import { fetchAllCouponLoja } from '@/store/actions/coupon';

import ContentLoader from 'react-content-loader';

import DataTable from '@/components/DataTable';
import Badge from '@/components/Badge';
import IcEdit from '@/assets/images/ic-edit.svg';
import * as ROUTES from '@/routes/routes';

function Coupons() {
  const dispatch = useDispatch();

  const coupons = useSelector((state) => state.coupon.coupons);
  const couponIsLoading = useSelector((state) => state.coupon.couponIsLoading);

  useEffect(() => {
    dispatch(fetchAllCouponLoja());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Administração Franqueadora -{translate('coupons')}</title>
      </Helmet>
      <TitlePage title={translate('coupons')} link={ROUTES.COUPON_CREATE} txtButton={translate('couponCreate')} />
      <Card>
        {couponIsLoading ? (
          <ContentLoader width="100%" speed={1} height="400px" backgroundColor="#c6c6c6" foregroundColor="#F0F0F5">
            <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="60px" />
            <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="150" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="200" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="250" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="300" rx="5px" ry="5px" width="100%" height="40px" />
            <rect x="0" y="350" rx="5px" ry="5px" width="100%" height="40px" />
          </ContentLoader>
        ) : (
          <DataTable
            data={coupons}
            searchPlaceHolder={translate('searchCouponName')}
            columns={[
              { name: translate('couponName'), selector: 'Codigo', sortable: true },
              { name: translate('type'), selector: 'Tipo', sortable: true },
              {
                name: translate('startDate'),
                selector: 'DataInicio',
                sortable: true,
                cell: (row) => {
                  return FormatDate(row.DataInicio);
                },
              },
              {
                name: translate('dueDate'),
                selector: 'DataFim',
                sortable: true,
                cell: (row) => {
                  return FormatDate(row.DataFim);
                },
              },
              {
                name: translate('discount'),
                selector: 'ValorDesconto',
                sortable: true,
                cell: (row) => {
                  return row.Tipo === 'Percentual'
                    ? `${row.ValorDesconto}%`
                    : row.ValorDesconto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                },
              },
              {
                name: translate('firstPurchase'),
                selector: 'PrimeiraCompra',
                cell: (row) => {
                  return row.PrimeiraCompra ? <Badge type="success">Ativo</Badge> : <Badge type="danger">Inativo</Badge>;
                },
              },
              {
                name: translate('active'),
                selector: 'ativo',
                compact: true,
                width: '120px',
                cell: (row) => {
                  return row.Ativo ? <Badge type="success">Ativo</Badge> : <Badge type="danger">Inativo</Badge>;
                },
              },
              {
                width: '60px',
                cell: (row) => (
                  <Link to={`${ROUTES.COUPON_CREATE}/${row.IdCupom}`}>
                    <img className="icon-edit" src={IcEdit} alt={row.Codigo} />
                  </Link>
                ),
              },
            ]}
            columnFilter="Codigo"
          />
        )}
      </Card>
    </>
  );
}

function FormatDate(dateTime) {
  const date = new Date(dateTime);
  return date.toLocaleDateString(translate('language'));
}

export default Coupons;
