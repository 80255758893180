import React, { useState, useEffect } from 'react';

import TitlePage from '@/components/TitlePage';
import Card from '@/components/Card';

import { BoxForm, HeaderVoucher, ContentCards, ContainerLoader } from './styles';
import { translate } from '@/i18n/src/locales';
import { Helmet } from 'react-helmet';
import SelectOne from '@/components/Forms/SelectOne';
import ContentLoader from 'react-content-loader';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { pt } from 'date-fns/esm/locale';

import { fetchPoles } from '@/store/actions/pole';
import { fetchProduct, clearProduct } from '@/store/actions/product';
import { fetchVoucher, filterVoucher, clearVoucher } from '@/store/actions/voucher';

function Vouchers() {
    const poles = useSelector((state) => state.pole.poles);
    const polesIsLoading = useSelector((state) => state.pole.polesIsLoading);

    const products = useSelector((state) => state.product.products);
    const productIsLoading = useSelector((state) => state.product.productIsLoading);

    const vouchersState = useSelector((state) => state.voucher.vouchers);
    const vouchersIsLoading = useSelector((state) => state.voucher.voucherIsLoading);
    const status = useSelector((state) => state.voucher.status);

    const dispatch = useDispatch();

    const [inputPoles, setInputPoles] = useState(null);
    const [inputProducts, setInputProducts] = useState(null);
    const [pole, setPole] = useState(null);
    const [vouchers, setVouchers] = useState([]);
    const [qtdVouchers, setQtdVouchers] = useState({
        all: 0,
        available: 0,
        inUse: 0,
        consuming: 0,
    });

    useEffect(() => {
        dispatch(clearVoucher());
        dispatch(clearProduct());
        dispatch(fetchPoles());
    }, []);

    useEffect(() => {
        if (poles.length > 0) {
            setInputPoles(
                poles.map((item) => ({
                    label: `${item.codigoExternoPolo} - ${item.nomePolo}`,
                    value: String(item.idPolo),
                })),
            );
        }
    }, [poles]);

    useEffect(() => {
        if (products.length > 0) {
            setInputProducts(
                products.map((item) => ({
                    label: `${item.IdProduto} - ${item.Nome}`,
                    value: String(item.IdProduto),
                })),
            );
        }
    }, [products]);

    useEffect(() => {
        if (vouchersState.length > 0) {
            setVouchers(vouchersState);
            let _qtd = {
                all: vouchersState.length,
                available: vouchersState.filter((itemCount) => itemCount.StatusVoucher === 1)
                    .length,
                inUse: vouchersState.filter((itemCount) => itemCount.StatusVoucher === 2).length,
                consuming: vouchersState.filter((itemCount) => itemCount.StatusVoucher === 3)
                    .length,
            };

            setQtdVouchers(_qtd);
        }
    }, [vouchersState]);

    useEffect(() => {
        let _status = status.filter((item) => item.selected === true);
        if (_status[0].idStatus === 0) {
            setVouchers(vouchersState);
        } else {
            let vouchers = vouchersState.filter(
                (item) => item.StatusVoucher === _status[0].idStatus,
            );
            setVouchers(vouchers);
        }
    }, [status]);

    function handleChangePolo(item) {
        setPole(item.value);
        dispatch(clearProduct());
        dispatch(clearVoucher());
        dispatch(fetchProduct(item.value));
    }

    function handleChangeProduct(item) {
        dispatch(clearVoucher());
        dispatch(fetchVoucher(pole, item.value));
    }

    function handleFilterVoucher(idStatus) {
        let _status = status.filter((item) => {
            item.idStatus == idStatus ? (item.selected = true) : (item.selected = false);
            return item;
        });

        dispatch(filterVoucher(_status));
    }

    return (
        <>
            <Helmet>
                <title>Administração Franqueadora - {translate('vouchers')}</title>
            </Helmet>
            <TitlePage title={translate('vouchers')} />
            <Card>
                <BoxForm>
                    {polesIsLoading ? (
                        <ContentLoader width={'100%'} speed={1} height={'40px'}>
                            <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="40px" />
                        </ContentLoader>
                    ) : (
                        <SelectOne
                            options={inputPoles}
                            change={(item) => handleChangePolo(item)}
                            searchable={true}
                            placeholder={translate('selectAUnit')}
                        />
                    )}
                </BoxForm>
                <BoxForm className="mt">
                    {productIsLoading ? (
                        <ContentLoader width={'100%'} speed={1} height={'40px'}>
                            <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="40px" />
                        </ContentLoader>
                    ) : (
                        <div>
                            {products.length > 0 ? (
                                <SelectOne
                                    options={inputProducts}
                                    change={(item) => handleChangeProduct(item)}
                                    searchable={true}
                                    placeholder={translate('selectAProduct')}
                                />
                            ) : null}
                        </div>
                    )}
                </BoxForm>
            </Card>
            {vouchersIsLoading ? (
                <ContainerLoader>
                    <ContentLoader
                        width={'100%'}
                        speed={1}
                        height={'400px'}
                        backgroundColor={'#c6c6c6'}
                        foregroundColor={'#F0F0F5'}>
                        <rect x="0" y="0" rx="5px" ry="5px" width="25%" height="80px" />
                        <rect x="26%" y="0" rx="5px" ry="5px" width="25%" height="80px" />
                        <rect x="52%" y="0" rx="5px" ry="5px" width="25%" height="80px" />
                        <rect x="78%" y="0" rx="5px" ry="5px" width="25%" height="80px" />
                        <rect x="0" y="100" rx="5px" ry="5px" width="100%" height="80px" />
                        <rect x="0" y="200" rx="5px" ry="5px" width="100%" height="80px" />
                        <rect x="0" y="300" rx="5px" ry="5px" width="100%" height="80px" />
                    </ContentLoader>
                </ContainerLoader>
            ) : (
                <>
                    {vouchers.length > 0 ? (
                        <ContentCards>
                            {status.map((item) => {
                                let qtdItem = 0;

                                if (item.idStatus === 0) {
                                    qtdItem = qtdVouchers.all;
                                } else if (item.idStatus === 1) {
                                    qtdItem = qtdVouchers.available;
                                } else if (item.idStatus === 2) {
                                    qtdItem = qtdVouchers.inUse;
                                } else if (item.idStatus === 3) {
                                    qtdItem = qtdVouchers.consuming;
                                }

                                if (item.selected) {
                                    return (
                                        <div className="item active" key={item.idStatus}>
                                            <div className="qtd">{qtdItem}</div>
                                            <div className="description">{item.description}</div>
                                        </div>
                                    );
                                } else {
                                    if (qtdItem > 0) {
                                        return (
                                            <div
                                                className="item"
                                                onClick={() => handleFilterVoucher(item.idStatus)}
                                                key={item.idStatus}>
                                                <div className="qtd">{qtdItem}</div>
                                                <div className="description">
                                                    {item.description}
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className="item" key={item.idStatus}>
                                                <div className="qtd">{qtdItem}</div>
                                                <div className="description">
                                                    {item.description}
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                            })}
                        </ContentCards>
                    ) : null}
                    {vouchers.length > 0
                        ? vouchers.map((item) => <ItemVoucher key={item.Hash} item={item} />)
                        : null}
                </>
            )}
        </>
    );
}

const ItemVoucher = ({ item }) => {
    let htmlStatus = '';

    if (item.StatusVoucher === 1) {
        htmlStatus = (
            <div className="status-voucher">
                <div className="indicator success"></div>
                {translate('available')}
            </div>
        );
    } else if (item.StatusVoucher === 2) {
        htmlStatus = (
            <div className="status-voucher">
                <div className="indicator warning"></div>
                {translate('inUse')}
            </div>
        );
    } else if (item.StatusVoucher === 3) {
        htmlStatus = (
            <div className="status-voucher">
                <div className="indicator danger"></div>
                {translate('consumed')}
            </div>
        );
    }

    return (
        <>
            <HeaderVoucher>
                <div className="item">
                    <div className="hash">{item.Hash}</div>
                    <div>{item.TipoAssinatura ? item.TipoAssinatura.NomeTipo : null}</div>
                </div>
                <div className="item">
                    <div className="titulo">{translate('product')}</div>
                    <div>{item.Produto.Nome}</div>
                </div>
                <div className="item">
                    {item.Assinatura ? (
                        <>
                            <div className="titulo">{translate('registerDate')}</div>
                            <div>
                                {format(
                                    new Date(item.Assinatura.DataInicio),
                                    'dd/MM/yyyy : HH:mm:ss',
                                    {
                                        locale: pt,
                                    },
                                )}
                            </div>
                        </>
                    ) : null}
                </div>
                <div className="item">
                    {item.Assinatura ? (
                        <>
                            <div className="titulo">{translate('expiredOn')}</div>
                            <div>
                                {format(
                                    new Date(item.Assinatura.DataFim),
                                    'dd/MM/yyyy : HH:mm:ss',
                                    {
                                        locale: pt,
                                    },
                                )}
                            </div>
                        </>
                    ) : null}
                </div>
                <div className="item">
                    <div className="titulo">{translate('status')}</div>
                    {htmlStatus}
                </div>
                <div className="item">
                    {item.Assinatura ? (
                        <>
                            <div className="titulo">{translate('student')}</div>
                            <div>{item.Assinatura.NomeSobrenomePerfil}</div>
                        </>
                    ) : null}
                </div>
            </HeaderVoucher>
        </>
    );
};

export default Vouchers;
