import React, { useState, useRef, useEffect } from 'react';

import {
    RiMenuLine,
    RiCloseLine,
    RiArrowDropDownLine,
    RiLogoutBoxLine,
    RiUser2Line,
} from 'react-icons/ri';
import { Container, ButtonToogle, ButtonUser, DropDownMenu } from './styles';

import Avatar from '@/components/Avatar';

import { useSelector, useDispatch } from 'react-redux';
import { setSideBarOpen } from '@/store/actions/theme';
import { logout } from '@/store/actions/user';
import { translate } from '@/i18n/src/locales';

function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        } else {
            setIsComponentVisible(true);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
}

function Header() {
    const sideBarOpen = useSelector((state) => state.theme.sideBarOpen);
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const { ref, isComponentVisible } = useComponentVisible(false);

    function handleClickSideBar() {
        dispatch(setSideBarOpen(!sideBarOpen));
    }

    function handleClick() {
        dispatch(logout());
    }

    return (
        <>
            <Container sideBarOpen={sideBarOpen}>
                <ButtonToogle onClick={() => handleClickSideBar()}>
                    {sideBarOpen ? <RiCloseLine size={25} /> : <RiMenuLine size={25} />}
                </ButtonToogle>
                <ButtonUser ref={ref}>
                    <Avatar />
                    {user ? user.NomeCompleto : null}
                    <RiArrowDropDownLine size={25} />
                </ButtonUser>

                <DropDownMenu dropOpen={isComponentVisible}>
                    <ul>
                        <li>
                            <RiLogoutBoxLine />
                            <a onClick={() => handleClick()} href="/">
                                {translate('logout')}
                            </a>
                        </li>
                    </ul>
                </DropDownMenu>
            </Container>
        </>
    );
}

export default Header;
