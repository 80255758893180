/* eslint-disable jsx-a11y/aria-role */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from '@/store/storeConfig';
import GlobalStyle from '@/template/styles/stylesGlobal';
import { PersistGate } from 'redux-persist/integration/react';
import PrivateRoute from '@/routes/PrivateRoute';
import { ConnectedRouter } from 'connected-react-router';

import Main from '@/template/Main';
import Public from '@/template/Public';
import Login from '@/pages/Login';
import Start from '@/pages/Start';
import NoPermission from '@/pages/NoPermission';
import Erro from '@/pages/Erro';
import Revenues from '@/pages/Financial/Revenues';
import Vouchers from '@/pages/Store/Vouchers';
import VoucherCreate from '@/pages/Store/VoucherCreate';
import Sales from '@/pages/Store/Sales';
import Poles from '@/pages/Pole/Poles';
import PoleCreate from '@/pages/Pole/PoleCreate';
import Franchisors from '@/pages/Franchisor/Franchisors';
import FranchisorCreate from '@/pages/Franchisor/FranchisorCreate';
import Products from '@/pages/Store/Products';
import ProductCreate from '@/pages/Store/ProductCreate';
import Coupons from '@/pages/Coupon/Coupons';
import CouponCreate from '@/pages/Coupon/CouponCreate';
import Sections from '@/pages/Section/Sections';
import SectionCreate from '@/pages/Section/SectionCreate';
import PaymentPlans from '@/pages/PaymentPlan/PaymentPlans';
import PaymentPlanCreate from '@/pages/PaymentPlan/PaymentPlanCreate';

import ConfigurationPlans from '@/pages/PaymentPlan/ConfigurationPlans';
import ConfigurationPlanCreate from '@/pages/PaymentPlan/ConfigurationPlanCreate';

import * as ROUTES from '@/routes/routes';
import history from '@/routes/history';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <GlobalStyle />
          <Public>
            <Switch>
              <Route exact path={ROUTES.HOME} component={Login} />
              <Route exact path={ROUTES.LOGIN} component={Login} />
            </Switch>
          </Public>
          <Route path={['/app']}>
            <Main>
              <Switch>
                <PrivateRoute exact path={ROUTES.START} component={Start} role="Start" />
                <PrivateRoute exact path={ROUTES.REVENUES} component={Revenues} role="Revenues" />
                <PrivateRoute exact path={ROUTES.VOUCHERS} component={Vouchers} role="Vouchers" />
                <PrivateRoute exact path={ROUTES.VOUCHER_CREATE} component={VoucherCreate} role="VoucherCreate" />
                <PrivateRoute exact path={ROUTES.SALES} component={Sales} role="Sales" />
                <PrivateRoute exact path={ROUTES.POLES} component={Poles} role="Poles" />
                <PrivateRoute exact path={ROUTES.POLE_CREATE} component={PoleCreate} role="PoleCreate" />
                <PrivateRoute exact path={ROUTES.POLE_CREATE} component={PoleCreate} role="PoleCreate" />
                <PrivateRoute exact path={ROUTES.FRANCHISORS} component={Franchisors} role="Franchisors" />
                <PrivateRoute exact path={ROUTES.FRANCHISOR_CREATE} component={FranchisorCreate} role="Franchisors" />
                <PrivateRoute exact path={ROUTES.PRODUCTS} component={Products} role="Products" />
                <PrivateRoute exact path={`${ROUTES.PRODUCT_CREATE}/:id?`} component={ProductCreate} role="ProductCreate" />
                <PrivateRoute exact path={ROUTES.COUPONS} component={Coupons} role="Coupons" />
                <PrivateRoute exact path={`${ROUTES.COUPON_CREATE}/:id?`} component={CouponCreate} role="CouponCreate" />
                <PrivateRoute exact path={ROUTES.SECTIONS} component={Sections} role="Sections" />
                <PrivateRoute exact path={`${ROUTES.SECTION_CREATE}/:id?`} component={SectionCreate} role="SectionCreate" />
                <PrivateRoute exact path={ROUTES.PAYMENTPLANS} component={PaymentPlans} role="PaymentPlans" />
                <PrivateRoute exact path={`${ROUTES.PAYMENTPLAN_CREATE}/:id?`} component={PaymentPlanCreate} role="PaymentPlanCreate" />

                <PrivateRoute exact path={ROUTES.CONFIGURATIONPLANS} component={ConfigurationPlans} role="ConfigurationPlans" />
                <PrivateRoute exact path={`${ROUTES.CONFIGURATIONPLAN_CREATE}/:id?`} component={ConfigurationPlanCreate} role="ConfigurationPlanCreate" />

                <PrivateRoute exact path={ROUTES.NO_PERMISSION} component={NoPermission} role="NoPermission" />
                <Route component={Erro} />
              </Switch>
            </Main>
          </Route>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
