import {
    INVOICES_SET,
    INVOICES_LOADING,
    INVOICES_LOADED,
    INVOICE_ITENS_SET,
    INVOICE_ITENS_LOADING,
    INVOICE_ITENS_LOADED,
} from '../actionTypes';

const initialState = {
    invoices: [],
    invoicesIsLoading: false,
    invoiceItens: [],
    invoiceItensIsLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case INVOICES_SET:
            return {
                ...state,
                invoices: action.payload,
            };
        case INVOICES_LOADING:
            return {
                ...state,
                invoicesIsLoading: true,
            };
        case INVOICES_LOADED:
            return {
                ...state,
                invoicesIsLoading: false,
            };
        case INVOICE_ITENS_SET:
            return {
                ...state,
                invoiceItens: action.payload,
            };
        case INVOICE_ITENS_LOADING:
            return {
                ...state,
                invoiceItensIsLoading: true,
            };
        case INVOICE_ITENS_LOADED:
            return {
                ...state,
                invoiceItensIsLoading: false,
            };
        default:
            return state;
    }
};

export default reducer;
