import { COUPON_TYPES_SET, COUPON_TYPES_LOADING, COUPON_TYPES_LOADED } from '../actionTypes';

const initialState = {
    couponTypes: [],
    couponTypesIsLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case COUPON_TYPES_SET:
            return {
                ...state,
                couponTypes: action.payload,
            };
        case COUPON_TYPES_LOADING:
            return {
                ...state,
                couponTypesIsLoading: true,
            };
        case COUPON_TYPES_LOADED:
            return {
                ...state,
                couponTypesIsLoading: false,
            };
        default:
            return state;
    }
};

export default reducer;
