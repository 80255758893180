import styled from 'styled-components';

export const Content = styled.div`
    width: 100%;
    margin-left: ${(props) => (props.sideBarOpen ? `220px` : `0px`)};

    transition: all 0.3s ease 0.1s;
`;

export const Wrapper = styled.div`
    width: 100%;
    padding: 3rem;
    margin-top: 70px;
`;
