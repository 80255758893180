import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { fonts } from '@/template/styles';

export const Container = styled.aside`
    position: fixed;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: ${(props) => (props.sideBarOpen ? `220px` : `0px`)};
    padding-top: ${(props) => (props.sideBarOpen ? `0px` : `100px`)};
    height: 100vh;
    background: #1e273b;
    flex-direction: column;
    overflow: hidden;
    z-index: 999;

    transition: all 0.3s ease 0.1s;
    font-weight: 300;
    font-size: ${fonts.small};

    img {
        opacity: ${(props) => (props.sideBarOpen ? 1 : 0)};
        transition: all 0.3s;
    }
`;

export const Logo = styled.img`
    width: auto;
    max-width: 180px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-height: 100px;
`;

export const SidebarLink = styled(NavLink)`
    overflow: hidden;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #eee;
    line-height: 50px;
    width: 100%;
    padding-left: 20px;

    &:hover {
        color: #fff;
        background: #242f47;
    }

    & .active {
        background: #242f47;
    }
`;
