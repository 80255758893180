export default {
  success: '#17c671',
  info: '#1EBEDA',
  warning: '#E6B02D',
  danger: '#E3214A',
  darker: '#212121',
  dark: '#263238',
  regular: '#4B515D',
  light: '#C0C0C0',
  gray: '#D2D2D2',
  lighter: '#f8f8f8',
  white: '#FFF',
  blue: '#007bff',
  transparent: 'rgba(0, 0, 0, 0)',
  gradientDefault:
    'linear-gradient(135deg, #9AB6BA 0%, #74C6F5 20%, #76AAF4 40%, #748EF3 60%, #6E72F2 80%, #6454F0 100%);',
};
