// en-US.js
export default {
  signIn: 'Sign In',
  user: 'User',
  password: 'Password',
  start: 'Start',
  revenues: 'Revenues',
  financial: 'Financial',
  store: 'Store',
  vouchers: 'Vouchers',
  voucherCreate: 'Create Voucher',
  informAmount: 'Inform Amount in 1 to 25',
  sales: 'Sales',
  pole: 'Pole',
  poles: 'Poles',
  poleCreate: 'Create Pole',
  poleName: 'Pole name',
  createAnother: 'Create another pole',
  invalidUserOrPassword: 'Invalid username or password',
  hello: 'Hello',
  welcomeToYourAdministration: 'Welcome to your administration',
  logout: 'Logout',
  selectAUnit: 'Select a unit',
  plan: 'Plan',
  value: 'Value',
  discount: 'Discount',
  totalItemValue: 'Total item value',
  total: 'Total',
  retroactive: 'Retroactive',
  referenceMounth: 'Reference Mounth',
  studentName: 'Student Name',
  moduleTitle: 'Module Title',
  class: 'Class',
  billable: 'Billable',
  registrationDate: 'Registration Date',
  billed: 'Billed',
  unbilled: 'Unbilled',
  selectAProduct: 'Select a product',
  selectAPlan: 'Select a plan',
  all: 'All',
  available: 'Available',
  inUse: 'In Use',
  consumed: 'Consumed',
  product: 'Product',
  products: 'Products',
  productCreate: 'Product create',
  productEdit: 'Product edit',
  last7Days: '7 days last',
  last30Days: '30 days last',
  period: 'Period',
  status: 'Status',
  registerDate: 'Register Date',
  expiredOn: 'Expired On',
  student: 'Student',
  saleDate: 'Sale date',
  typeOfSale: 'Type of sale',
  dueDate: 'Due date',
  paymentStatus: 'Payment Status',
  formOfPayment: 'Form of Payment',
  installments: 'Installments',
  installmentValue: 'Installment Value',
  quantity: 'Quantity',
  interest: 'Interest',
  dispatch: 'Dispatch',
  totalPaid: 'Total paid',
  externalCode: 'External Code',
  searchPoleName: 'Search pole name',
  adminName: 'Admin name',
  adminEmail: 'Admin email',
  invalidEmail: 'invalid Email',
  creatingPole: 'Creating pole',
  successfullyCreated: 'Successfully created',
  hash: 'Picadillo',
  genaratedDate: 'Fecha generada',
  signature: 'Firma',
  trail: 'Sendero',
  course: 'Curso',
  enrollmentDate: 'Fecha de inscripción',
  rg: 'RG',
  cpf: 'CPF',
  address: 'Address',
  number: 'Number',
  complement: 'Complement',
  neighborhood: 'Neighborhood',
  city: 'City',
  state: 'State',
  legalPerson: 'Legal person',
  physicalPerson: 'Physical person',
  selectedPearson: 'Select Individual or Legal Entity',
  admNameStatus: 'Enter the name of the pole administrator',
  poleNameStatus: 'Enter the name of the pole',
  rgStatus: 'Inform the document',
  cpfStatus: 'Inform the document',
  telefoneStatus: 'Enter the telephone number (with the area code)',
  celularStatus: 'Enter the cellphone number (with the area code)',
  nomeFantasiaStatus: 'Enter your fantasy name',
  razaoSocialStatus: 'Enter the company name',
  cnpjStatus: 'Enter the CNPJ',
  ieStatus: 'Enter the IE',
  logradouroStatus: 'Enter the address',
  numberStatus: 'Enter the number',
  complementoStatus: 'Enter the add-on',
  neighborhoodStatus: 'Inform the neighborhood',
  cityStatus: 'Inform the city',
  stateStatus: 'Enter the state',
  cepStatus: 'Enter the CEP',
  nomeFantasia: 'Fantasy name',
  razaoSocial: 'Company Name',
  cnpj: 'CNPJ',
  ie: 'IE',
  cep: 'CEP',
  differentPaymentAddress: 'Different Payment Address?',
  differentShippingAddress: 'Different Delivery Address?',
  addressMain: 'Main Address',
  telefone: 'Telephone',
  celular: 'Cell Phone',
  franchisor: 'Franchisor',
  franchisors: 'Franchisors',
  franchisorCreate: 'Franchisor create',
  franchisorEdit: 'Franchisor edit',
  coupons: 'Coupons',
  couponCreate: 'Create Coupon',
  searchCouponName: 'Search coupon name',
  couponName: 'Coupon name',
  active: 'Active',
  inactive: 'Inactive',
  type: 'Type',
  startDate: 'Start Date',
  language: 'en-US',
  typeCoupom: 'Type of Coupon',
  activeCoupon: 'Active Coupon',
  selectAType: 'select a type',
  couponEdit: 'Coupon edit',
  couponExists: 'The coupon already exists',
  validateDate: 'Invalid expiration date',
  couponAdd: 'Coupon successfully added!',
  couponEdited: 'Coupon changed successfully!',
  validatePercentage: 'Percentage above 100%',
  sections: 'Sections',
  sectionCreate: 'Create Section',
  sectionEdit: 'Edit section',
  searchSection: 'Search section',
  searchPaymentPlan: 'Search Payment Plan',
  searchProduct: 'Search Product',
  havePaymentPlan: 'Does the section have a payment plan?',
  haveProduct: 'Section has a product?',
  dropFile: 'Drop the file here...',
  chooseImageSection: 'Choose an image for the Section, recommended size 350x326px',
  BadRequestCoupon: 'Changes not made to the Coupon',
  alertPlanAtSection: 'Only one payment plan per Section is allowed.',
  changePlanSectionMessageSucess: 'Plan successfully changed',
  changePlanSectionMessageError: 'Unable to change plan',
  chooseImageSection: 'Choose an image for the Section, recommended size 350x326px',
  nomeCompletoDocumento: 'Full Name (Document)',


  paymentPlans: 'Payment Plans',
  paymentPlanCreate: 'Create Payment Plan',
  searchPaymentPlanName: 'Search Payment Plan',
  paymentPlanName: 'Name Payment Plan',
  paymentPlanEdit: 'Edit Payment Plan',
  paymentPlanEdited: 'Payment plan changed successfully!',
  paymentPlanAdd: 'Payment plan successfully added!',


  configurationPlans: 'Configuration Plans',
  configurationPlanCreate: 'Create Configuration Plan',
  searchConfigurationPlansName: 'Search Configuration Plan',
  configurationPlansType: 'PaymentPlanType',
  description: 'Description',
  qtyTimes: 'Qty Times',
  entryPercentage: 'Entry %',
  discountPercentage: 'Discount %',
  interestPercentage: 'Interest %',
  main: 'Main',
  emphasis: 'Emphasis',
  installmentLimitValue: 'Limit Value Installment',

  selectCouponTypeApplication: 'Select the type of coupon application',
  firstPurchase: 'First Purchase',
};
