import I18n from 'i18n-js';
import en from './en-US'; // importa o objeto de traduções para o idioma inglês
import pt from './pt-BR'; // importa o objeto de traduções para o idioma português
import es from './es-ES'; // importa o objeto de traduções para o idioma português

// Aqui setamos os idiomas que o I18N irá dar suporte
I18n.translations = {
    en_US: en,
    pt_BR: pt,
    es_ES: es,
};

export const setLanguageToI18n = (language) => {
    I18n.locale = language;
};

setLanguageToI18n('pt_BR');

export const translate = (key) => I18n.t(key);
