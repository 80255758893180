/* eslint-disable no-console */
import { push } from 'connected-react-router';
import { apiLoja } from '@/services/api';
import * as ROUTES from '@/routes/routes';
import * as _ from 'lodash';
import {
  PRODUCTS_SET,
  PRODUCT_EDIT_SET,
  PRODUCT_LOADING,
  PRODUCT_LOADED,
  PRODUCT_CLEAR,
  PRODUCT_TRAILS_SET,
  PRODUCT_TRAILS_LOADING,
  PRODUCT_TRAILS_LOADED,
  PRODUCT_MODULES_SET,
  PRODUCT_MODULES_LOADING,
  PRODUCT_MODULES_LOADED,
} from '../actionTypes';

export const setProducts = (products) => ({
  type: PRODUCTS_SET,
  payload: products,
});

export const setProductEdit = (product) => ({
  type: PRODUCT_EDIT_SET,
  payload: product,
});

export const loadingProduct = () => ({
  type: PRODUCT_LOADING,
});

export const loadedProduct = () => ({
  type: PRODUCT_LOADED,
});

export const clearProduct = () => ({
  type: PRODUCT_CLEAR,
});

export const setProductTrails = (trails, isTrailOnProduct) => ({
  type: PRODUCT_TRAILS_SET,
  payload: { trails, isTrailOnProduct },
});

export const loadingProductTrails = () => ({
  type: PRODUCT_TRAILS_LOADING,
});

export const loadedProductTrails = () => ({
  type: PRODUCT_TRAILS_LOADED,
});

export const setProductModules = (modules, isModuleOnProduct) => ({
  type: PRODUCT_MODULES_SET,
  payload: { modules, isModuleOnProduct },
});

export const loadingProductModules = () => ({
  type: PRODUCT_MODULES_LOADING,
});

export const loadedProductModules = () => ({
  type: PRODUCT_MODULES_LOADED,
});

export const fetchProduct = (idPolo) => {
  return (dispatch) => {
    dispatch(loadingProduct());
    apiLoja
      .get(`/Produto/GetAllByPolo/${idPolo}`)
      .then((data) => {
        dispatch(setProducts(data.data));
        dispatch(loadedProduct());
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const addProduct = (product) => {
  return (dispatch) => {
    dispatch(loadingProduct());
    apiLoja
      .post(`/Produto`, {
        ...product,
      })
      .then((data) => {
        dispatch(setProductEdit(data.data));
        dispatch(loadedProduct());
        dispatch(push(`${ROUTES.PRODUCT_CREATE}/${data.data.IdProduto}`));
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const editProduct = (product) => {
  return (dispatch) => {
    dispatch(loadingProduct());
    apiLoja
      .put(`/Produto`, {
        ...product,
      })
      .then((data) => {
        dispatch(setProductEdit(data.data));
        dispatch(push(`${ROUTES.PRODUCT_CREATE}/${product.IdProduto}`));
        dispatch(loadedProduct());
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const fetchAllProductAdmInternal = () => {
  return (dispatch) => {
    dispatch(loadingProduct());
    apiLoja
      .get(`/Produto/`)
      .then((data) => {
        dispatch(setProducts(data.data));
        dispatch(loadedProduct());
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const fetchProductByIdAdmInternal = (IdProduto) => {
  return (dispatch) => {
    dispatch(loadingProduct());
    apiLoja
      .get(`/Produto/${IdProduto}`)
      .then((data) => {
        dispatch(setProductEdit(data.data));
        dispatch(loadedProduct());
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const fetchAllTrailsProductAdmInternal = (IdProduto) => {
  return (dispatch) => {
    dispatch(loadingProductTrails());
    apiLoja
      .get(`/Trilha/${IdProduto}`)
      .then((data) => {
        if (data.data) {
          const ordered = _.orderBy(data.data, 'Integrado', 'desc');

          const isTrailOnProduct = _.find(ordered, { Integrado: true }) || false;

          if (isTrailOnProduct) {
            dispatch(setProductTrails(ordered, true));
          } else {
            dispatch(setProductTrails(ordered, false));
          }
        }

        dispatch(loadedProductTrails());
        dispatch(fetchAllModulesProductAdmInternal(IdProduto));
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const fetchAllModulesProductAdmInternal = (IdProduto) => {
  return (dispatch) => {
    dispatch(loadingProductModules());
    apiLoja
      .get(`/Curso/${IdProduto}`)
      .then((data) => {
        if (data.data) {
          const ordered = _.orderBy(data.data, ['IntegradoTrilha', 'Integrado'], ['desc', 'desc']);

          const isModuleOnProduct = _.find(ordered, (o) => {
            return o.Integrado === true || o.IntegradoTrilha === true;
          });
          if (isModuleOnProduct) {
            dispatch(setProductModules(ordered, true));
          } else {
            dispatch(setProductModules(ordered, false));
          }
        }

        dispatch(loadedProductModules());
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const addProductIntegration = (integracao) => {
  return (dispatch) => {
    dispatch(loadingProductTrails());
    dispatch(loadingProductModules());
    apiLoja
      .post(`/ProdutoIntegracao`, {
        ...integracao,
      })
      .then(() => {
        dispatch(fetchAllTrailsProductAdmInternal(integracao.IdProduto));
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const removeProductIntegration = (integracao) => {
  return (dispatch) => {
    dispatch(loadingProductTrails());
    dispatch(loadingProductModules());
    apiLoja
      .delete(`/ProdutoIntegracao`, {
        data: {
          ...integracao,
        },
      })
      .then((data) => {
        dispatch(fetchAllTrailsProductAdmInternal(integracao.IdProduto));
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};

export const updateImageProduct = (IdProduto, ImagemBase64) => {
  return (dispatch) => {
    dispatch(loadingProduct());
    apiLoja
      .patch(`/Produto/UpdateImagem`, {
        IdProduto,
        ImagemBase64,
      })
      .then(() => {
        dispatch(loadedProduct());
      })
      .catch((error) => {
        console.log('error comming', error);
      });
  };
};
